.notification-listing-page{
    min-height: calc(100vh - 62px);
    background: #F9F8F7;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &-card{
        width: 500px;  
        box-shadow: 0px 12px 45px #0000000d;
        margin: 24px 0px; 
        .card-wrap{
            max-height: 540px;
            padding: 20px;
            overflow: auto;
            scrollbar-color: #f5f5f5 !important;
            scrollbar-width: thin !important;
            &::-webkit-scrollbar {
                width: 5px;
                background-color: #f5f5f5;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
                background-color: #ccc;
            }
        }
        .ant-card-head{
            display: flex;
            justify-content: flex-start;
            min-height: auto;
            height: 40px;
            align-items: center;
            padding: 20px;
            .ant-card-head-title{
                font-size: 18px;
            }
        }
        .ant-card-body{
            padding: 0 !important;
        }
        .notification-item{
            margin-bottom: 10px;
            box-shadow: 0px 6px 15px #0000000d !important;
            .list-top{
                display: flex;
                width: 100%;
                justify-content: space-between;
                margin-bottom: 10px;
                span{
                    &:nth-of-type(1){
                        font-size: 12px;
                        font-weight: 500;
                        @media(min-width: 1440px){
                            font-size: 14px;
                        }
                    }
                    &:nth-of-type(2){
                        font-size: 10px;
                        font-weight: 300;
                        @media(min-width: 1440px){
                            font-size: 12px;
                        }
                    }
                }
            }
            .list-bottom{
                font-size: 10px;
                font-weight: 300;
                @media(min-width: 1440px){
                    font-size: 12px;
                }
            }
            .ant-card-body{
                padding: 15px !important;
            }
        }
        .blue-list{
            background: #F5F5F5;
        }
        .pink-list{
            background: #ff972012;
        }
    }
    .pop-loader{
        position: absolute !important;
        ._loading_overlay_overlay{
            border-radius: 10px !important; 
        }
    }
}