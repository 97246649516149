@import 'Assets/styles/global.scss';
.chat-wrapper-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    width: 100%;
    .chat-tabs {
        width: 100%;
        .ant-tabs-nav {
            width: 25%;
            margin-right: 12px;
            border-radius: 10px;
            box-shadow: 0px 12px 45px #0000000f;
            position: relative;
            background: #fff;
            .ant-tabs-nav-wrap {
                .ant-tabs-nav-list {
                    .ant-tabs-tab {
                        border-bottom: 1px solid #f3f3f3;
                        padding: 24px 20px;
                        margin: 0;
                        &:hover {
                            svg {
                                fill: $dark-orange;
                                opacity: 1;
                                -webkit-transition: all 0.2s ease-in-out;
                                -o-transition: all 0.2s ease-in-out;
                                transition: all 0.2s ease-in-out;
                            }
                            .tab-data {
                                color: $dark-orange;
                                opacity: 1;
                                -webkit-transition: all 0.2s ease-in-out;
                                -o-transition: all 0.2s ease-in-out;
                                transition: all 0.2s ease-in-out;
                            }
                        }
                    }
                }
            }
        }
        .ant-tabs-content-holder {
            width: 75%;
            border-radius: 10px;
            box-shadow: 0px 12px 45px #0000000f;
            position: relative;
            background: #fff;
            // padding: 20px;
            .ant-tabs-content {
                height: 100%;
                .ant-tabs-tabpane {
                    padding: 0;
                }
            }
        }
        .tab-icon-wrap {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            .tab-data {
                color: $light-grey;
                opacity: 0.5;
            }
            svg {
                fill: $light-grey;
                opacity: 0.5;
            }
        }
        .ant-tabs-tab-active {
            .tab-icon-wrap {
                .tab-data {
                    color: $dark-orange;
                    opacity: 1;
                }
                svg {
                    fill: $dark-orange;
                    opacity: 1;
                }
            }
        }
    }
}

//styles for chat container

.chat-content-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    > div {
        width: 50%;
    }
    .new-chat-wrapper {
        border-right: 1px solid #dfdfdf;
    }
    .new-topic-wrapper {
    }
    .chat-head {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        margin-bottom: 24px;
        padding: 20px;
        .add-btn {
            color: $white-color;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            padding: 0;
            border: 0;
            span {
                color: $white-color;
            }
        }
        span {
            font-size: 14px;
            font-weight: 700;
            color: $black-color;
        }
    }
    .chat-content {
        height: 100%;
        max-height: calc(100% - 89px);
        overflow: auto;
        padding: 0px 20px;
        scrollbar-color: #f5f5f5 !important;
        scrollbar-width: thin !important;
        &::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
            background-color: #ccc;
        }
        .create-new-chat-text {
            margin-top: 410px;
            text-decoration: underline;
            text-align: center;
            font: normal normal normal 14px/17px Museo Sans 300;
            letter-spacing: 0px;
            color: #cbcfd0;
            opacity: 1;
        }
        .chat-content-item {
            cursor: pointer;
            padding: 8px 10px;
            box-shadow: 0px 6px 15px #0000000d;
            margin-bottom: 16px;
            .ant-card-body {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -ms-flex-direction: row;
                flex-direction: row;
                -webkit-box-pack: start;
                -ms-flex-pack: start;
                justify-content: flex-start;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                width: 100%;
                padding: 0 !important;
                overflow: visible;
                .card-left {
                    width: 20%;
                    .avatar-wrap {
                        position: relative;
                        .ant-avatar {
                            border: 1px solid $white-color;
                            &:nth-of-type(2) {
                                position: absolute;
                                right: 15px;
                                top: -7px;
                            }
                        }
                    }
                }
                .card-right {
                    width: 80%;
                    .person-data {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-pack: justify;
                        -ms-flex-pack: justify;
                        justify-content: space-between;
                        span {
                            &:nth-of-type(1) {
                                font-size: 12px;
                                color: $black-color;
                                font-weight: 700;
                                width: 135px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                            &:nth-of-type(2) {
                                font-size: 10px;
                                color: #b2b2b2;
                            }
                        }
                    }
                    .msg-body {
                        margin-top: 10px;
                        span {
                            font-size: 10px;
                            color: #b2b2b2;
                            // width: 180px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: block;
                        }
                        .chat-messages-count {
                            display: inline-block;
                            height: 20px;
                            width: 20px;
                            color: $white-color;
                            text-align: center;
                            background-color: $primary-blue;
                            border-radius: 50%;
                            padding: 0px;
                            font-size: 9px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
                .card-end {
                    width: 5%;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: end;
                    -ms-flex-pack: end;
                    justify-content: flex-end;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    cursor: pointer;
                    svg {
                        fill: #58595b;
                    }
                }
                &::before,
                &::after {
                    display: none;
                }
            }
        }
    }
}

//styles for chat screen

.chat-screen-wrapper {
    height: 100%;
    padding: 20px;
    .chat-screen-head {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        span {
            &:first-of-type {
                font-size: 14px;
                font-weight: 700;
                color: $black-color;
            }
        }
        //classes for archived and unarchived for the icons
        .archived {
            svg {
                cursor: pointer;
                fill: #479ed7;
            }
        }
        .unarchived {
            svg {
                cursor: pointer;
                fill: #58595b;
            }
        }
    }
    .chat-wrap {
        height: 100%;
        > div > div {
            align-items: flex-start;
            overflow: auto;
            scrollbar-color: #f5f5f5 !important;
            scrollbar-width: thin !important;
            height: calc(100% - 80px) !important;
            &::-webkit-scrollbar {
                width: 5px;
                background-color: #f5f5f5;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
                background-color: #ccc;
            }
        }
        .toolbar-wrap {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            background: #f6f6f6;
            height: auto !important;
            border-radius: 6px;
            overflow: hidden;
            .send-btn {
                border: 0;
                border-radius: 5px;
                margin-right: 10px;
            }
            .dummy-field {
                margin-right: 10px;
                margin-left: 20px;
            }
            svg {
                fill: $light-grey;
                cursor: pointer;
                margin-right: 10px;
                margin-top: 5px;
            }
            textarea {
                background: transparent;
                border: 0;
                resize: none;
                height: 60px;
                padding-top: 17px;
                outline: none;
                width: 100%;
                border: 0;
                padding: 10px;
                &::placeholder {
                    padding-top: 2px;
                }
            }
            .ant-input:focus,
            .ant-input-focused {
                border-right: none !important;
                box-shadow: none !important;
            }
            .upload-wrapper {
                position: relative;
                cursor: pointer;
            }
            input[type='file'],
            #img {
                position: absolute;
                z-index: 0;
                opacity: 0;
                cursor: pointer;
            }
            .ant-input-textarea {
                width: 100%;
            }
        }
        .rn-borderTopWidth-5kkj8d {
            margin-bottom: 20px;
            height: 50px !important;
            width: 100%;
            border-top: 0;
            > div {
                width: 100%;
                position: relative;
                background: #f6f6f6;
                border-radius: 6px;
                textarea {
                    background: #f6f6f6;
                    margin-left: 0 !important;
                    padding: 10px;
                    font-size: 14px !important;
                    height: 50px !important;
                    padding-top: 10px !important;
                }
                > div {
                    height: 50px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    width: 65px;
                    div {
                        > div {
                            background: $dark-orange;
                            height: 30px;
                            margin: 0;
                            font-size: 14px;
                            display: flex;
                            align-items: center;
                            padding: 10px 7px;
                            border-radius: 6px;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

//styles for archive screen

.chat-archive-wrap {
    height: 100%;
    .archive-head {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 20px;
        span {
            font-size: 14px;
            color: $black-color;
            font-weight: 700;
        }
        .icon-wrap {
            svg {
                cursor: pointer;
                fill: #479ed7;
            }
        }
    }
    .archive-content {
        overflow: auto;
        height: 100%;
        padding: 0px 20px 20px 20px;
        scrollbar-color: #f5f5f5 !important;
        scrollbar-width: thin !important;
        padding-bottom: 60px;
        &::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
            background-color: #ccc;
        }
        .archive-content-item {
            cursor: pointer;
            padding: 8px 10px;
            box-shadow: 0px 6px 15px #0000000d;
            margin-bottom: 16px;
            .ant-card-body {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -ms-flex-direction: row;
                flex-direction: row;
                -webkit-box-pack: start;
                -ms-flex-pack: start;
                justify-content: flex-start;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                width: 100%;
                padding: 0 !important;
                overflow: visible;
                .card-left {
                    width: 10%;
                    .avatar-wrap {
                        position: relative;
                        .ant-avatar {
                            border: 1px solid $white-color;
                            &:nth-of-type(1) {
                                position: relative;
                                z-index: 1;
                                img {
                                    position: relative;
                                    z-index: 1;
                                }
                            }
                            &:nth-of-type(2) {
                                position: absolute;
                                z-index: 10;
                                right: 15px;
                                top: -7px;
                                img {
                                    position: relative;
                                    z-index: 10;
                                }
                            }
                        }
                    }
                }
                .card-right {
                    width: 90%;
                    .person-data {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-pack: justify;
                        -ms-flex-pack: justify;
                        justify-content: space-between;
                        span {
                            &:nth-of-type(1) {
                                font-size: 12px;
                                color: $black-color;
                                font-weight: 700;
                                width: 135px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                            &:nth-of-type(2) {
                                font-size: 10px;
                                color: #b2b2b2;
                            }
                        }
                    }
                    .msg-body {
                        margin-top: 10px;
                        span {
                            font-size: 10px;
                            color: #b2b2b2;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: block;
                        }
                    }
                }

                &::before,
                &::after {
                    display: none;
                }
            }
        }
    }
}

//styles for the new chat modal

.chat-modal {
    width: 346px !important;
    height: calc(50% - 5px);
    margin-top: 10px;
    .create-modal-loader {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        ._loading_overlay_overlay {
            border-radius: 20px;
        }
    }
    .ant-card-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .ant-modal-close {
        top: 14px;
        svg {
            width: 19px;
            height: 19px;
        }
    }
    .ant-modal-header {
        border-radius: 20px;
        border-bottom: 0;
        padding-top: 30px;
        padding-bottom: 20px;
        .ant-modal-title {
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            color: $black-color;
        }
    }
    .ant-modal-body {
        padding-top: 0;
    }
    .new-topic-wrap {
        .full-select {
            width: 100%;
            .ant-select-selector {
                height: 40px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                padding: 13px 10px;
                border: 0;
                border-radius: 3px;
                box-shadow: 0px 6px 15px #0000000d;
                color: $primary-blue;
                font-size: 12px;
            }
            .ant-select-arrow {
                color: $primary-blue;
            }
        }
        .full-input {
            border: 0;
            color: #000;
            border-bottom: 1px solid #f0f0f0;
            padding-left: 0;
        }
        .ant-input:focus,
        .ant-input-focused {
            box-shadow: none;
        }
        .float-label {
            margin-top: 15px;
            .label-float {
                top: -5px !important;
                left: 0;
            }
        }
        .create-btn {
            width: 100%;
            height: 40px;
            border-radius: 6px;
            color: #fff;
            border: 0;
            font-size: 12px;
            margin-top: 25px;
            margin-bottom: 10px;
            font-weight: 500;
        }
        .topic-btn {
            background-color: #999999;
            color: #fff;
        }
        .archive-btn {
            width: 100%;
            height: 48px;
            border-radius: 6px;
            color: #fff;
            border: 0;
            font-size: 12px;
            margin-bottom: 16px;
            font-weight: 500;
            &:nth-of-type(1) {
                margin-top: 10px;
            }
        }
        .form-err {
            font-size: 10px;
            color: $brick-red;
        }
        .multi-select {
            .ant-select-selector {
                padding: 0 !important;
            }
        }
        .ant-select-multiple {
            .ant-select-selector {
                position: relative;
                height: auto !important;
                min-height: 35px;
                padding: 0px 3px;
                .ant-select-selection-item {
                    padding: 0;
                    background: #fff;
                    border: 0;
                }
                .ant-select-selection-item-remove {
                    color: $primary-blue;
                }
                > span.ant-select-selection-item {
                    position: relative;
                    background: $white-color;
                    border: 0;
                    margin-right: 0;
                    padding: 0 4px 0 8px;
                    &:after {
                        content: ',';
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        color: $primary-blue;
                    }
                }
                span.ant-select-selection-item:nth-last-child(2) {
                    &:after {
                        content: none;
                    }
                }
                .ant-select-selection-search {
                    display: none;
                }
                .ant-select-selection-item-content {
                    color: $primary-blue;
                    margin-right: 0;
                }
                .ant-select-selection-item-remove {
                    display: none;
                }
                &:after {
                    position: absolute;
                    right: 10px;
                    top: 5px;
                    background: url('../../../Assets/select-drop.png') center no-repeat;
                    width: 20px;
                    height: 20px;
                    cursor: pointer;
                    background-size: 8px 11px;
                    -webkit-transform: rotate(-90deg);
                    -ms-transform: rotate(-90deg);
                    transform: rotate(-90deg);
                }
            }
            .ant-select-selection-placeholder {
                color: $primary-blue;
            }
        }
    }
    .upload-wrapper {
        position: relative;
        cursor: pointer;
    }
    input[type='file'],
    #img {
        position: absolute;
        z-index: 10;
        opacity: 0;
        cursor: pointer;
        width: 100%;
        height: 100%;
    }
}

.text-color {
    color: $primary-color;
}
//styles for chat toolbar

.chat-loader-overlay {
    ._loading_overlay_overlay {
        border-radius: 20px;
    }
}
.select-action-chat {
    display: flex;
    align-items: center;
    margin: 5px 0px;
    .ant-checkbox-wrapper {
        margin-right: 10px;
        .ant-checkbox-inner,
        .ant-checkbox-checked::after {
            border-radius: 50%;
        }
        .ant-checkbox:hover {
            &:after {
                visibility: hidden;
            }
        }
    }
}
.topic-copy-btn {
    height: auto !important;
    .ant-btn {
        width: 100%;
        border: 0;
        border-radius: 6px;
        height: 44px;
    }
}
.msg-action-modal {
    height: auto !important;
    .ant-modal-body {
        padding: 0px;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }
    .msg-body {
        margin-top: 10px;
        span {
            font-size: 10px;
            color: #b2b2b2;
            // width: 180px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
        }
        .chat-messages-count {
            display: inline-block;
            height: 18px;
            width: 18px;
            color: $white-color;
            text-align: center;
            background-color: $primary-blue;
            border-radius: 50%;
            padding: 2px;
            font-size: 10px;
        }
    }
    .chat-time {
        font-size: 10px;
        color: #b2b2b2;
    }
    .ant-card {
        margin-bottom: 10px;
    }
    .content-wrap {
        max-height: 350px;
        overflow: auto;
        overflow: auto;
        padding: 0px 20px;
        scrollbar-color: #f5f5f5 !important;
        scrollbar-width: thin !important;
        &::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
            background-color: #ccc;
        }
        .chat-content-item {
            cursor: pointer;
            .ant-card-body {
                display: flex;
                flex-direction: row;
                width: 100%;
                .card-left {
                    width: 25%;
                    .avatar-wrap {
                        position: relative;
                        .ant-avatar:nth-of-type(1) {
                            position: relative;
                            z-index: 10;
                        }
                        .ant-avatar:nth-of-type(2) {
                            position: absolute;
                            left: 20px;
                            top: -7px;
                            z-index: 1;
                        }
                    }
                }
                .card-right {
                    width: 75%;
                    .person-data {
                        display: flex;
                        justify-content: space-between;
                        span:nth-of-type(2) {
                            font-size: 11px;
                        }
                    }
                    .msg-txt {
                        margin-top: 5px;
                        span {
                            font-size: 11px;
                        }
                    }
                }
            }
        }
        .selected-item {
            background: #eaeaea;
        }
    }
    .msg-action-btn {
        margin: 10px 20px;
        padding-bottom: 10px;
        .ant-btn {
            width: 100%;
            border: 0;
            border-radius: 6px;
        }
    }
}
.audio-box {
    position: absolute;
    z-index: 1;
    width: 90px;
    height: 50px;
    left: 33px;
}

.audio-box2 {
    position: absolute;
    z-index: 1;
    display: flex;
    align-self: flex-end;
    width: 140px;
    height: 21px;
    left: 120px;
}


.chat-export-picker {
    display: flex;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    .chat-export-start-label {
        color: #a4a4a4;
        font-size: 13px;
    }
    .chat-export-inner {
        width : 50%;
        .ant-picker{
            height: 40px;
            width: 100%;
            align-items: center;
            padding: 13px 10px;
            border: 0;
            border-radius: 3px;
            box-shadow: 0px 6px 15px #0000000d;
            input {
                font-size: 14px;
                color: #40a9ff;
            }
        }
        
        &:nth-of-type(1){
            margin-right : 5px;
        }
         &:nth-of-type(2){
            margin-left : 5px;
        }

    }
    
}

    .export-chat-btn {
        border: 0;
        border-radius: 5px;
        span {
            color : #fff !important;
        }
    }

    .chat-downLoad-btn {
        text-decoration: none;
        padding: 10px;
        color: #fff;
        background-color: #f99c30;
        width: 100%; 
        display: flex;
        justify-content: center;
        border-radius: 6px;
        border: 0;
        &:focus,&:hover {
            color : #fff;
        }
    }
    .chat-export-wrap {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        .chat-export-img {
            padding-left : 15px;
        }
    }
    
    