@import 'Assets/styles/global.scss';
.party {
    &-card {
        height: calc(50% - 5px) !important;
        margin-top: 10px;
        .ant-card-body {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    }
    &-list {
        max-height: 300px;
        overflow: auto;
        padding: 0 10px 10px;
        margin-left: -10px;
        margin-right: -10px;
        scrollbar-color: #f5f5f5 !important;
        scrollbar-width: thin !important;
        &::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
            background-color: #ccc;
        }
    }
    &-inner {
        margin: 10px 0px 0;
        box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.05) !important;
        .ant-card-body {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            flex-direction: row !important;
            font-size: 12px;
            @media (min-width: 1920px) {
                font-size: 16px;
            }
            .relation {
                width: 50%;
                font-weight: 600;
                .tp-relation {
                    display:block;
                    font-weight: normal;
                }
            }
            .name {
                width: 50%;
                text-align: right;
            }
        }
    }
}
.add-third-party {
    .input-full {
        position: relative;
    }
}
.cp-popup {
    .remo_position {
        position: absolute !important;
    }
}
.tp-form {
    .form-err {
        color: $brick-red;
        font-size: 10px;
    }
}
.party-lis {
    .delete {
        fill: $brick-red;
        cursor: pointer;
    }
    .edit {
        fill: $primary-color;
        cursor: pointer;
    }
    .party-inner {
        padding: 10px !important ;
    }
}
.del-pop {
    .btn-confirm {
        .ant-btn {
            height: 44px !important;
            width: 100% !important;
        }
    }
}
.cust-select {
    .ant-select-selector {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 40px !important;
        border-radius: 5px !important;
        font-size: 12px !important;
        color: $light-grey !important;
    }
}
.multi-custom-select{
    .ant-select-selector{
        height: auto !important;
    }
}

.ant-card.party-card{
    .ant-card-body{
        padding: 0 !important;
        color: $light-grey;
    }
}

.permission-switch-wrap {
    display: flex;
    flex-direction: column;
    margin: 0px 10px;
    .permission-switch-inner {
        display: flex;
        width: 100%;
        justify-content: space-between;
        &:not(:last-of-type) {
            margin-bottom : 15px;
        }
    }
}
 .permission-switch-head {
    margin-bottom : 20px;
    font-size : 14px;
    font-weight : 600;
    border-bottom : 1px solid #e9e9e9 !important;
    padding-bottom : 5px; 
}
