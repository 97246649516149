@import 'Assets/styles/global.scss';
.sub {
    &-wrapper {
        background: $light-bg;
        min-height: 100vh;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;
        &:before {
            content: '';
            position: absolute;
            top: -30px;
            left: 0;
            width: 100%;
            height: 100%;
            max-height: 50vh;
            background: rgb(255, 151, 32);
            background: -moz-linear-gradient(
                90deg,
                rgba(255, 151, 32, 1) 0%,
                rgba(254, 157, 38, 1) 50%,
                rgba(249, 206, 85, 1) 100%
            );
            background: -webkit-linear-gradient(
                90deg,
                rgba(255, 151, 32, 1) 0%,
                rgba(254, 157, 38, 1) 50%,
                rgba(249, 206, 85, 1) 100%
            );
            background: linear-gradient(
                90deg,
                rgba(255, 151, 32, 1) 0%,
                rgba(254, 157, 38, 1) 50%,
                rgba(249, 206, 85, 1) 100%
            );
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff9720",endColorstr="#f9ce55",GradientType=1);
        }
        .logo {
            margin: 30px auto;
            position: relative;
            .logo-wrap {
                display: flex;
                flex-direction: column;
                align-items: center;
                img {
                    width: 46px;
                    height: 35px;
                    margin-bottom: 15px;
                }
                .logo-item {
                    font-size: 30px;
                    font-weight: 700;
                    color: $white-color;
                    margin-top: -10px;
                }
            }
        }
        .sub {
            &-card {
                max-width: 450px;
                margin: 0 auto;
                border-radius: 15px;
                &-head {
                    h1 {
                        font-size: 24px !important;
                        color: $light-grey;
                        text-align: center;
                        margin-bottom: 20px;
                        margin-top: 20px !important;
                        font-weight: 300;
                    }
                }
                .ant-card-body {
                    padding: 0px !important;
                    margin-bottom: 30px;
                }
                @media (min-width: 1990px) {
                    max-width: 440px;
                    &-head {
                        h1 {
                            font-size: 28px;
                        }
                    }
                }
                .tab-content {
                    .img-wrap {
                        width: 200px;
                        height: 200px;
                        margin: 0 auto;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .amount {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-orient: vertical;
                        -webkit-box-direction: normal;
                        -ms-flex-direction: column;
                        flex-direction: column;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        margin-bottom: 30px;
                        span {
                            &:nth-of-type(1) {
                                font-size: 50px;
                                font-weight: 500;
                                color: $black-color;
                            }
                            &:nth-of-type(2) {
                                font-size: 14px;
                                font-weight: 300;
                                color: $light-grey;
                                margin-top: -10px;
                            }
                        }
                    }
                    .contents {
                        border-top: 1px solid #58595b1a;
                        &-inner {
                            max-width: 350px;
                            margin: 0 auto;
                            padding: 20px 0px;
                            .item {
                                display: -webkit-box;
                                display: -ms-flexbox;
                                display: flex;
                                -webkit-box-pack: justify;
                                -ms-flex-pack: justify;
                                justify-content: space-between;
                                margin-bottom: 20px;
                                span {
                                    font-size: 14px;
                                    color: $light-grey;
                                    font-weight: 300;
                                    &:nth-of-type(2) {
                                        width: 20px;
                                        height: 20px;
                                        img {
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                }
                            }
                            .btn-wrapper {
                                .ant-btn {
                                    width: 100%;
                                    height: 44px;
                                    border: 0;
                                    border-radius: 6px;
                                    font-size: 16px;
                                    color: $white-color;
                                    margin: 10px 0px 10px 0px;
                                }
                            }
                        }
                    }
                }
            }
            &-wrap {
                .ant-tabs {
                    margin: 0 auto;
                    .ant-tabs-nav {
                        margin-bottom: 10px !important;
                        width: 350px;
                        max-width: 350px;
                        margin: 0 auto;

                        .ant-tabs-nav-wrap {
                            border-radius: 3px !important;
                            box-shadow: 0px 4px 10px #0000000d !important;
                            border: 1px solid $primary-blue;
                            .ant-tabs-nav-list {
                                width: 100%;
                                height: 44px;
                                .ant-tabs-tab {
                                    display: -webkit-box;
                                    display: -ms-flexbox;
                                    display: flex;
                                    -webkit-box-pack: center;
                                    -ms-flex-pack: center;
                                    justify-content: center;
                                    -webkit-box-align: center;
                                    -ms-flex-align: center;
                                    align-items: center;
                                    width: 50%;
                                    margin: 0;
                                    .ant-tabs-tab-btn {
                                        font-size: 12px;
                                        color: $light-grey;
                                    }
                                }
                                .ant-tabs-tab-active {
                                    background: $primary-blue;
                                    .ant-tabs-tab-btn {
                                        color: $white-color !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &-btn {
                .ant-btn {
                    width: 100%;
                    border: 1px solid $light-border;
                    border-radius: 3px;
                    height: 44px;
                    color: $light-grey;
                    background: $white-color;
                    margin-bottom: 5px;
                    font-weight: 600;
                }
                .text-sub {
                    font-size: 10px;
                    color: $light-grey;
                    display: flex;
                    justify-content: center;
                    font-weight: 600;
                }
                //style for selected button
                .selected {
                    @include secondary-gradient;
                    color: $white-color;
                    border: 0;
                }
            }
            &-input {
                margin: 20px 0px;
                .ant-input {
                    height: 36px;
                    border-radius: 3px;
                    border: 1px solid $light-border;
                    margin-bottom: 10px;
                    &::placeholder {
                        font-size: 12px;
                        color: $light-grey;
                    }
                }
                &-inner {
                    display: flex;
                    .ant-input {
                        margin-right: 10px;
                    }
                    .ant-btn {
                        height: 36px;
                        border-radius: 3px;
                        background: $dark-orange;
                        border: 0;
                        font-weight: 600;
                        span {
                            font-size: 14px;
                        }
                    }
                }
            }
            &-payment {
                padding-top: 20px;
                .ant-input {
                    border-radius: 3px;
                    border-color: $light-border;
                    height: 36px;
                }
                h2 {
                    font-size: 14px;
                    color: $light-grey;
                    text-align: center;
                    margin-bottom: 6px;
                    font-weight: 600;
                }

                .ant-form-item-label {
                    // top: -27px;
                    padding: 0;
                    label {
                        font-size: 12px;
                        color: $black-color;
                    }
                }
                .ant-picker {
                    min-height: 36px;
                    border-radius: 3px;
                    border-color: $light-border;
                    &-suffix {
                        color: $light-grey;
                    }
                }
                .form-inner {
                    display: flex;
                    .ant-form-item {
                        margin-bottom: 30px;
                        &:not(:last-of-type) {
                            margin-right: 10px;
                        }
                        &:first-of-type {
                            flex-basis: 80%;
                        }
                    }
                }
                .submit-btn {
                    width: 100%;
                    border-radius: 3px;
                    height: 40px;
                    background: $primary-blue;
                    border: 0;
                    margin-bottom: 0;
                    font-weight: 600;
                    span {
                        font-size: 14px;
                    }
                }
                .btn-form {
                    margin-bottom: 0;
                }
            }
        }
        //disabled style for buttons
        .disabled {
            background: $disabled-color !important;
        }
        .ach-tab {
            .ant-form-item {
                flex-basis: auto !important;
                &:last-of-type {
                    flex-basis: 60% !important;
                }
                .ant-select-selector {
                    height: 36px;
                    border-radius: 3px;
                    border-color: $light-border;
                }
            }
        }
    }
}
.mr-5 {
    margin-right: 5px;
}
.ml-5 {
    margin-left: 5px;
}

.w-100 {
    width: 100% !important;
}

.coupon-success {
    color: green;
    font-size: 15px;
}

.coupon-error {
    color: red;
    font-size: 15px;
}

.messageRight {
    position: absolute;
    right: 0px;
    top: 100px;
    z-index: 2000;
    font-size: 14px;
    background-color: #bf3341;
}
.messageRight p {
    font-size: 15px;
    color: #fff;
    font-weight: 400;
    padding: 6px 20px;
    margin: 0;
    border-radius: 3px 0 0 3px;
}
.messageRight .bg-success {
    background: #46c362;
    border-left: 10px solid #2f902f;
}
.messageRight .bg-danger {
    background: var(--danger) !important;
    border-left: 10px solid #bf3341;
}
.sub-er-pop {
    .ant-modal-title {
        max-width: 300px;
        margin: 0 auto;
    }
}
.subscription-loader {
    position: absolute !important;
    height: calc(100% + 30px);
    width: 100%;
    top: -30px;
    left: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    // font-size: 1.2em;
    // color: #fff;
    // background: rgba(0, 0, 0, 0.7);
    // z-index: 800;
    -webkit-transition: opacity 500ms ease-in;
    transition: opacity 500ms ease-in;
    opacity: 1;
}
.subscription-loader.css-79elbk {
    position: absolute !important;
}
.loader-d-none {
    display: none;
}
.input-sm {
    .input-50 {
        width : 50%;
    }
}
