@import 'Assets/styles/global.scss';
.swap {
    height: 50%;
    position: relative;
    .ant-card-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .loader-wrap {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
    &-create {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border: 0 !important;
        border-radius: 3px;
        // position: absolute;
        // right: 20px;
        // top: -50px;
        width: 70px;
        height: 30px;
        padding: 0;
        box-shadow: 0px 3px 6px #00000038;
        font-size: 12px;
        span {
            font-weight: 600;
            img {
                width: 10px;
                height: 10px;
                margin-bottom: 2px;
            }
            &:first-of-type {
                margin-right: 5px;
            }
        }
        @media (max-width: 1366px) {
            font-size: 10px;
            // top: -43px;
            // height: 25px;
        }
    }
    &-produce {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border: 0 !important;
        border-radius: 3px;
        // position: absolute;
        // left: 20px;
        // top: -50px;
        width: 70px;
        height: 30px;
        padding: 0;
        box-shadow: 0px 3px 6px #00000038;
        font-size: 12px;
        span {
            font-weight: 600;
            img {
                width: 10px;
                height: 10px;
                margin-bottom: 2px;
            }
            &:first-of-type {
                margin-right: 5px;
            }
        }
        @media (max-width: 1366px) {
            font-size: 10px;
            // top: -43px;
            // height: 25px;
        }
    }
    &-card-wrap {
        padding: 15px;
        overflow: auto;
        scrollbar-color: #f5f5f5 !important;
        scrollbar-width: thin !important;
        &::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
            background-color: #ccc;
        }

        .ant-card {
            &:not(:first-of-type) {
                margin-top: 10px;
            }
            .ant-card-body {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -ms-flex-direction: row;
                flex-direction: row;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
                width: 100%;
                padding: 10px !important;
                font-size: 12px;
                color: $light-grey;
                &:before,
                &:after {
                    display: none;
                }
                .card-left {
                    width: 40%;
                    .title {
                        font-weight: 600;
                    }
                    & > div {
                        margin-bottom: 2px;
                    }
                }
                .card-right {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -webkit-box-align: end;
                    -ms-flex-align: end;
                    align-items: flex-end;
                    width: 60%;
                    .icon {
                        -webkit-transform: rotate(90deg);
                        -ms-transform: rotate(90deg);
                        transform: rotate(90deg);
                        cursor: pointer;

                        span {
                            width: 15px;
                            height: 15px;
                            display: inline-block;
                            img {
                                width: 100%;
                                height: 100%;
                                opacity: 0.6;
                            }
                        }
                    }
                    .name {
                        color: $text-color;
                        font-weight: 600;
                        text-align: right;
                        // margin-right: 20px;
                    }
                    .edit {
                        margin-right: 20px;
                        span {
                            &:nth-of-type(2) {
                                img {
                                    width: 13px;
                                    height: 13px;
                                    margin-bottom: 3px;
                                    margin-left: 5px;
                                }
                            }
                        }
                    }
                    .d-none {
                        display: none;
                    }
                    & > div {
                        margin-bottom: 2px;
                    }
                }
            }
        }
        .swap-special {
            .ant-card-body {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                width: 100%;
                padding: 10px !important;
                font-size: 12px;
                color: $light-grey;
                &:before,
                &:after {
                    display: none;
                }
                .card-left {
                    -ms-flex-preferred-size: 40%;
                    flex-basis: 40%;
                    .title {
                        font-weight: 600;
                    }
                    & > div {
                        margin-bottom: 2px;
                    }
                    .date {
                        flex-basis: 45%;
                    }
                    .status {
                        flex-basis: 55%;
                    }
                }
                .card-right {
                    -ms-flex-preferred-size: 60%;
                    flex-basis: 60%;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -webkit-box-align: end;
                    -ms-flex-align: end;
                    align-items: flex-end;
                    .icon {
                        -webkit-transform: rotate(90deg);
                        -ms-transform: rotate(90deg);
                        transform: rotate(90deg);
                        cursor: pointer;

                        span {
                            width: 15px;
                            height: 15px;
                            display: inline-block;
                            img {
                                width: 100%;
                                height: 100%;
                                opacity: 0.6;
                            }
                        }
                    }
                    .name {
                        color: $text-color;
                        font-weight: 600;
                        margin-right: 20px;
                        text-align: right;
                    }
                    .edit {
                        margin-right: 20px;
                        span {
                            &:nth-of-type(2) {
                                img {
                                    width: 13px;
                                    height: 13px;
                                    margin-bottom: 3px;
                                    margin-left: 5px;
                                }
                            }
                        }
                    }
                    .d-none {
                        display: none;
                    }
                    & > div {
                        margin-bottom: 2px;
                    }
                    .status {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: end;
                        -ms-flex-align: end;
                        align-items: flex-end;
                        width: 100%;
                        .date-wrap {
                            -webkit-box-flex: 1;
                            -ms-flex: 1;
                            flex: 1;
                            // width: 35px;
                            // white-space: nowrap;
                            // overflow: hidden;
                            // text-overflow: ellipsis;
                            text-align: right;
                        }
                        .state {
                            -webkit-box-flex: 1.5;
                            -ms-flex: 1.5;
                            flex: 1.5;
                            text-align: center;
                        }
                        .status-date {
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            -webkit-box-pack: end;
                            -ms-flex-pack: end;
                            justify-content: flex-end;
                            // white-space: nowrap;
                            // overflow: hidden;
                            // text-overflow: ellipsis;
                            // text-align: right;
                            .activity {
                                margin-right: 5px;
                            }
                            .date {
                                display: -webkit-box;
                                display: -ms-flexbox;
                                display: flex;
                            }
                        }
                    }
                    .declined {
                        color: $brick-red;
                    }
                    .active {
                        color: $success-color;
                    }
                }
                .swap-special-inner {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                    width: 100%;
                }
            }
            .card-bottom {
                .state {
                    text-align: center;
                }
            }
        }
        .swap-data {
            .swap-special-inner {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
                width: 100%;
            }
        }
    }
    .ant-card-body {
        position: relative;
        padding: 0px !important;
        display: flex;
        flex-direction: column;
        max-height: calc(100% - 75px);
        &:before,
        &:after {
            display: none;
        }
    }
    &-modal {
        position: relative;
        .ant-card-body {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .loader-wrap {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
        .calendar-btn {
            .full-btn {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
                font-weight: 500 !important;
                span {
                    img {
                        width: 12px;
                        height: 12px;
                    }
                }
            }
            .ant-form-item-label {
                // top: -27px;
                padding: 0;
                color: $light-grey;
                label {
                    font-size: 12px;
                    color: $light-grey;
                }
            }
        }
        .sm-textarea {
            margin-top: 0px;
            .ant-form-item-label {
                // top: -32px;
                padding: 0;
                color: $light-grey;
                label {
                    font-size: 12px;
                    color: $light-grey;
                }
            }
            textarea {
                // border: 1px solid $light-grey;
                border-radius: 3px;
            }
        }
        .invite-top {
            // padding: 0px 20px 0px 20px;
            span {
                font-size: 12px;
                color: $light-grey;
            }
            .avatar-wrap {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                padding-bottom: 33px;
            }
        }
        .invite-form {
            .expiry {
                font-size: 12px;
                color: $light-grey;
            }
            .dates {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                span {
                    color: $light-grey;
                    font-size: 12px;
                    &:not(:last-of-type) {
                        margin-bottom: 6px;
                    }
                }
            }
            .calendar-btn {
                margin-bottom: 10px !important;
            }
            .memo-wrap {
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                span {
                    font-size: 12px;
                    color: $light-grey;
                    &:first-of-type {
                        color: $light-grey;
                        opacity: 0.5;
                    }
                }
            }

            .sm-textarea {
                .ant-form-item-label label {
                    font-size: 10px;
                }
                textarea {
                    box-shadow: 0px 4px 10px #0000000a;
                    max-height: 44px;
                }
            }
        }
        .schedule-form {
            .calendar-btn {
                margin-top: 10px;
                .ant-form-item-label {
                    // top: -20px !important;
                    label {
                        font-size: 10px !important;
                    }
                }
                .img-wrap {
                    transform: rotate(90deg);
                }
            }
            .exception-input {
                font-size: 12px;
            }
        }
        .select-sm {
            .ant-form-item-label {
                label {
                    font-size: 12px;
                    color: $light-grey;
                }
            }
        }
        .cal-icon {
            .react-calendar {
                .react-calendar__tile {
                    height: 60px;
                    width: 50px;
                }
                .dot-wrap {
                    position: absolute;
                    bottom: 15px;
                    height: 100%;
                    width: 100%;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    -webkit-box-align: end;
                    -ms-flex-align: end;
                    align-items: flex-end;
                    span {
                        display: block;
                        width: 5px;
                        height: 5px;
                        background: transparent;
                        border-radius: 50%;
                        margin: 2px;
                    }
                    .Pink {
                        background-color: #c910e8;
                    }
                    .Blue {
                        background-color: #2196f3;
                    }
                    .Purple {
                        background-color: #673ab7;
                    }
                    .Red {
                        background-color: #ff0000;
                    }
                    .Green {
                        background-color: #4caf50;
                    }
                    .Orange {
                        background-color: #ffc107;
                    }
                    .Cyan {
                        background-color: #00bcd4;
                    }
                    .Brown {
                        background-color: #795548;
                    }
                    .White {
                        background-color: #ffffff;
                        border: 1px solid #000000;
                    }
                    .Grey {
                        background-color: #999999;
                    }
                    .Charcoal {
                        background-color: #333333;
                    }
                    .Black {
                        background-color: #000000;
                    }
                    .Gradient1 {
                        background: linear-gradient(-200deg, #ffffff 50%, #999999 50%);
                        border: 1px solid #000000;
                    }
                    .Gradient2 {
                        background: linear-gradient(-200deg, #ffffff 50%, #000000 50%);
                        border: 1px solid #000000;
                    }
                    .blue {
                        background: $primary-color;
                    }
                    .red {
                        background: $brick-red;
                    }
                    .orange {
                        background: $dark-orange;
                    }
                    .green {
                        background: $success-color;
                    }
                    .brown {
                        background: $brown-color;
                    }
                    .violet {
                        background: $violet-color;
                    }
                    .rose {
                        background: $rose-color;
                    }
                    .grey {
                        background: $light-grey;
                    }
                    .dot-inside {
                        width: 35px;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        -ms-flex-wrap: wrap;
                        flex-wrap: wrap;
                    }
                }
            }
            .tile-circle {
                > div {
                    color: #fff;
                    position: relative;
                    z-index: 10;
                }
                &:after {
                    content: '';
                    position: absolute;
                    left: 5px;
                    top: -6px;
                    width: 30px;
                    height: 30px;
                    vertical-align: middle;
                    background: #ff9720;
                    border-radius: 50%;
                    border: 4px solid #fffefdc4;
                    // @media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio:0) {
                    //     left: 5px;
                    //     top: 6px;
                    // }
                    @media not all and (min-resolution: 0.001dpcm) {
                        @supports (-webkit-appearance: none) {
                            left: 5px;
                            top: 6px;
                        }
                    }
                }
                &:before {
                    content: '-';
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    font-weight: 900;
                    background: #ff9720;
                    z-index: 11;
                    top: 8px;
                    right: 3px;
                    color: #fff;
                    border-radius: 50%;
                    border: 1px solid #fff;
                    font-size: 10px;
                    line-height: 1.1;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: end;
                    -ms-flex-align: end;
                    align-items: flex-end;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    // @media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio:0) {
                    //     top: 16px !important;
                    // }
                    @media not all and (min-resolution: 0.001dpcm) {
                        @supports (-webkit-appearance: none) {
                            top: 16px !important;
                        }
                    }
                }
            }
            .trade-tile-circle {
                > div {
                    color: #fff;
                    position: relative;
                    z-index: 10;
                }
                &:after {
                    content: '';
                    position: absolute;
                    left: 5px;
                    top: -6px;
                    width: 30px;
                    height: 30px;
                    vertical-align: middle;
                    background: #479ed7;
                    border-radius: 50%;
                    border: 4px solid #fffefdc4;
                    // @media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio:0) {
                    //     left: 5px;
                    //     top: 6px;
                    // }
                    @media not all and (min-resolution: 0.001dpcm) {
                        @supports (-webkit-appearance: none) {
                            left: 5px;
                            top: 6px;
                        }
                    }
                }
                &:before {
                    content: '+';
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    font-weight: 900;
                    background: #479ed7;
                    z-index: 11;
                    top: 8px;
                    right: 3px;
                    color: #fff;
                    border-radius: 50%;
                    border: 1px solid #fff;
                    font-size: 10px;
                    line-height: 1.1;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: end;
                    -ms-flex-align: end;
                    align-items: flex-end;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    // @media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio:0) {
                    //     top: 16px !important;
                    // }
                    @media not all and (min-resolution: 0.001dpcm) {
                        @supports (-webkit-appearance: none) {
                            top: 16px !important;
                        }
                    }
                }
            }
        }
        .cust-mt {
            margin-top: 15px;
        }
    }
    .swap-icon {
    }
}
.exception {
    .detail-wrap {
        font-size: 10px;
        color: #0a0a0a;
        .detail-item {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            margin-bottom: 15px;
        }
    }
    .btn-top {
        margin-top: 5px;
        .ant-btn {
            height: 40px;
            border-radius: 3px;
            font-size: 12px;
        }
    }
}

.exception-modal {
    position: relative;
    .ant-card-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .loader-wrap {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
    .child-input {
        margin: 20px 0px;
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 6px 15px #0000000d;
        border-radius: 3px;
        opacity: 1;
        border: none;
        .cinput {
            border: none;
            color: $primary-color;
        }
    }
    .heading-style {
        margin: 10px 0;
        font-size: 12px;
        color: #999999;
    }
    .save-btn {
        background-color: $secondary-color;
        color: #fff;
        width: 100%;
        border: 0;
        margin-top: 15px;
        height: 40px;
        border-radius: 3px;
        margin-bottom: 0 !important;
        span {
            font-size: 12px;
            font-weight: 600;
            color: $white-color;
        }
    }
    .red-btn {
        background-color: $brick-red !important;
        color: #fff;
        width: 100%;
        border: 0;
        height: 40px;
        border-radius: 3px;
        margin-bottom: 0 !important;
        span {
            font-size: 12px;
            font-weight: 600;
            color: $white-color;
        }
    }
    .grey-btn {
        background-color: $light-grey;
        color: #fff;
        width: 100%;
        border: 0;
        // margin-top: 15px;
        height: 40px;
        border-radius: 3px;
        margin-bottom: 0 !important;
        span {
            font-size: 12px;
            font-weight: 600;
            color: $white-color;
        }
    }
}

.forfeit-list {
    margin-bottom: 15px;
}
.trade-list {
    margin-bottom: 15px;
}
.display-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}
.swap-details {
    margin: 20px 0px;
    display: flex;
    justify-content: space-between;
    span {
        font-size: 12px !important;
    }
}
.exception-input {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 3px 3px #0000000a;
    border-radius: 3px;
    opacity: 1;
    color: $primary-color;
    border: 1px solid transparent;
}
.cal-modal {
    .scroll-cust {
        min-height: 343px !important;
        max-height: 100% !important;
    }
}
.cal-with-dots {
    .set-date {
        &:after {
            @media not all and (min-resolution: 0.001dpcm) {
                @supports (-webkit-appearance: none) {
                    left: 7px !important;
                    top: 8px !important;
                }
            }
        }
    }
}

.exception-kid-color-card {
    .exception-list-dot {
        display: inline-block;
        width: 7px;
        height: 7px;
        margin-right: 5px;
        border-radius: 50%;
        background-color: #ff0000;
    }
     .blue {
        background: $primary-blue;
    }
    .orange {
        background: $dark-orange;
    }
    .green {
        background: $success-color;
    }
    .Pink {
        background-color: #c910e8;
    }
    .Blue {
        background-color: #2196f3;
    }
    .Purple {
        background-color: #673ab7;
    }
    .Red {
        background-color: #ff0000;
    }
    .Green {
        background-color: #4caf50;
    }
    .Orange {
        background-color: #ffc107;
    }
    .Cyan {
        background-color: #00bcd4;
    }
    .Brown {
        background-color: #795548;
    }
    .White {
        background-color: #ffffff;
        border: 1px solid #000000;
    }
    .Grey {
        background-color: #999999;
    }
    .Charcoal {
        background-color: #333333;
    }
    .Black {
        background-color: #000000;
    }
    .Gradient1 {
        background: linear-gradient(-200deg, #ffffff 50%, #999999 50%);
        border: 1px solid #000000;
    }
    .Gradient2 {
        background: linear-gradient(-200deg, #ffffff 50%, #000000 50%);
        border: 1px solid #000000;
    }
}

.date-cal-modal {
    width : 385px !important;
    .scroll-wrap {
        padding : 0px 10px 0px 10px;
    }
    .dot-wrap {
        bottom: 10px !important;
        .dot-inside {
            width: 45px !important;
        }
    }
}
.custom-card-btn-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    .custom-head-title{
        font-weight: 700;  
        @media(max-width: 1150px) and (min-width: 991px){
            display: block;
            text-align: center;
            width: 100px;
            word-wrap: break-word;
            white-space: initial;
        } 
    }
    .title{
        font-size: 16px;
        font-weight: 700;
        color: #000000;    
        display: block;
        margin-bottom: 10px;
        @media(max-width: 1366px){
            font-size: 14px;
        }
        &:nth-of-type(2){
            text-align: right;
        }
    }
}
.swap-spcl-dates{
    .ant-card-body{
        max-height: 100% !important;
    }

}

