@import 'Assets/styles/global.scss';
.custody-swap-card,
.p-t-card,
.reconcile-card,
.time-ratio-card {
    .ant-card-body {
        height: 100%;
        padding: 0 !important;
        .card-head {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding: 35px 20px !important;
            margin: 0 !important;
            span {
                color: $light-grey;
            }
            .add-btn {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                padding: 0;
                span {
                    color: $white-color;
                    font-size: 18px !important;
                    font-weight: 700;
                    line-height: 1;
                    margin-top: 1px;
                }
            }
        }
        .card-body {
            height: calc(100% - 94px);
        }
        .ant-tabs {
            height: 100%;
            .ant-tabs-nav {
                .ant-tabs-nav-wrap {
                    border-radius: 0;
                    margin: 0px 20px;
                    box-shadow: none;
                    height: 40px;
                    border-radius: 3px;
                    .ant-tabs-nav-list {
                        width: 100%;
                        .ant-tabs-tab {
                            margin: 0;
                            width: 50%;
                            -webkit-box-pack: center;
                            -ms-flex-pack: center;
                            justify-content: center;
                            border: 1px solid $dark-orange;
                            &:hover {
                                color: $light-grey;
                            }
                            .ant-tabs-tab-btn {
                                @media (max-width: 1919px) {
                                    font-size: 13px !important;
                                }
                            }
                        }
                        .ant-tabs-tab-active {
                            background: $dark-orange;
                            .ant-tabs-tab-btn {
                                color: $white-color;
                            }
                        }
                    }
                }
                &::before {
                    display: none;
                }
            }
        }
        .ant-tabs-content {
            height: 100%;
            .tab-content-wrap {
                padding: 0px 20px;
                height: 100%;
                overflow: auto;
                scrollbar-color: #f5f5f5 !important;
                scrollbar-width: thin !important;
                padding-top: 24px;
                &::-webkit-scrollbar {
                    width: 5px;
                    background-color: #f5f5f5;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 5px;
                    -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
                    background-color: #ccc;
                }
                .card-inner {
                    cursor: pointer;
                    padding: 8px 14px;
                    border-radius: 6px;
                    box-shadow: 0px 6px 15px #58595b1a;
                    margin-bottom: 14px;
                }
            }
        }
    }
    .card-inner {
        .card-top {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            span {
                color: $light-grey;
                margin-bottom: 15px;
                width: 50%;
                &:nth-of-type(1) {
                    font-size: 14px;
                    font-weight: 500;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                &:nth-of-type(2) {
                    font-size: 12px;
                    font-weight: 300;
                    text-align: right;
                }
            }
        }
        .card-bottom {
            span {
                font-size: 12px;
                font-weight: 300;
                color: $light-grey;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
.report-modal {
    width: 346px !important;
    .ant-modal-close {
        top: 14px;
        svg {
            width: 19px;
            height: 19px;
        }
    }
    .ant-modal-header {
        border-radius: 20px;
        border-bottom: 0;
        padding-top: 30px;
        padding-bottom: 20px;
        .ant-modal-title {
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            color: $black-color;
        }
    }
    .ant-modal-body {
        padding-top: 0;
    }
    .modal-body {
        .full-select {
            width: 100%;
            margin-bottom: 10px;
            .ant-select-selector {
                height: 40px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                padding: 13px 10px;
                border: 0;
                border-radius: 3px;
                box-shadow: 0px 6px 15px #0000000d;
                color: $primary-blue;
                font-size: 12px;
            }
            .ant-select-arrow {
                color: $primary-blue;
            }
        }
        .create-btn {
            width: 100%;
            height: 40px;
            border-radius: 6px;
            color: #fff;
            border: 0;
            font-size: 12px;
            margin-top: 10px;
            margin-bottom: 10px;
            font-weight: 500;
        }
        .form-err {
            font-size: 10px;
            color: $brick-red;
        }
        .ant-select-multiple {
            .ant-select-selector {
                position: relative;
                height: auto !important;
                min-height: 35px;
                padding: 0px 3px;
                .ant-select-selection-item {
                    padding: 0;
                    background: #fff;
                    border: 0;
                }
                .ant-select-selection-item-remove {
                    color: $primary-blue;
                }
                > span.ant-select-selection-item {
                    position: relative;
                    background: $white-color;
                    border: 0;
                    margin-right: 0;
                    padding: 0 4px 0 8px;
                    &:after {
                        content: ',';
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        color: $primary-blue;
                    }
                }
                span.ant-select-selection-item:nth-last-child(2) {
                    &:after {
                        content: none;
                    }
                }
                .ant-select-selection-search {
                    display: none;
                }
                .ant-select-selection-item-content {
                    color: $primary-blue;
                    margin-right: 0;
                }
                .ant-select-selection-item-remove {
                    display: none;
                }
                &:after {
                    position: absolute;
                    right: 10px;
                    top: 5px;
                    background: url('../../Assets/select-drop.png') center no-repeat;
                    width: 20px;
                    height: 20px;
                    cursor: pointer;
                    background-size: 8px 11px;
                    -webkit-transform: rotate(-90deg);
                    -ms-transform: rotate(-90deg);
                    transform: rotate(-90deg);
                }
            }
            .ant-select-selection-placeholder {
                color: $primary-blue;
            }
        }
        .ant-select-open {
            .ant-select-selector {
                &:after {
                    -webkit-transform: rotate(90deg);
                    -ms-transform: rotate(90deg);
                    transform: rotate(90deg);
                    -webkit-transition: all ease-in-out 0.2s;
                    -o-transition: all ease-in-out 0.2s;
                    transition: all ease-in-out 0.2s;
                }
            }
        }
    }
    .date-picker-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        .date-picker-inner {
            width: 50%;
            .cust-dp {
                height: 40px;
                width: 100%;
                align-items: center;
                padding: 13px 10px;
                border: 0;
                border-radius: 3px;
                box-shadow: 0px 6px 15px #0000000d;
                .ant-picker-input {
                    input {
                        font-size: 12px;
                        color: #479ed7;
                    }
                    svg {
                        fill: #479ed7;
                    }
                }
            }
            &:nth-of-type(1) {
                margin-right: 5px;
            }
            &:nth-of-type(2) {
                margin-left: 5px;
            }
            .dp-label {
                font-size: 12px;
                color: $text-sec-color;
            }
        }
    }
    .pop-loader {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
}
.report-data-modal {
    width: 446px !important;
    .ant-modal-header {
        border-radius: 20px;
        border-bottom: 0;
        padding-top: 30px;
        padding-bottom: 20px;
        .ant-modal-title {
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            color: $black-color;
        }
    }
    .ant-modal-close {
        top: 14px;
        svg {
            width: 19px;
            height: 19px;
        }
    }
    .ant-modal-body {
        padding: 0;
    }
    .modal-top {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-left: 20px;
        margin-bottom: 20px;
        span {
            margin-bottom: 10px;
            color: $light-grey;
            &:nth-of-type(1) {
                font-size: 14px;
                font-weight: 500;
            }
            &:nth-of-type(2) {
                font-size: 12px;
                font-weight: 300;
            }
        }
    }
    .ant-tabs {
        height: 100%;
        .ant-tabs-nav {
            .ant-tabs-nav-wrap {
                border-radius: 0;
                margin: 0px 20px;
                box-shadow: none;
                height: 40px;
                box-shadow: 0px 4px 10px #0000000d;
                .ant-tabs-nav-list {
                    width: 100%;
                    border: 1px solid $dark-orange;
                    border-radius: 3px;
                    .ant-tabs-tab {
                        margin: 0;
                        width: 50%;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        &:hover {
                            color: $light-grey;
                        }
                    }
                    .ant-tabs-tab-active {
                        background: $dark-orange;
                        .ant-tabs-tab-btn {
                            color: $white-color;
                        }
                    }
                }
            }
            &::before {
                display: none;
            }
        }
    }
    .ant-tabs-content {
        height: 100%;
        .tab-content-wrap {
            padding: 0px;
            height: 100%;
            overflow: auto;
            scrollbar-color: #f5f5f5 !important;
            scrollbar-width: thin !important;

            &::-webkit-scrollbar {
                width: 5px;
                background-color: #f5f5f5;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
                background-color: #ccc;
            }
            .card-inner {
                cursor: pointer;
                padding: 8px 14px;
                border-radius: 6px;
                box-shadow: 0px 6px 15px #58595b1a;
                margin-bottom: 14px;
            }
        }
    }
    .thead {
        display: flex;
        justify-content: space-between;
        span {
            width: 33.3%;
            text-align: center;
            margin: 4px 0px;
        }
    }
    .content-inner {
        padding: 0px 5px;
        max-height: 280px;
        overflow: auto;
        scrollbar-color: #f5f5f5 !important;
        scrollbar-width: thin !important;
        padding: 0px 10px;
        max-height: 250px;
        margin: 0px 10px 10px 10px;
        &::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
            background-color: #ccc;
        }
        .t-row {
            .row-item {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
                margin-bottom: 10px;
                border-bottom: 1px dotted #ccc;
                img {
                    width: 20px;
                }
                span {
                    width: 33.3%;
                    font-size: 12px;
                    font-weight: 300;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    margin: 4px 0px;
                    &:last-of-type {
                        text-align: right;
                        -webkit-box-pack: end;
                        -ms-flex-pack: end;
                        justify-content: flex-end;
                        -ms-flex-wrap: wrap;
                        flex-wrap: wrap;
                    }
                    &:nth-child(2) {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                    }
                }
                .multi-date {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -webkit-box-align: end;
                    -ms-flex-align: end;
                    align-items: flex-end;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                }
                .multi-date-left {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -webkit-box-align: start;
                    -ms-flex-align: start;
                    align-items: flex-start;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                }
                .arrow-both {
                    position: relative;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        background: url('../../Assets/left_arrow.png') no-repeat center;

                        width: 20px;
                        height: 20px;
                        background-size: contain;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        right: 0;
                        background: url('../../Assets/right_arrow.png') no-repeat center;
                        width: 20px;
                        height: 20px;
                        background-size: contain;
                    }
                }
                .arrow-left {
                    position: relative;
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        background: url('../../Assets/left_arrow.png') no-repeat center;
                        width: 20px;
                        height: 20px;
                        background-size: contain;
                    }
                }
                .arrow-right {
                    position: relative;
                    &::after {
                        content: '';
                        position: absolute;
                        right: 0;
                        background: url('../../Assets/right_arrow.png') no-repeat center;
                        width: 20px;
                        height: 20px;
                        background-size: contain;
                    }
                }
            }
            .top-row-item {
                span {
                    width: 25%;
                }
            }
            .row-head {
                margin-bottom: 10px;
                border-bottom: 1px solid #cccc;
                span {
                    width: 33.3%;
                    font-size: 12px;
                    font-weight: 500;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    margin: 4px 0px;
                    &:nth-child(2) {
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        text-align: center;
                    }
                    &:nth-child(3) {
                        -webkit-box-pack: end;
                        -ms-flex-pack: end;
                        justify-content: flex-end;
                    }
                }
            }
            .top-head {
                span {
                    width: 25%;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    margin: 4px 0px;
                    &:nth-child(2) {
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        text-align: center;
                    }
                    &:nth-child(3) {
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        text-align: center;
                    }
                    &:nth-child(4) {
                        -webkit-box-pack: end;
                        -ms-flex-pack: end;
                        justify-content: flex-end;
                    }
                }
            }
        }
    }
    .btn-save-wrap {
        margin: 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        .ant-btn {
            width: 306px;
            height: 44px;
            border-radius: 6px;
            box-shadow: 0px 3px 3px #00000033;
            span {
                color: #fff;
                font-size: 13px;
            }
        }
    }
    .pop-loader {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
}
.text-color {
    color: $primary-color;
}
.report-page-wrap {
    height: 100%;
    .pop-loader {
        position: absolute !important;
    }
}
.report-reconcile-wrap {
    height: 50%;
    .reconcile-card {
        height: 100%;
    }
    .pop-loader {
        position: absolute;
        width: 100%;
        height: 50%;
        left: 0;
        top: 0;
        ._loading_overlay_overlay {
            border-radius: 20px;
        }
    }
}
.report-time-wrap {
    height: calc(50% - 10px);
    position: relative;
    .time-ratio-card {
        height: 100%;
    }
    .pop-loader {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        ._loading_overlay_overlay {
            border-radius: 20px;
        }
    }
}
