@import 'Assets/styles/global.scss';
.nt-card {
    height: calc(100% - 79px);
    &-head {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin: 15px 10px 10px 10px;
        padding: 15px;
        h1 {
            font-size: 20px !important;
            margin: 0 !important;
            font-weight: 700 !important;
        }
        .btn-add-req {
            border: none;
            border-radius: 8px;
            width: 165px;
            height: 35px;
            padding: 0 !important;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }
    }
    .divider {
        margin-left: -15px;
        margin-right: -15px;
        border-bottom: 1px solid rgba(153, 153, 153, 0.2);
    }
    &-body {
        margin: 0px 35px;
        .list-card {
            margin-top: 16px;
            box-shadow: 0px 6px 15px #0000000d;
            padding: 0px;
            .req {
                &-inner {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                }
                &-details {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -ms-flex-preferred-size: 33.3%;
                    flex-basis: 33.3%;
                    span {
                        color: $text-sec-color;
                        font-size: 16px;
                        &:first-of-type {
                            color: $text-color;
                        }
                    }
                }
                &-action {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -ms-flex-preferred-size: 33.3%;
                    flex-basis: 33.3%;
                    span {
                        font-size: 16px;
                    }
                    .icon-wrap {
                        span {
                            font-size: 12px;
                            color: #999999;
                            margin-left: 3px;
                        }
                        .anticon {
                            width: 13px;
                            height: 13px;
                            svg {
                                width: 100%;
                                height: 100%;
                                fill: #38a0eb;
                            }
                        }
                    }
                }
                &-amount {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    text-align: right;
                    -ms-flex-preferred-size: 33.3%;
                    flex-basis: 33.3%;
                    span {
                        color: $text-sec-color;
                        font-size: 12px;
                        &:first-of-type {
                            font-weight: 700;
                            color: $text-color;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 1919px) {
        &-head {
            h1 {
                font-size: 14px !important;
            }
            .btn-add-req {
                border-radius: 6px;
                width: 130px;
                height: 30px;
                span {
                    font-size: 11px;
                }
            }
        }
        &-body {
            margin: 0px;
            .list-card {
                margin-top: 16px;
                box-shadow: 0px 6px 15px #0000000d;
                padding: 0px;
                .req {
                    &-details {
                        span {
                            font-size: 11px !important;
                            color: $text-sec-color;
                            &:first-of-type {
                                color: $text-color;
                                font-size: 12px !important;
                            }
                        }
                    }
                    &-action {
                        span {
                            font-size: 12px !important;
                        }
                    }
                    &-amount {
                        span {
                            font-size: 11px !important;
                            &:first-of-type {
                                font-size: 12px !important;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 1366px) {
        &-head {
            h1 {
                font-size: 13px !important;
            }
            .btn-add-req {
                span {
                    font-size: 10px;
                }
            }
        }
        .nt-card-body .list-card .req-action span {
            font-size: 9px !important;
        }
    }
    .loader-wrap {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.nt-card.ant-card {
    .ant-card-body {
        padding: 0px !important;
        .scrollarea-content {
            padding: 15px !important;
            .list-card {
                .ant-card-body {
                    padding: 12px !important    ;
                }
            }
        }
    }
}
