@import 'Assets/styles/global.scss';

.calenderlist {
    height: 100%;
    position: relative;
    .loader-wrap {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.react-calendar {
    width: 100% !important;
    border: 0 !important;
    font-family: 'Museo' !important;
    .child-name {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: 10px 0px 20px 0px;
        span {
            font-size: 12px;
            margin-right: 10px;
            font-weight: 500;
            @media (max-width: 1366px) {
                font-size: 11px;
            }
        }
        .Pink {
            color: $rose-color;
        }
        .Blue {
            color: $primary-color;
        }
        .Purple {
            color: $violet-color;
        }
        .Red {
            color: $danger-color;
        }
        .Green {
            color: $success-color;
        }
        .Orange {
            color: $secondary-color;
        }
        .Cyan {
            color: #00bcd4;
        }
        .Brown {
            color: #795548;
        }
        .White {
            color: #000000;
        }
        .Grey {
            color: #999999;
        }
        .Charcoal {
            color: $text-color;
        }
        .Black {
            color: $black-color;
        }
        .Gradient1 {
            color: #999999;
        }
        .Gradient2 {
            color: #000000;
        }
        .blue {
            border-color: #38a0eb;
            span {
                color: #38a0eb;
            }
        }
        .orange {
            border-color: #fca232;
            span {
                color: #fca232;
            }
        }
        .green {
            border-color: #22b422;
            span {
                color: #22b422;
            }
        }
        .violet {
            border-color: #dc2cd1;
            span {
                color: #dc2cd1;
            }
        }
    }
    .day-item {
        .day {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-height: 100px;
            height: 100px;
            flex-basis: 12% !important;
            max-width: 12% !important;
            margin: 0px 5px;
            padding: 0px !important;
            margin-bottom: 5px;
            @media(max-width: 1182px) and (min-width: 990px){
                flex-basis: 11% !important;
                max-width: 11% !important;
            }
            @media(max-width: 520px){
                flex-basis: 11% !important;
                max-width: 11% !important;
            }
            @media(max-width: 380px){
                flex-basis: 10% !important;
                max-width: 10% !important;
            }
            .react-calendar__tile {
                font-weight: 300;
                width: 35px;
            }
        }
        .selected {
            border-radius: 12px;
            border: 1px solid $grey-border;
        }
    }
    .child-block {
        display: block;
        max-width: 40px;
        overflow: hidden;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        span {
            width: 7px;
            height: 7px;
            display: inline-block;
            border-radius: 50%;
            background: transparent;
            margin: 2px 3px;
        }
        .Pink {
            background-color: #c910e8;
        }
        .Blue {
            background-color: #2196f3;
        }
        .Purple {
            background-color: #673ab7;
        }
        .Red {
            background-color: #ff0000;
        }
        .Green {
            background-color: #4caf50;
        }
        .Orange {
            background-color: #ffc107;
        }
        .Cyan {
            background-color: #00bcd4;
        }
        .Brown {
            background-color: #795548;
        }
        .White {
            background-color: #ffffff;
            border: 1px solid #000000;
        }
        .Grey {
            background-color: #999999;
        }
        .Charcoal {
            background-color: #333333;
        }
        .Black {
            background-color: #000000;
        }
        .Gradient1 {
            background: linear-gradient(-200deg, #ffffff 50%, #999999 50%);
            border: 1px solid #000000;
        }
        .Gradient2 {
            background: linear-gradient(-200deg, #ffffff 50%, #000000 50%);
            border: 1px solid #000000;
        }
        .blue {
            background: $primary-color;
        }
        .red {
            background: $brick-red;
        }
        .orange {
            background: $dark-orange;
        }
        .green {
            background: $success-color;
        }
        .brown {
            background: $brown-color;
        }
        .violet {
            background: $violet-color;
        }
        .rose {
            background: $rose-color;
        }
        .grey {
            background: $light-grey;
        }
    }
}
.react-calendar__tile {
    padding: 10px 20px;
    position: relative;
    font-size: 16px;
    font-weight: 600;
    color: $light-grey;
    &:focus {
        background: transparent;
    }
    @media (max-width: 1366px) {
        font-size: 14px;
    }
}
.tile-circle {
    abbr {
        color: #fff;
        position: relative;
        z-index: 10;
    }
    &:after {
        content: '';
        position: absolute;
        left: 1px;
        top: 0;
        width: 100%;
        height: 100%;
        background: #ff9720;
        border-radius: 50%;
        border: 4px solid #fffefdc4;
    }
    &:before {
        content: '-';
        position: absolute;
        width: 15px;
        height: 15px;
        font-weight: 900;
        background: #ff9720;
        z-index: 11;
        top: 1px;
        right: -5px;
        color: #fff;
        border-radius: 50%;
        border: 1px solid #fff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}
.trade-tile-circle {
    abbr {
        color: #fff;
        position: relative;
        z-index: 10;
    }
    &:after {
        content: '';
        position: absolute;
        left: 1px;
        top: 0;
        width: 100%;
        height: 100%;

        background: #479ed7;
        border-radius: 50%;
        border: 4px solid #fffefdc4;
    }
    &:before {
        content: '+';
        position: absolute;
        width: 15px;
        height: 15px;
        font-weight: 900;
        font-size: 12px;
        line-height: 1.2;

        background: #479ed7;
        z-index: 11;
        top: 1px;
        right: -5px;
        color: #fff;
        border-radius: 50%;
        border: 1px solid #fff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}
.react-calendar__navigation button {
    span {
        font-size: 16px;
        font-weight: 700;
        color: $black-color;
        @media (max-width: 1366px) {
            font-size: 14px;
        }
    }
    &:hover {
        background: transparent !important;
    }
}
.react-calendar__month-view__days__day--weekend {
    color: $light-grey;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: transparent !important;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background: transparent !important;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background: transparent !important;
}
.react-calendar__navigation__arrow .react-calendar__navigation__next2-button {
    display: none;
}
.react-calendar__tile--now {
    background: transparent !important;
}
.react-calendar__month-view__weekdays__weekday {
    text-transform: capitalize;
    flex-basis: 12% !important;
    max-width: 12% !important;
    margin: 0px 5px;
    font-weight: 600;
    @media(max-width: 1182px) and (min-width: 990px){
        flex-basis: 11% !important;
        max-width: 11% !important;
    }
    @media(max-width: 520px){
        flex-basis: 11% !important;
        max-width: 11% !important;
    }
    @media(max-width: 380px){
        flex-basis: 10% !important;
        max-width: 10% !important;
    }
    

    abbr[title] {
        text-decoration: none;
        cursor: none;
        font-size: 16px;
        color: $light-grey;
        @media (max-width: 1366px) {
            font-size: 14px;
        }
    }
}
.react-calendar__month-view__weekdays {
    font-size: 16px;
    margin-bottom: 20px;
    justify-content: center;
}
.react-calendar__month-view__days.day-item{
    justify-content: center;
}
.react-calendar__tile--active {
    background: transparent;
    color: $light-grey;
}
.react-calendar__navigation {
    position: relative;
    height: auto;
    padding-bottom: 20px;
    margin-top: 10px;
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 17px;
        width: calc(100% - 30px);
        border-bottom: 1px solid $grey-border;
    }
    button {
        font-size: 40px;
    }
    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
        display: none !important;
    }
}
