@import 'Assets/styles/colors.scss';
@import 'Assets/styles/typography.scss';
@import 'Assets/styles/media.scss';

:root {
    body {
        margin: 0;
        font-family: 'Museo';
        font-size: 14px;
        font-weight: 500;
        color: $text-color;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        overflow-x: hidden;
    }
    .fp {
        &-button {
            font-size: 1rem;
            padding: 8px 24px;
            color: $white-color;
            line-height: 0;
            font-weight: 600;
            text-shadow: none;
            &-sm {
                font-size: 1rem;
                padding: 4px 16px;
                color: $white-color;
            }
        }
        &-button-primary {
            @include primary-gradient();
        }
        &-button-secondary {
            @include secondary-gradient();
        }
    }
    .ant-select {
        &-item {
            &-option {
                font-size: 12px;
            }
        }
    }
    .scrollarea .scrollbar-container.vertical {
        width: 5px !important;
    }
}

select.slt-2-box {
    width: calc(100% - 100px);
    padding: 10px;
    font-size: 0.8rem;
    text-transform: capitalize;
    font-weight: 400;
    border-radius: 5px;
    border-color: #999999;
    color: rgba(0, 0, 0, 0.85);
    background-color: #fff !important;
    line-height: 1.5715;
    appearance: none;
    -webkit-appearance: none;
    margin-bottom: 12px;
}
.slt-2-box:focus {
    outline: 0;
}
.pos-relative {
    position: relative;
}
.arr-slt {
    position: absolute;
    top: 33%;
    right: 13px;

    font-size: 10px;
    fill: #a6a6a6;
}
.rotate-icon > .arr-slt {
    transform: rotate(180deg);
}
svg.icon-_size {
    width: 13px;
}

.child-element p {
    font-size: 10px;
    margin-bottom: 0px;
}
