@import 'Assets/styles/global.scss';
.household-card {
    .Letter {
        position: relative;
        cursor: pointer;
        width: 100%;
        text-align: center;
        .g1 {
            color: #ccc !important;
            border: double 2px transparent !important;
            background-image: linear-gradient(white, white), radial-gradient(circle at top left, #fff, #ccc);
            background-origin: border-box;
            background-clip: content-box, border-box;
        }
        .g2 {
            color: #000 !important;
            border: double 2px transparent !important;
            background-image: linear-gradient(white, white), radial-gradient(circle at top left, #fff, #000);
            background-origin: border-box;
            background-clip: content-box, border-box;
        }
    }

    .delete-icon {
        position: absolute;
        top: -5px;
        left: 37px;
        opacity: 0;
        transition: 0.3s;
        .del {
            fill: $brick-red;
        }
    }
    .empty-data {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .edit-icons {
        position: absolute;
        right: -15px;
        top: 32%;
        transition: 0.3s;
        opacity: 0;
    }

    .Letter:hover span {
        opacity: 1;
    }
    .child {
        &-content {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            margin-left: -10px;
            margin-right: -10px;
            &-wrap {
                padding-top: 10px;
            }
            .cyan {
                span:first-of-type {
                    border-color: $primary-color;
                    color: $primary-color;
                }
            }
            .orange {
                span:first-of-type {
                    border-color: $secondary-color;
                    color: $secondary-color;
                }
            }
            .green {
                span:first-of-type {
                    border-color: $success-color;
                    color: $success-color;
                }
            }
            .violet {
                span:first-of-type {
                    border-color: $danger-color;
                    color: $danger-color;
                }
            }
            .blue {
                span:first-of-type {
                    border-color: $dark-blue;
                    color: $dark-blue;
                }
            }
        }
        &-element {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -ms-flex-preferred-size: 25%;
            flex-basis: 25%;
            margin-bottom: 10px;
            @media (min-width: 1440px) {
                margin-bottom: 46px;
            }

            span {
                cursor: pointer;

                // &:first-of-type {
                //     width: 32px;
                //     height: 32px;
                //     border-radius: 50%;
                //     margin-bottom: 5px;
                //     display: flex;
                //     justify-content: center;
                //     align-items: center;
                //     text-transform: uppercase;
                //     font-size: 16px;
                //     font-weight: 700;
                //     border: 2px solid #369ae2;
                //     color: #369ae2;
                //     @media (min-width: 1920px) {
                //         font-size: 20px;
                //         width: 48px;
                //         height: 48px;
                //     }
                // }
            }
            .ant-avatar {
                width: 50px;
                height: 50px;
            }
        }
    }
}

.ant-modal-close-x svg {
    width: 20px;
    height: 20px;
    color: #333333;
}
.w-100 {
    display: block;
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
}

//form error message fix for add children
.form-err-child {
    position: absolute;
    bottom: -15px;
    font-size: 10px;
    color: $brick-red;
}
.add-child-popup {
    .member-form {
        padding: 0px 12px 0px 12px;
        padding: 20px !important;
        max-height: 350px;
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 10px;
        &::-webkit-scrollbar {
            width: 10px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
            background-color: #ccc;
        }
    }
    .slt-2-box {
        margin-bottom: 0px !important;
    }
    .ant-modal-body {
        padding: 20px !important;
    }
    .pop-loader {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        ._loading_overlay_overlay {
            border-radius: 20px;
        }
    }
    .input-full {
        .ant-picker {
            width: 100%;
            border-radius: 5px;
            height: 40px;
        }
        input {
            font-size: 12px;
            color: $light-grey;
            @media (max-width: 1366px) {
                height: 40px !important;
            }
        }
    }
    .cust-selct {
        .ant-select {
            width: 100% !important;
        }
        .ant-select-arrow {
            top: 46% !important;
        }
    }

    .select-icon {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        .add-icon {
            cursor: pointer;
            img {
                width: 18px;
                height: 18px;
            }
        }
        .box-slt-box {
            width: 90%;
            margin-right: 15px;
            .ant-select-selector {
                border-radius: 5px;
                .ant-select-selection-item {
                    color: $light-grey !important;
                    font-size: 12px;
                }
            }
        }
    }
    .cust-selct {
        input {
            &::placeholder {
                color: $light-grey !important;
                font-size: 12px !important;
            }
        }
    }
    .input-dob {
        .ant-input-affix-wrapper {
            border-radius: 5px;
            input {
                height: 30px !important;
            }
            .ant-input-suffix {
                svg {
                    fill: $primary-blue !important;
                }
            }
        }
        .ant-picker-clear {
            display: none;
        }
    }
}

.member-popup {
    .ant-modal-body {
        padding: 20px 0px 0px 0px !important;
    }
}
.ant-modal.add-child-popup .select-wrap .ant-select-selection-item {
    color: $light-grey !important;
    font-size: 12px !important;
}
.del-pop {
    .ant-btn {
        border-radius: 6px !important;
        height: 28px !important;
        width: 85px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        @media (min-width: 1366px) {
            height: 28px !important;
            width: 85px !important;
        }
    }
    .del-head {
        max-width: 200px;
        margin: 0 auto;
    }
}

.react-calendar__tile {
    padding: 0.75em 0.5em !important;
}

.react-calendar__month-view__weekdays__weekday abbr[title] {
    font-size: 11px !important;
}
.react-calendar__month-view__weekdays__weekday {
    padding: 0 !important;
}
.child-content-wrap {
    .child-content {
        .child-element {
            p {
                font-size: 12px !important;
                text-align: center;
                word-break: break-all;
                padding: 0px 3px;
                margin-top: 5px;
            }
        }
    }
}

.text-color {
    color: $primary-blue;
}


.select-dot-kid {
    .dot {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 5px;
        border-radius: 50%;
    }
    .Pink {
        background-color: #c910e8;
    }
    .Blue {
        background-color: #2196f3;
    }
    .Purple {
        background-color: #673ab7;
    }
    .Red {
        background-color: #ff0000;
    }
    .Green {
        background-color: #4caf50;
    }
    .Orange {
        background-color: #ffc107;
    }
    .Cyan {
        background-color: #00bcd4;
    }
    .Brown {
        background-color: #795548;
    }
    .White {
        background-color: #ffffff;
        border: 1px solid #000000;
    }
    .Grey {
        background-color: #999999;
    }
    .Charcoal {
        background-color: #333333;
    }
    .Black {
        background-color: #000000;
    }
    .Gradient1 {
        background: linear-gradient(-200deg, #ffffff 50%, #999999 50%);
        border: 1px solid #000000;
    }
    .Gradient2 {
        background: linear-gradient(-200deg, #ffffff 50%, #000000 50%);
        border: 1px solid #000000;
    }
}