.reconcile-card {
    .card-body {
        .tab-content-wrap {
            .ant-card {
                .card-top {
                    span {
                        @media (max-width: 1919px) {
                            font-size: 12px !important;
                        }
                        &:nth-of-type(2) {
                            @media (max-width: 1919px) {
                                font-size: 11px !important;
                            }
                        }
                    }
                }
                .card-bottom {
                    span {
                        @media (max-width: 1919px) {
                            font-size: 11px !important;
                        }
                    }
                }
            }
        }
    }
    height: 50%;
}
.recocile-wrap {
    padding: 20px;
    padding-top: 0px !important;
    .recocile-item {
        .item-head {
            border-bottom: 1px dotted #ccc;
        }
        .item-inner {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            span {
                width: 25%;
                margin: 4px 0px;
                &:nth-of-type(1) {
                    text-align: left;
                    width: 34%;
                }
                &:nth-of-type(2) {
                    border-bottom: 1px dotted #ccc;
                    width: 22%;
                }
                &:nth-of-type(3) {
                    text-align: center;
                    border-bottom: 1px dotted #ccc;
                    width: 22%;
                }
                &:nth-of-type(4) {
                    text-align: right;
                    border-bottom: 1px dotted #ccc;
                    width: 22%;
                }
            }
            .double-cell {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                span {
                    &:nth-of-type(1) {
                        width: 40%;
                        height: 100%;
                        margin: 0;
                        border-bottom: none !important;
                    }
                    &:nth-of-type(2) {
                        width: 60%;
                        margin: 0;
                        border-bottom: 1px dotted #ccc;
                    }
                }
            }
            span {
                font-size: 12px;
                font-weight: 300;
            }
        }
        .red {
            color: #f06061;
        }
    }
    .recocile-head,
    .item-head {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        border-bottom: 1px solid #ccc;
        span {
            margin: 4px 0px;
            &:nth-of-type(1) {
                width: 34%;
                text-align: left;
            }
            &:nth-of-type(2) {
                width: 22%;
            }
            &:nth-of-type(3) {
                width: 22%;
                text-align: center;
            }
            &:nth-of-type(4) {
                width: 22%;
                text-align: right;
            }
        }
    }
}

.report-data-modal {
    .modal-body {
        max-height: 250px;
        overflow: auto;
        scrollbar-color: #f5f5f5 !important;
        scrollbar-width: thin !important;
        &::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
            background-color: #ccc;
        }
    }
}

.save-wrap {
    .d-none {
        display: none;
    }
}
