.page-wrap {
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    .detail-wrap {
        text-align: center;
        img {
            width: 100px;
            height: 100px;
        }
        p {
            font-size: 40px;
            font-weight: 900;
            text-transform: uppercase;
            color: #000;
        }
    }
}
