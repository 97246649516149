@import 'Assets/styles/global.scss';
.head {
    text-align: center;
    margin-bottom: 24px;
    position: relative;
    z-index: 1;
    span {
        font-size: 13px;
        color: $black-color;
        font-weight: 700;
        @media (min-width: 1440px) {
            font-size: 14px;
        }
        @media (min-width: 1920px) {
            font-size: 20px;
        }
    }
    &-avatar {
        text-align: center;
        margin-bottom: 30px;
        .ant-avatar {
            width: 80px;
            height: 80px;
        }
        @media (min-width: 1440px) {
            margin-bottom: 74px;
            .ant-avatar {
                width: 85px;
                height: 85px;
            }
        }
        @media (min-width: 1920px) {
            margin-bottom: 96px;
            .ant-avatar {
                width: 100px;
                height: 100px;
            }
        }
    }
    .add-item {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 2;
        transition: all ease-in-out 0.2s;
        border: 0;
        @include secondary-gradient();
        span {
            color: $white-color;
            font-size: 18px;
            font-weight: 700;
            line-height: 1;
            margin-top: 2px;
            margin-right: 1px;
        }
    }
}
.ant-card.household-card {
    height: 100%;
    & > .ant-card-body {
        // padding: 30px 16px !important;
        // @media (min-width: 1440px) {
        //     padding: 30px 20px !important;
        // }
    }
    .ant-select {
        &:hover,
        &:active {
            border-color: #999999;
        }
    }
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: #999999;
    }

    .ant-input {
        min-height: 40px;
        padding: 12px;
        border-radius: 5px;
        color: $text-color;
        border-color: $text-sec-color;
        font-size: 12px;
        @media (min-width: 1440px) {
            font-size: 12px;
        }
        @media (min-width: 1920px) {
            font-size: 14px;
        }
    }
    .float-label {
        margin-bottom: 15px;
        .ant-input,
        .ant-select-selector {
            &:hover,
            &:focus,
            &:active {
                box-shadow: none !important;
                border-color: #999999;
            }
        }
        .label {
            top: 13px;
            left: 12px;
            font-size: 10px;
            @media (min-width: 1440px) {
                font-size: 12px;
            }
            @media (min-width: 1920px) {
                font-size: 14px;
            }
        }
        @media (min-width: 1920px) {
            margin-bottom: 20px;
        }
    }
    .ant-input + .label-float,
    .ant-input + .label-float {
        font-size: 8px;
        transform: translate3d(0, -80%, 0);
        opacity: 1;
        color: $text-sec-color;
        @media (min-width: 1440px) {
            font-size: 10px;
        }
        @media (min-width: 1920px) {
            font-size: 12px;
        }
    }

    .ant-select-selector {
        min-height: 40px;
        padding: 4px 12px;
        border-radius: 5px;
        color: $text-color;
        border-color: $text-sec-color;
        font-size: 10px;
        margin-bottom: 12px;
        @media (min-width: 1440px) {
            font-size: 12px;
        }
        @media (min-width: 1920px) {
            font-size: 14px;
            margin-bottom: 20px;
        }
    }
    .ant-select-arrow {
        span {
            color: $text-sec-color;
        }
        font-size: 10px;
        top: 40%;
        transition: all ease-in 0.2s;
    }
    .ant-select-open {
        .ant-select-arrow {
            transform: rotate(180deg);
        }
    }
    .bottom-btn {
        margin: 20px 0 0;
        text-align: center;
        .btn-save {
            max-width: 180px;
            width: 100%;
            height: auto;
            padding: 8px 24px;
            border: none;
            border-radius: 6px;
            @include primary-gradient();
            span {
                font-size: 12px;
                font-weight: 600;
            }
        }
        @media (min-width: 1440px) {
            margin: 50px 0 0;
            .btn-save {
                padding: 10px 24px;
            }
        }
        @media (min-width: 1920px) {
            margin: 60px 0 0;
            .btn-save {
                padding: 12px 24px;
                span {
                    font-size: 16px;
                }
            }
        }
    }
    .form-err-household {
        color: $danger-color;
        position: absolute;
        left: 0;
        top: 41px;
        z-index: 2;
        font-size: 8px;
    }
    .err-msg {
        .ant-input {
            box-shadow: 0px 3px 6px #fb443729;
            border: 1px solid #fb4437 !important;
        }
    }
}
.upr-labl .label {
    transform: translate3d(0, -80%, 0);
    opacity: 1;
    color: #999999 !important;
    font-size: 8px !important;
}
.ui_btn {
    width: 100%;
    border: 0;
    height: 43px;
    border-radius: 6px;
    font-size: 13px;
}
.set_wid {
    width: 100% !important;
    height: 100% !important;
}
.slt-2-box {
    min-height: 40px;
    font-size: 10px !important;
}

//fixes for the error message in household
.household-content {
    .slct {
        margin-bottom: 15px !important;
        border-width: 1px !important;
        .ant-select-selector {
            margin-bottom: 0px;
            height: 44px;
            font-size: 12px;
        }
        .ant-select-arrow {
            top: 50% !important;
        }
    }
    .form-err {
        position: relative !important;
        top: auto !important;
        bottom: 8px !important;
        color: $brick-red;
        font-size: 10px;
    }
    .arr-slt {
        top: 26% !important;
    }
    .err-msg {
        .ant-select-selector {
            box-shadow: 0px 3px 6px #fb443729;
            border: 1px solid #fb4437 !important;
        }
    }
    .custom-dp {
        width: 100%;
        min-height: 44px;
        border-color: #999999;
        border-radius: 5px;
        color: #333333;
        .ant-picker-input {
            input {
                font-size: 12px;
            }
            .ant-picker-suffix {
                display: none;
            }
        }
        .ant-picker-clear {
            display: none;
        }
    }
    .ant-picker + .label-float,
    .ant-picker + .label-float {
        font-size: 8px;
        transform: translate3d(0, -80%, 0);
        opacity: 1;
        color: $text-sec-color;
        @media (min-width: 1440px) {
            font-size: 10px;
        }
        @media (min-width: 1920px) {
            font-size: 12px;
        }
    }
}
.cp-popup {
    .input-full {
        position: relative;
    }
    .remo_position {
        position: absolute !important;
        top: auto !important;
        font-size: 10px;
        bottom: -17px;
        color: $brick-red;
    }
}
//dob calender popup
.hh-dob-modal {
    .ant-modal-body {
        padding-top: 0px !important;
    }
    .dob-cal-wrap {
        padding: 0px 20px;
        .set-date {
            justify-content: center;
            display: flex;
            position: relative;
            &::after {
                content: '';
                position: absolute;
                z-index: 0;
                left: 9px;
                top: 5px;
                width: 25px;
                height: 25px;
                background: $secondary-color;
                border-radius: 50%;
            }
            > div {
                position: relative;
                z-index: 1;
                color: $white-color;
            }
        }
    }
    .btn-wrap {
        padding: 0px 20px;
        .ant-btn {
            width: 100%;
            border-radius: 6px;
            border: 0 !important;
            margin-bottom: 0px !important;
            font-size: 12px;
        }
    }
}
.ant-card.coparent-card {
    .ant-card-body {
        padding: 0 !important;
    }
}
.household-card {
    .card-scroll-wrap {
        padding: 15px;
        overflow: auto;
        scrollbar-color: #f5f5f5 !important;
        scrollbar-width: thin !important;
        &::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
            background-color: #ccc;
        }
    }
    .cust-head {
        margin-top: 30px;
        .add-item {
            right: 25px !important;
        }
    }
}
.ant-picker-panel {
    .ant-picker-footer {
        display: none;
    }
}
