.trans-card {
    height: calc(50% - 10px);
    margin-top: 10px;
    .ant-card-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    @media (max-width: 768px) {
        margin-bottom: 20px;
    }
    .d-block {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}
.ant-card.card-rec-trans{
    .ant-card-body{
        padding: 0px !important;
    }
    .req-head{
        padding: 15px !important; 
    }
    .trans-card-wrap{
        .scrollarea-content{
            padding: 15px !important;
            .list-card{
                .ant-card-body{
                    padding: 12px !important;
                }
            }
        }
       
    }
}
.card-rec-trans{
    height: 100%;
    margin-top: 0px;
}


.checklist-card {
    max-width : 731px;
    margin: 0 auto;
    .card-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 5px;
        padding: 10px !important;
        height: 100%;
        width: 100%;
        span {
            font-weight : 500 !important;
        }
        .checklist-btn-wrap {
            span {
                color : #fff !important;

            }
        }

    }
}
.checklist-div {
    display:flex;
    align-items : center;
   
        img {
            width:15px;
            height:15px;
            margin-right: 15px;
        }
     .ant-btn {
                display: flex;
                align-items: center;
                border: 0;
                height: 30px;
                border-radius: 5px;
                margin-right: 10px;
            }
}
.checklist-wrap {
    padding: 0 !important;
    li {
        list-style-type : none;
        border-bottom: 1px solid #ccc;
        padding: 10px 20px;
        .checklist-list-item {
          display: flex;
          justify-content: space-between;
          .checklist-list-item-inner {
            display: flex;
            //align-items: center;
          }
        }
        a {
            color : #000;
            padding : 10px 0px;
        }
        span:nth-of-type(1) {
            margin-right: 10px;
            
        }
        span:nth-of-type(2) {
            font-size: 12px;
            color : #58595B;
            line-height:1.5;
        }
        &:last-of-type {
            border-bottom: none;
        }
    }
    .checklist-active {
        background-color: #479ED7;
        span:nth-of-type(2) {
            color: #FFFFFF;
        }
    }

}
.cal-modal.checklist-modal {
    width: 400px !important;
    .checklist-loader-d-none{
        display:none;
    }
}
.checklist-bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        margin-top: 14px;
}
.svg-fill-color {
    fill: #ffa641;
    color: #ffa641;
}
.svg-fill-color-grey {
    fill: #808080;
    color:#808080;
}
.svg-color-color-white {
    fill:#ffffff;
    color:#ffffff;
}
    
