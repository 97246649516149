@import 'Assets/styles/global.scss';

.error-popup {
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-modal-header {
        border-radius: 20px;
        text-align: center;
    }
    .ant-modal-title {
        padding-top: 0;
    }
    .btn {
        display: inline-block;
        width: 100%;
        background-color: $secondary-color;
        color: #fff;
        outline: none;
        border-color: $secondary-color;
        font-size: 12px;
    }
    .err-msg {
        margin: 20px 0px;
    }
    .ant-modal-close {
        top: 0 !important;
        svg {
            width: 16px;
            height: 16px;
        }
    }
}
