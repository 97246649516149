$primary-color: #38a0eb;
$secondary-color: #fca232;
$success-color: #22b422;
$danger-color: #fb4437;
$disabled-color: #c7c7cc;
$dark-blue: #4632fc;
$violet-color: #513bc5;
$rose-color: #dc2cd1;
$text-color: #333333;
$text-sec-color: #999999;
$text-light-color: #dc2cd1;
$black-color: #000000;
$white-color: #ffffff;
$light-bg: #f9f8f7;
$light-grey: #58595b;
$light-border: #58595b80;
$disabled-color: #bcbcbd;
$shadow-color: #0000000a;
$text-border: #b9b9b9;
$grey-border: #e9e9e9;
$brick-red: #e63e35;
$brown-color: #785549;

//updated theme colors

$primary-blue: #479ed7;
$dark-orange: #f99c30;
$dim-brown: #70707033;

/***** mixins ******/
@mixin primary-gradient {
    background: $dark-orange;
}
@mixin secondary-gradient {
    background: $primary-blue;
}
@mixin orange-gradient {
    background: rgb(255, 151, 32);
    background: -moz-linear-gradient(286deg, rgba(255, 151, 32, 1) 0%, rgba(249, 206, 85, 1) 100%);
    background: -webkit-linear-gradient(286deg, rgba(255, 151, 32, 1) 0%, rgba(249, 206, 85, 1) 100%);
    background: linear-gradient(286deg, rgba(255, 151, 32, 1) 0%, rgba(249, 206, 85, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff9720",endColorstr="#f9ce55",GradientType=1);
}
