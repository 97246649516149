.time-ratio-card {
    height: calc(50% - 10px);
    margin-top: 10px;
    .card-body {
        .tab-content-wrap {
            .ant-card {
                .card-top {
                    span {
                        @media (max-width: 1919px) {
                            font-size: 12px !important;
                        }
                        &:nth-of-type(2) {
                            @media (max-width: 1919px) {
                                font-size: 11px !important;
                            }
                        }
                    }
                }
                .card-bottom {
                    span {
                        @media (max-width: 1919px) {
                            font-size: 11px !important;
                        }
                    }
                }
            }
        }
    }
}
.report-data-modal {
    .modal-body {
        max-height: 300px;
        overflow: auto;
        scrollbar-color: #f5f5f5 !important;
        scrollbar-width: thin !important;
        &::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
            background-color: #ccc;
        }
        .ratio-wrap {
            padding: 0px 20px 20px 20px;
            .ratio-head {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                border-bottom: 1px solid #58595b1a;
                span {
                    color: #58595b;
                    font-size: 12px;
                    font-weight: 300;
                    margin-bottom: 10px;
                    &:nth-of-type(1) {
                        width: 50%;
                    }
                    &:nth-of-type(2) {
                        width: 22%;
                    }
                    &:nth-of-type(3) {
                        width: 22%;
                    }
                    &:nth-of-type(4) {
                        width: 6%;
                    }
                }
            }
            .parent-wrap {
                margin: 10px 0px;
                .parent-row {
                    padding: 9px 0px;
                    background: #58595b1a;
                    border-radius: 4px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    span {
                        color: #58595b;
                        font-size: 12px;
                        font-weight: 500;
                        &:nth-of-type(1) {
                            padding-left: 9px;
                            width: 50%;
                        }
                        &:nth-of-type(2) {
                            width: 22%;
                        }
                        &:nth-of-type(3) {
                            width: 22%;
                        }
                        &:nth-of-type(4) {
                            width: 6%;
                        }
                    }
                }
            }
            .row-item {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                span {
                    color: #58595b;
                    font-size: 12px;
                    font-weight: 300;
                    // padding: 7px 0px;
                    &:nth-of-type(1) {
                        width: 50%;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;

                        span {
                            width: 50%;
                            border-bottom: 1px dotted #fff;
                            &:nth-of-type(2) {
                                border-bottom: 1px dotted #58595b33;
                                padding: 5px 0px;
                            }
                        }
                    }
                    &:nth-of-type(2) {
                        width: 22%;
                        border-bottom: 1px dotted #58595b33;
                        padding: 5px 0px;
                    }
                    &:nth-of-type(3) {
                        width: 22%;
                        border-bottom: 1px dotted #58595b33;
                        padding: 5px 0px;
                    }
                    &:nth-of-type(4) {
                        width: 6%;
                        border-bottom: 1px dotted #58595b33;
                        padding: 5px 0px;
                    }
                }
            }
            .violet {
                color: #dc2cd1 !important;
            }
            .green {
                color: #22b422 !important;
            }
        }
        .stat-wrap {
            margin: 0px 20px;
            padding-bottom: 20px;
            .stat-card {
                border-radius: 6px;
                box-shadow: 0px 6px 15px #58595b1a;
                padding: 20px 0px;
                .title {
                    font-size: 14px;
                    color: #58595b;
                    text-align: center;
                    margin: 0px 40px;
                    border-bottom: 1px solid #58595b1a;
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                }
                .stat-card-body {
                    margin: 0px 20px;
                    .child-name {
                        font-size: 12px;
                        text-align: center;
                        color: #58595b;
                        font-weight: 300;
                        margin-bottom: 8px;
                        &:not(:first-of-type) {
                            margin-top: 20px;
                        }
                    }
                }
            }
            .progress-wrap {
                position: relative;
                .ant-progress {
                    .ant-progress-outer {
                        margin: 0;
                        padding: 0;
                        .ant-progress-inner {
                            background-color: #58595b1a;
                            border-radius: 2px;
                            .ant-progress-bg {
                                height: 28px !important;
                                border-radius: 2px 0px 0px 2px;
                            }
                        }
                    }
                }
                .ant-progress-text {
                    position: absolute;
                    right: 75px;
                    top: 6px;
                    font-size: 12px;
                    font-weight: 300;
                }
                .ant-progress-text-active {
                    position: absolute;
                    left: 75px;
                    top: 6px;
                    font-size: 12px;
                    color: #fff;
                    font-weight: 300;
                }
                .ant-progress-text-inactive {
                    position: absolute;
                    left: 258px;
                    top: 6px;
                    font-size: 12px;
                    color: #333333;
                    font-weight: 300;
                }
            }
            .orange-bar {
                .ant-progress-bg {
                    background: #ff9720;
                }
            }
            .blue-bar {
                .ant-progress-bg {
                    background: #479ed7;
                }
            }
        }
    }
    .ant-modal-body {
        padding-bottom: 10px;
    }
}

.save-wrap {
    .d-none {
        display: none;
    }
}
