@import 'Assets/styles/global.scss';
.signin {
    &-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        min-height: 100vh;
        background: $white-color;
        position: relative;
        > div {
            width: 50%;
        }
        @media (max-width: 768px) {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            > div {
                background: url('../../Assets/cover.png') center no-repeat;
                background-repeat: no-repeat;
                background-size: cover;
                width: 100%;
                height: 100%;
            }
        }
        .sign-err-top {
            position: absolute;
            z-index: 9;
            left: 50%;
            top: -43px;
            font-size: 12px;
            border-radius: 5px;
            padding: 5px 10px;
            color: $white-color;
            background: $danger-color;
            box-shadow: 0px 10px 20px #00000029;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            width: 410px;
        }
        .back-arrow{
            position: absolute;
            top: 40px;
            left: 20px;
            cursor: pointer;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 50%;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            span{
                border-radius: 50%;
                img{
                    transform: rotate(180deg);
                    width: 15px;
                    height: 15px;
                }

            }
            &:hover{
                -webkit-transition: .2s ease-in-out;
                -o-transition: .2s ease-in-out;
                transition: .2s ease-in-out;
                -webkit-transform: scale(1.1);
                -ms-transform: scale(1.1);
                transform: scale(1.1);
                background: transparent;
                border: 1px solid #fff;
                span{
                    -webkit-transform: translateX(-3px);
                    -ms-transform: translateX(-3px);
                    transform: translateX(-3px);
                }
            }
            @media(max-width: 768px){
                top: 30px;
                width: 25px;
                height: 25px;
                background: $primary-blue;
                img{
                    width: 12px !important;
                    height: 12px !important;
                }
            }
        }
        @media(max-width: 768px){
            height: 100vh;
        }
    }
    &-cover {
        background: url('../../Assets/cover.png') center no-repeat;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        @media (max-width: 768px) {
            display: none;
        }

    }
    &-details {
        .details-inner {
            max-width: 410px;
            margin: 0 auto;
            padding-top: 50px;
            padding-bottom: 50px;
            @media (max-width: 768px) {
                max-width: 100%;
                padding: 20px;
                height: 100%;
                background: rgba(255, 255, 255, 0.85);
            }
        }
        .details-head {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            .logo-wrap {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                span {
                    font-size: 30px;
                    font-weight: 700;
                    color: $light-grey;
                }
                @media (max-width: 1440px) {
                    span {
                        font-size: 26px;
                    }
                    img {
                        width: 35px;
                    }
                }
            }
            .btn-wrap {
                .btn-sign-in {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    height: 40px;
                    width: 90px;
                    background: $white-color;
                    border: 1px solid $dim-brown;
                    padding: 10px;
                    border-radius: 6px;
                    span {
                        font-size: 16px;
                        font-weight: 700;
                        color: $light-grey;
                    }
                    .arrow-wrap {
                        margin-left: 5px;
                        img {
                            width: 10px;
                            height: 10px;
                        }
                    }
                    @media (max-width: 1440px) {
                        span {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .data-wrap {
            position: relative;
            padding: 30px;
            margin-top: 45px;
            border-radius: 6px;
            border: 2px solid $dim-brown;
            .sign-head {
                position: absolute;
                left: 35px;
                top: -15px;
                background: $white-color;
                padding: 0px 3px;
                span {
                    color: $primary-blue;
                    font-size: 22px;
                    font-weight: 700;
                }
                @media (max-width: 1440px) {
                    span {
                        font-size: 20px;
                    }
                }
                @media (max-width: 768px) {
                    position: relative !important;
                    left: 0;
                    top: 0;
                    text-align: center;
                }
            }

            .signin-form-wrap {
                padding-top: 10px;
                @media (max-width: 768px) {
                    padding-top: 0px;
                }
                .ant-form-item {
                    margin-bottom: 10px !important;
                    input {
                        height: 48px;
                        padding: 15px 20px;
                        border-radius: 6px;
                        font-size: 16px;
                        border-color: #58595b4d;
                        color: $light-grey;
                        &::placeholder {
                            font-weight: 300;
                            color: $light-grey;
                            opacity: 0.5;
                        }
                        @media (max-width: 1440px) {
                            height: 40px;
                            padding: 10px 15px;
                            font-size: 14px;
                        }
                    }
                    .ant-input-affix-wrapper {
                        height: 48px;
                        padding: 0px 2px;
                        border-radius: 6px;
                        font-size: 16px;
                        border-color: #58595b4d;
                        @media (max-width: 1440px) {
                            height: 40px;
                            font-size: 14px;
                        }
                        input {
                            height: 100%;
                            color: $light-grey;
                            font-size: 16px;
                        }
                        .ant-input-suffix {
                            display: none;
                        }
                    }
                    .ant-form-item-label {
                        padding-bottom: 0px !important;
                        label {
                            font-size: 18px;
                            color: $light-grey;
                            @media (max-width: 1440px) {
                                font-size: 14px;
                            }
                        }
                    }
                }
                .fgt-pwd {
                    text-align: right;
                    a {
                        font-size: 13px;
                        font-weight: 500;
                        color: $light-grey;
                        opacity: 0.8;
                        text-decoration: underline;
                        &:hover {
                            color: $primary-blue;
                        }
                        @media (max-width: 1440px) {
                            font-size: 11px;
                        }
                    }
                }
                .checkbox-wrap {
                    margin: 20px 0px;
                    .ant-checkbox-wrapper {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        span {
                            font-size: 14px;
                            color: $light-grey;
                            opacity: 0.7;
                            @media (max-width: 1440px) {
                                font-size: 12px;
                            }
                        }
                        .ant-checkbox-inner {
                            opacity: 1;
                            border-color: $light-grey;
                            margin-top: 5px;
                            margin-right: 10px;
                        }
                    }
                    .password_text {
                        height: 25px;
                        vertical-align: middle;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 12px;
                        color: $light-grey;
                        margin: 0;
                    }
                    .ant-checkbox-checked::after {
                        border: 0 !important;
                    }
                }
                .btn-wrap {
                    // margin-bottom: 30px;
                    .btn-full {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        font-size: 18px;
                        width: 100%;
                        height: 46px;
                        background: $primary-blue;
                        border-radius: 6px;
                        box-shadow: 0px 3px 6px #00000038;
                        span {
                            &:first-of-type {
                                margin-right: 20px;
                            }
                        }
                        @media (max-width: 1440px) {
                            font-size: 14px;
                            height: 42px;
                        }
                        &:hover{
                            span{
                                &:nth-of-type(2){
                                    -webkit-transition: .2s ease-in-out;
                                    -o-transition: .2s ease-in-out;
                                    transition: .2s ease-in-out;
                                    -webkit-transform: translateX(5px);
                                    -ms-transform: translateX(5px);
                                    transform: translateX(5px);
                                }
                            }
                        }

                    }
                    .btn-google{
                        background: $white-color;
                        border: 0 !important;
                        margin-top: 15px;
                        span{
                            color: $light-grey;
                            img{
                                width: 20px;
                                height: 20px;
                            }
                        }
                        // &:hover{
                        //     span{
                        //         &:nth-of-type(2){
                        //             -webkit-transition: .2s ease-in-out;
                        //             -o-transition: .2s ease-in-out;
                        //             transition: .2s ease-in-out;
                        //             -webkit-transform: translateX(5px);
                        //             -ms-transform: translateX(5px);
                        //             transform: translateX(5px);
                        //         }
                        //     }
                        // }
                        .g-image{
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
                .form-bottom {
                    > div {
                        font-size: 11px;
                        color: $light-grey;
                        opacity: 0.7;
                        font-weight: 300;
                        margin-bottom: 20px;
                        &:last-child {
                            margin-bottom: 10px !important;
                        }
                        a {
                            color: #313233;
                            font-weight: 700;
                        }
                        @media (max-width: 1440px) {
                            font-size: 10px;
                            margin-bottom: 10px;
                        }
                    }
                }
                .sign-err {
                    position: absolute;
                    font-size: 10px;
                    color: $brick-red;
                }
            }
            @media (max-width: 768px) {
                padding: 15px;
                background: $white-color;
            }
        }
    }
}


// height: 25px;
// vertical-align: middle;
// display: inline-flex;
// justify-content: center;
// align-items: center;
// font-size: 12px;
// color: #555;
// margin: 0;