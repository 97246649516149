@import 'Assets/styles/global.scss';
.events {
    height: 100%;
    position: relative;
    .loader-wrap {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &-create {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 600;
        border: 0 !important;
        border-radius: 3px;
        position: absolute;
        right: 20px;
        top: -50px;
        padding: 7px 19px 7px 19px;
        height: 30px;
        box-shadow: 0px 3px 6px #00000038;
        @media (max-width: 1366px) {
            font-size: 10px;
            top: -43px;
            height: 25px;
        }
        @media(max-width: 1150px) and (min-width: 991px){
            padding: 10px;
        }
    }
    &-card-wrap {
        padding: 15px;
        overflow: auto;
        scrollbar-color: #f5f5f5 !important;
        scrollbar-width: thin !important;
        &::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
            background-color: #ccc;
        }
    }
    .ant-card-body {
        position: relative;
        padding: 0px !important;
        display: flex;
        flex-direction: column;
        max-height: calc(100% - 75px);
        height: 100%;
        &:before,
        &:after {
            display: none;
        }
    }
    .list {
        &-card {
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            .ant-card-body {
                padding: 10px !important;
            }
            .address {
                .data {
                    width: 55px;
                    display: inline-block;
                    margin-bottom: 0;
                    .dot {
                        width: 5px;
                        height: 5px;
                        background: transparent;
                        display: inline-block;
                        border-radius: 50%;
                        margin-bottom: 0;
                    }
                    .blue {
                        background: $primary-blue;
                    }
                    .orange {
                        background: $dark-orange;
                    }
                    .green {
                        background: $success-color;
                    }
                    .Pink {
                        background-color: #c910e8;
                    }
                    .Blue {
                        background-color: #2196f3;
                    }
                    .Purple {
                        background-color: #673ab7;
                    }
                    .Red {
                        background-color: #ff0000;
                    }
                    .Green {
                        background-color: #4caf50;
                    }
                    .Orange {
                        background-color: #ffc107;
                    }
                    .Cyan {
                        background-color: #00bcd4;
                    }
                    .Brown {
                        background-color: #795548;
                    }
                    .White {
                        background-color: #ffffff;
                        border: 1px solid #000000;
                    }
                    .Grey {
                        background-color: #999999;
                    }
                    .Charcoal {
                        background-color: #333333;
                    }
                    .Black {
                        background-color: #000000;
                    }
                    .Gradient1 {
                        background: linear-gradient(-200deg, #ffffff 50%, #999999 50%);
                        border: 1px solid #000000;
                    }
                    .Gradient2 {
                        background: linear-gradient(-200deg, #ffffff 50%, #000000 50%);
                        border: 1px solid #000000;
                    }
                }
            }
        }
        &-inner {
            cursor: default;
            .icon {
                -webkit-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                transform: rotate(90deg);
                cursor: pointer;

                span {
                    width: 15px;
                    height: 15px;
                    display: inline-block;
                    img {
                        width: 100%;
                        height: 100%;
                        opacity: 0.6;
                    }
                }
            }
            .detail-wrapper {
                -ms-flex-preferred-size: 80%;
                flex-basis: 80%;
            }
        }
        &-time {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            width: 100%;
            border-left: 1px solid #eaeaea;
            span {
                margin-bottom: 4px;
                font-size: 12px;
                color: $light-grey;
            }
            .blue {
                color: $primary-blue;
            }
            .orange {
                color: $dark-orange;
            }
            .green {
                color: $success-color;
            }
            .Pink {
                color: #c910e8;
                display: inline-block;
                width: 120px;
                word-break: break-all;
            }
            .Blue {
                color: #2196f3;
                display: inline-block;
                width: 120px;
                word-break: break-all;
            }
            .Purple {
                color: #673ab7;
                display: inline-block;
                width: 120px;
                word-break: break-all;
            }
            .Red {
                color: #ff0000;
                display: inline-block;
                width: 120px;
                word-break: break-all;
            }
            .Green {
                color: #4caf50;
                display: inline-block;
                width: 120px;
                word-break: break-all;
            }
            .Orange {
                color: #ffc107;
                display: inline-block;
                width: 120px;
                word-break: break-all;
            }
            .Cyan {
                color: #00bcd4;
                display: inline-block;
                width: 120px;
                word-break: break-all;
            }
            .Brown {
                color: #795548;
                display: inline-block;
                width: 120px;
                word-break: break-all;
            }
            .White {
                color: #000000;
                display: inline-block;
                width: 120px;
                word-break: break-all;
            }
            .Grey {
                color: #999999;
                display: inline-block;
                width: 120px;
                word-break: break-all;
            }
            .Charcoal {
                color: #333333;
                display: inline-block;
                width: 120px;
                word-break: break-all;
            }
            .Black {
                color: #000000;
                display: inline-block;
                width: 120px;
                word-break: break-all;
            }
            .Gradient1 {
                color: #999999;
                display: inline-block;
                width: 120px;
                word-break: break-all;
            }
            .Gradient2 {
                color: #000000;
                display: inline-block;
                width: 120px;
                word-break: break-all;
            }
            .time-inner,
            .address {
                span {
                    cursor: pointer;
                }
            }
        }
        &-date {
            -ms-flex-preferred-size: 20%;
            flex-basis: 20%;
            border-right: 0;
            span {
                font-size: 10px;
                color: $light-grey;
                &:first-of-type {
                    font-size: 12px;
                }
            }
        }
    }
}
.cal-modal {
    position: relative;
    .loader-wrap {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .child-name {
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12);
        .inputName {
            color: $primary-blue;
            border: none;
            border-radius: 5px;
        }
    }
    .butn {
        display: inline-block;
        width: 100%;
        border: 1px solid $primary-color;
        border: none;
    }
    .scroll-wrap {
        .freq-select {
            .ant-form-item-control-input-content {
                display: flex;
                .ant-select {
                    margin-right: 10px;
                }
            }
        }
        .marked-list {
            display: flex;
            padding-left: 0;
            li {
                list-style-type: none;
                margin-right: 4px;
                &:last-of-type {
                    margin-right: 0px;
                }
                .ant-btn {
                    font-size: 12px;
                    padding: 4px 8px;
                    border-radius: 5px;
                    &:active,&:focus,&:hover {
                        color : rgba(0, 0, 0, 0.85);
                    }
                }

            }
            .click-color {
                    background-color : #80c0f8;
                }
        }
        .marked-list-label {
            display: block;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 10px;
        }
    }
    .event-height {
        max-height: 380px !important;
    }
    .p-0 {
        padding: 0;
    }
}
.icon-input {
    .ant-form-item-control-input-content {
        border-bottom: 1px solid $grey-border !important;
    }
    .ant-input-affix-wrapper {
        border: 0;
        padding-left: 0 !important;
    }
    input {
        border-bottom: 0 !important;
        &::placeholder {
            font-size: 12px;
            color: $light-grey;
            opacity: 0.5;
        }
    }
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
        box-shadow: none;
    }
    .ant-input-suffix {
        color: $primary-color !important;
        cursor: pointer;
    }
}
.time-picker {
    display: flex;
    justify-content: center;
    .ant-picker {
        width: 100%;
    }
}
.ant-picker-dropdown {
    font-size: 12px;
    .ant-picker-content {
        ul {
            &:last-of-type {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
            }

            &:after {
                display: none;
            }
            scrollbar-color: #f5f5f5 !important;
            scrollbar-width: thin !important;
            &::-webkit-scrollbar {
                width: 2px;
                background-color: #f5f5f5;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
                background-color: #ccc;
            }
        }
    }
}
.time-modal {
    .input-col {
        padding: 20px;
    }
    .btn-top {
        padding: 20px;
    }
}
.ant-select-dropdown {
    .dot {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 5px;
        border-radius: 50%;
    }
    .Pink {
        background-color: #c910e8;
    }
    .Blue {
        background-color: #2196f3;
    }
    .Purple {
        background-color: #673ab7;
    }
    .Red {
        background-color: #ff0000;
    }
    .Green {
        background-color: #4caf50;
    }
    .Orange {
        background-color: #ffc107;
    }
    .Cyan {
        background-color: #00bcd4;
    }
    .Brown {
        background-color: #795548;
    }
    .White {
        background-color: #ffffff;
        border: 1px solid #000000;
    }
    .Grey {
        background-color: #999999;
    }
    .Charcoal {
        background-color: #333333;
    }
    .Black {
        background-color: #000000;
    }
    .Gradient1 {
        background: linear-gradient(-200deg, #ffffff 50%, #999999 50%);
        border: 1px solid #000000;
    }
    .Gradient2 {
        background: linear-gradient(-200deg, #ffffff 50%, #000000 50%);
        border: 1px solid #000000;
    }
}
.pop-over-event-custom {
    .pop-content-wrap {
        display : flex;
        flex-direction : column;
        padding : 10px;
        cursor: pointer;
    }
    .ant-popover-inner {
        width: 231px;
    }
}

.load-more-btn-wrap {
    text-align: center;
    margin-top: 15px;
    .load-more-btn {
     border : 0;
     border-radius : 5px;
    }
}

.event-st-msg {
    width: 410px !important;
    .ant-modal-content {
        width: 100%;
    }
}
