@import 'Assets/styles/global.scss';


.edit-container {
    background-color:#F9F8F7;
    display: flex;
    flex: auto;
    flex-direction: column;
    min-height: 0;
    background: #F9F8F7;

    .subscription__header {
        width: 100%;
        height: 70px;
        background: transparent linear-gradient(90deg, #ECD66B 0%, #FF9A29 100%) 0% 0% no-repeat padding-box;
        opacity: 1;

        .logo {
            margin: 10px auto;
            position: relative;
            .logo-wrap {
                display: flex;
                flex-direction: column;
                align-items: center;
                img {
                    width: 46px;
                    height: 35px;
                    margin-bottom: 15px;
                }
                .logo-item {
                    font-size: 30px;
                    font-weight: 700;
                    color: $white-color;
                    margin-top: -10px;
                }
            }
        }
    }
    .container {
        max-width: 820px;
        padding: 20px;
        margin: 20px auto;
        display: flex;
        flex-direction: column;

        .heading {
            font-size: 16px;
            margin-bottom: 20px;
        }

        .upgrade__box {
            height: 96px;
            width: 100%;
            background-color: #fff;
            box-shadow: 0px 12px 45px #0000000F;
            border-radius: 10px;
            opacity: 1;
            margin-bottom: 15px;

           .upgrade__wrapper {
                padding-top: 20px;
                padding-left: 30px;
                padding-right: 30px;
                padding-bottom: 17.5px;
                display: flex;
                justify-content: space-between;

                .subscription__plan {
                    display: flex;
                    flex-direction: column;

                    .plan__text {
                        font-size: 14px;
                        font-weight: 300;
                        color: #707070;
                        opacity: 1;
                    }
                    .plan__price {
                        font-size: 20px;
                        font-weight: bold;
                    }
                }
                
                .subscription__button_box {
                    display: flex;
                    margin-top: 10px;
                    height:100%;

                    .upgrade__button {
                        background: #479ED7 0% 0% no-repeat padding-box;
                        box-shadow: 0px 3px 3px #00000033;
                        opacity: 1;
                        color: #fff;
                        font-size: 16px;
                        font-weight: 700;
                        border: 0;
                        width: 126px;
                        height: 36px;
                        border-radius: 6px;
                        margin-right: 25px;
                    }

                    .menu__box {
                        width: 20px;

                        .popover__model {
                            display: block;
                            width: 125px;
                            height: 73px;
                        }
                    }
                    .upgrade__option_menu {
                        width: 15px;
                        height: 100%;
                        vertical-align: middle;
                        font-size: 50px;
                        color: #f5f4f3;
                        cursor: pointer;
                        filter: invert(73%) sepia(0%) saturate(0%) hue-rotate(61deg) brightness(97%) contrast(96%);
                    }
                }
            }
        }

        .payment__method__box {
            height: 314px;
            width: 100%;
            background-color: #fff;
            box-shadow: 0px 12px 45px #0000000F;
            border-radius: 10px;
            opacity: 1;
            margin-bottom: 15px;
            position: relative;

            .payment-loader-wrap {
                position: absolute;
                height: 100%;
                width: 100%;
                border-radius: inherit;
            }

            .create__payment__wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-left: 30px;
                padding-right: 30px;
                height: 80px;
                & h3{
                    font-size: 16px;
                    margin:0;
                    padding:0;
                    font-weight: 700;
                }

                .create__payment__button {
                        display: inline-block;
                        height: 24px;
                        width: 24px;
                        color: $white-color;
                        text-align: center;
                        background-color: $primary-blue;
                        border-radius: 50%;
                        padding: 2px;
                        font-size: 15px;
                        position: relative;
                        .create__icon {
                          font-size: 16px;
                          position: absolute;
                          top: -9%;
                          right: 30%;
                          left: 30%;
                        }
                }
            }
            .payment__method__line {
                height: 1px;
                width: 100%;
                background-color: #f5f4f3;
            }

            .card__wrapper {
                padding: 30px;
                padding-top: 30px;
                max-height: 200px;
                overflow: auto;
                scrollbar-color: #f5f5f5 !important;
                scrollbar-width: thin !important;
                &::-webkit-scrollbar {
                    width: 5px;
                    background-color: #f5f5f5;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 5px;
                    -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
                    background-color: #ccc;
                }

                .card__item {
                    height: 40px;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;

                    .card__number__box {
                        height: 100%;
                        display: flex;

                        .card__image {
                            height: 100%;
                            width: 60px;
                            border-radius: 5px;
                            border: 2px solid #f5f4f3;
                            margin-right: 15px;
                        }

                        .card__number__item {
                            display: flex;
                            height: 100%;
                            flex-direction: column;
                            justify-content: center;
        
                            .card__number {
                                color: #000;
                                font-size: 16px;
                                font-weight: 500;
                            }
                            .primary__text {
                                font-size: 13px;
                                color: #58595B;
                            }
                        }
                    }
                    .menu__box {
                        width: 20px;
                        height: 40px;

                        .popover__model {
                            display: block;
                            width: 175px;
                            height: 73px;
                        }
                    }
                    .upgrade__option_menu {
                        width: 15px;
                        vertical-align: top;
                        font-size: 50px;
                        color: #f5f4f3;
                        cursor: pointer;
                        filter: invert(73%) sepia(0%) saturate(0%) hue-rotate(61deg) brightness(97%) contrast(96%);
                    }
                }

            }
        }

        .payment__history__box {
            height: 383px;
            width: 100%;
            background-color: #fff;
            box-shadow: 0px 12px 45px #0000000F;
            border-radius: 10px;
            opacity: 1;
            position: relative;

            .history-loader-wrap {
                position: absolute;
                height: 100%;
                width: 100%;
            }

            .payment__history__wrapper {
                height: 80px;
                display: flex;
                align-items: center;
                padding: 0 30px;

                & h3 {
                    margin: 0;
                    padding: 0;
                    font-size: 16px;
                    font-weight: 700;
                }
            }
            .payment__history__line {
                height: 1px;
                width: 100%;
                border: 1px solid #f5f4f3;
            }
            .transactions__wrapper {
                padding: 30px;
                padding-top: 20px;
                max-height: 250px;
                overflow: auto;
                scrollbar-color: #f5f5f5 !important;
                scrollbar-width: thin !important;
                &::-webkit-scrollbar {
                    width: 5px;
                    background-color: #f5f5f5;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 5px;
                    -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
                    background-color: #ccc;
                }

                .history__item {
                    height: 44px;
                    opacity: 1;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 24px;

                    .history__date {
                        display: flex;
                        flex-direction: column;
                        text-align: left;

                        .history__month{
                            font-weight: 500;
                            color: #000000;
                            font-size: 16px;
                            margin-bottom: 5px;
                        }

                        .history__card__number {
                            font-size: 13px;
                            color: #58595B;
                            opacity: 1;
                            font-weight: 300;
                        }
                    }

                    .history__amount {
                        display: flex;
                        flex-direction: column;
                        text-align: right;

                        .history__price {
                            margin-bottom: 5px;
                            font-weight: 500;
                            color: #000000;
                            font-size: 16px;
                        }

                        .history__price__date {
                            font-size: 13px;
                            color: #58595B;
                            opacity: 1;
                            font-weight: 300;
                        }
                    }
                }
            }
        }

    }
}

.popover__model {
    display: block;
    width: 125px;
    height: 73px;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    .primary__item {
        flex:1;
        padding-left: 12px;
        padding-top: 12px;
        font-size: 12px;
        color: #333333;
        border-bottom: 1px solid #f5f4f3;
        cursor: pointer;
    }
    .delete__item {
        flex:1;
        padding-left: 12px;
        padding-top: 8px;
        font-size: 12px;
        color: #333333;
        cursor: pointer;
    }
}

.upgrade__popover {
    .ant-popover-inner {
        width: 180px;
    }
}

.upgrade__popover__model {
    display: block;
    width: 125px;
    height: 45px;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    .primary__item {
        flex:1;
        width:170px;
        padding-left: 12px;
        padding-top: 12px;
        font-size: 12px;
        color: #333333;
        border-bottom: 1px solid #f5f4f3;
        cursor: pointer;
    }
    .delete__item {
        flex:1;
        padding-left: 12px;
        padding-top: 8px;
        font-size: 12px;
        color: #333333;
        cursor: pointer;
    }
}

.card-popup {
    width: 346px !important;
    .ant-modal-content{
        width: 346px;
        height: 304px;
        border-radius: 20px;
        opacity: 1;
        position: relative;
        .delete-loader-wrap {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
            overflow: hidden;
        }
    }
    .ant-modal-close{
        top:13px;
    }
    .ant-modal-header {
        padding-top: 30px;
        padding-bottom: 10px;
        border-radius: 20px;
        text-align: center;
        border-bottom: 0;
    }
    .ant-modal-body{
        padding-top: -6px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .card__text {
            width: 258px;
            height: 57px;
            font-size: 13px;
            font-weight: 300;
            color: #000000;
            text-align: center;
            margin: 0 auto;
            margin-bottom: 30px;
        }

        .ant-btn {
            width: 306px;
            height: 44px;
            font-size: 18px;
            color: #FFFFFF;
            margin-bottom: 15px;
            outline: none;
            box-shadow: 0px 3px 3px #00000033;
            border-radius: 6px;
            opacity: 1;
            border: 0;

            .ant-button-primary{
                background: #FF9720;
            }
            

        }
    }
}

.choose-plan-modal {
    width: 346px !important;
    .ant-modal-content{
        width: 346px;
        height: 400px;
        border-radius: 20px;
        opacity: 1;
    }
    .ant-modal-close{
        top:13px;
    }
    .ant-modal-header {
        padding-top: 30px;
        padding-bottom: 10px;
        border-radius: 20px;
        text-align: center;
        border-bottom: 0;
    }
    .ant-modal-body{
        padding-top: -6px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .current-plan {
            font-weight: bold;
            font-size: 16px;
        }
        .ant-btn {
            width: 306px;
            height: 44px;
            font-size: 18px;
            color: #FFFFFF;
            margin-bottom: 15px;
            outline: none;
            box-shadow: 0px 3px 3px #00000033;
            border-radius: 6px;
            opacity: 1;
            border: 0;

            .ant-button-primary{
                background: #FF9720;
            }
        }
        .ant-radio-group {
            text-align: center;
            

            .ant-radio-wrapper {
                text-align: center;
                line-height: 25px;

                & span{
                    font-size: 14px;
                }

                .ant-radio{
                    margin-bottom: 4.8px;
                    margin-right: 5px;
                }
            }
        }
    }
}

.choose-user-modal {
    width: 346px !important;
    .ant-modal-content{
        width: 346px;
        height: 240px;
        border-radius: 20px;
        opacity: 1;
    }
    .ant-modal-close{
        top:1px;
    }
    .ant-modal-header {
        border-radius: 20px 20px 0 0;
        text-align: center;
    }
    .ant-modal-body {
        text-align: center;
    }
    .ant-btn {
        width: 100%;
        height: 44px;
        font-size: 16px;
        color: #FFFFFF;
        margin-bottom: 15px;
        outline: none;
        box-shadow: 0px 3px 3px #00000033;
        border-radius: 6px;
        opacity: 1;
        border: 0;

        .ant-button-primary{
            background: #FF9720;
        }
    }
    .ant-radio-group {
        .ant-radio-wrapper {
            text-align: center;
            line-height: 25px;

            & span{
                font-size: 14px;
            }

            .ant-radio{
                margin-bottom: 4.8px;
                margin-right: 5px;
            }
        }
    }
}


.choose-household-modal {
    width: 346px !important;
    .ant-modal-content{
        width: 346px;
        border-radius: 20px;
        opacity: 1;
        overflow: hidden;
    }
    .ant-modal-close{
        top:1px;
    }
    .ant-modal-header {
        border-radius: 20px 20px 0 0;
        text-align: center;
    }
    .ant-modal-body {
        text-align: center;
        padding: 20px 0 !important;
        .household-wrapper {
            margin-bottom: 10px;
            max-height: 120px;
            overflow: auto;
            scrollbar-color: #f5f5f5 !important;
            scrollbar-width: thin !important;
            &::-webkit-scrollbar {
                width: 5px;
                background-color: #f5f5f5;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
                background-color: #ccc;
            }
        }
    }
    .ant-btn {
        width: 85%;
        height: 44px;
        font-size: 16px;
        color: #FFFFFF;
        margin-bottom: 15px;
        outline: none;
        box-shadow: 0px 3px 3px #00000033;
        border-radius: 6px;
        opacity: 1;
        border: 0;

        .ant-button-primary{
            background: #FF9720;
        }
    }
    .ant-radio-group {

        .ant-radio-wrapper {
            text-align: center;
            line-height: 25px;

            & span{
                font-size: 14px;
            }

            .ant-radio{
                margin-bottom: 4.8px;
                margin-right: 5px;
            }
        }
    }
}

.add-card-modal {
    //position: relative;
    width: 480px !important;
    .ant-modal-content{
        // width: 480px;
        height: 320px;
        border-radius: 20px;
        opacity: 1;
        position: relative;
        .loader-wrap {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
            overflow: hidden;
        }
         
    }
    .ant-modal-close{
        top:13px;
    }
    .ant-modal-header {
        padding-top: 30px;
        padding-bottom: 10px;
        border-radius: 20px;
        text-align: center;
        border-bottom: 0;
    }
    .ant-modal-body{
        padding-top: -6px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .ant-btn {
            margin-top: 20px;
            width: 100%;
            height: 44px;
            font-size: 18px;
            color: #FFFFFF;
            margin-bottom: 15px;
            outline: none;
            box-shadow: 0px 3px 3px #00000033;
            border-radius: 6px;
            opacity: 1;
            border: 0;

            .ant-button-primary{
                background: #FF9720;
            }
        } 
        
        .input-sm {
            margin-bottom: 10px;
            input {
                height: 40px;
                border-radius: 5px;
                border-color: #58595b4d;
                font-size: 14px;
                padding: 11px;

                color: $light-grey;
                &::placeholder {
                    color: #58595bb3;
                }
            }
            .ant-select-selector {
                height: 40px;
                border-radius: 5px;
                border-color: #58595b4d;
                font-size: 14px;

                .ant-select-selection-item {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    color: $light-grey;
                }
                .ant-select-selection-placeholder {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                }
            }
        }

        .input-group-sm {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            width: 100%;
            .ant-select,
            input {
                flex-basis: 33.3%;
                -ms-flex-preferred-size: 33.3%;
                &:not(:last-child) {
                    margin-right: 5px;
                }
            }
            .ant-select-selector,
            input {
                font-size: 12px !important;
            }
            .ant-select-selector .ant-select-selection-placeholder {
                display: flex;
                align-items: center;
                color: #58595bb3;
            }
        }

        .sub-form-err {
            margin-top: 1px;
            font-size: 10px;
        }
    }

}