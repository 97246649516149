@import 'Assets/styles/global.scss';
.signup-btn {
    padding-top: 10px;
}
.phone-form {
    input {
        padding-left: 90px !important;
        position: relative;
    }
    .code-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        padding: 10px;
        background: #0000001f;
        .code {
            margin-left: 5px;
            color: $light-grey;
            font-size: 16px;
            @media (max-width: 1367px) {
                font-size: 14px;
            }
        }
    }
}
.form-bottom{
  margin-top: 20px;
}

.otp-box-wrap {
    .otp-input-wrap {
        display: flex;
        input,
        input:focus {
            width: 50px !important;
            height: 50px !important;
            padding: 10px 15px !important;
            font-size: 24px !important;
            color: #000 !important;
            border: 1px solid #58595b4d;
            margin-right: 10px;
            @media(max-width: 768px){
                width: 35px !important;
                height: 35px !important;
                padding: 0 !important;
                font-size: 14px;
            }
            @media(max-width: 360px){
                width: 30px !important;
                height: 30px !important;
            }
        }
    }
    .otp-label-wrap {
        margin-top: 20px;
        color: #58595bb3;
        font-size: 13px;
        font-weight: 300;
        .otp-timer {
            color: #58595b;
            span {
                color: #000;
                font-weight: 500;
            }
        }
    }
    .otp-resend {
        text-align: right;
        margin-bottom: 15px;
        margin-top: 15px;
        a {
            color: #000;
            font-size: 12px;
            text-decoration: underline;
            &:hover {
                color: #479ed7;
            }
        }
    }
}
.otp-error {
    .otp-input-wrap {
        input {
            border-color: #f75f5e !important;
        }
    }
    .otp-label-wrap {
        color: #f75f5e !important;
    }
}
.otp-success {
    .otp-label-wrap {
        color: #22b422 !important;
    }
}


.terms_box {
    margin-right: 10px;
}

.terms_text {
    display: inline-block;
    font-size: 11px;
    color: #58595b;
    opacity: 0.7;
    margin-top: 5px;
    @media (max-width: 500px) {
        margin-top: 10px;
    }
}

.confirm_text {
    margin-top: 25px;
}