@import 'Assets/styles/global.scss';

.payments {
    height: calc(50% - 5px);
    margin-top: 10px;
    display: flex;
    .ant-card-body {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        .scrollarea-content {
            flex: 1 1 0%;
        }
    }
    &-head {
        text-align: center;
        padding: 15px;
        h1 {
            font-size: 20px !important;
            font-weight: 700 !important;
            @media (max-width: 1919px) {
                font-size: 14px !important;
            }
            @media (max-width: 1366px) {
                font-size: 13px !important;
            }
        }
    }
    &-modal {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 0px 15px 0px 15px;
        .ant-btn {
            -ms-flex-preferred-size: 50%;
            flex-basis: 50%;
            border-radius: 8px;
            border: 0;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            &:not(:last-child) {
                margin-right: 10px;
            }
            span {
                font-size: 14px;
                font-weight: 600;
            }
            @media (max-width: 1919px) {
                span {
                    font-size: 10px;
                }
            }
            @media (max-width: 1366px) {
                span {
                    font-size: 9px;
                }
            }
        }
    }
    &-create-btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 0px 15px 0px 15px;
        .ant-btn {
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            border-radius: 8px;
            border: 0;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            span {
                font-size: 14px;
                font-weight: 600;
            }
            @media (max-width: 1919px) {
                span {
                    font-size: 10px;
                }
            }
            @media (max-width: 1366px) {
                span {
                    font-size: 9px;
                }
            }
        }
    }
    &-btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 10px 15px 10px 15px;
        border-bottom: 1px solid $text-sec-color;
        // margin: 0px -15px 0px -15px;
        .ant-btn {
            -ms-flex-preferred-size: 33.3%;
            flex-basis: 33.3%;
            background: $white-color;
            border-radius: 8px;
            border: 1px solid $text-sec-color;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            &:not(:last-child) {
                margin-right: 5px;
            }
            span {
                color: $text-sec-color;
                font-size: 12px;
            }
            @media (max-width: 1919px) {
                span {
                    font-size: 8px;
                }
            }
            @media (max-width: 1366px) {
                span {
                    font-size: 10px;
                }
            }
        }
        .active {
            background: $dark-orange;
            box-shadow: 0px 6px 15px #0000000d;
            border-radius: 6px;
            opacity: 1;
            border: 0;
            span {
                color: #fff !important;
            }
        }
    }
    &-popup {
        width: 500px !important;
        padding: 0;
        .ant-modal-header {
            border-radius: 20px;
            text-align: center;
            border-bottom: 0;
        }
        .ant-modal-title {
            font-size: 20px;
            font-weight: 700;
        }
        .modal-top {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            width: 100%;
            margin-top: 20px;
            .detail-wrap {
                span {
                    font-size: 16px;
                    color: $black-color;
                    &:nth-of-type(2) {
                        font-weight: 600;
                    }
                }
            }
            .ant-form-item {
                width: 50%;
                &:nth-of-type(1) {
                    margin-right: 5px;
                }
                &:nth-of-type(2) {
                    margin-left: 5px;
                }
            }
        }
        .req-action {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -ms-flex-preferred-size: 33%;
            flex-basis: 33%;
        }
        .req-amount {
            -ms-flex-preferred-size: 33%;
            flex-basis: 33%;
        }
        .cb-wrap {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-preferred-size: 33%;
            flex-basis: 33%;
            .req-details {
                -ms-flex-preferred-size: auto;
                flex-basis: auto;
            }
            .img-wrap {
                width: 10px;
                height: 10px;
                margin-right: 10px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .icon-card {
            .req-action {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
            }
        }
        .icon-wrap {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin-bottom: 2px;
            span {
                font-size: 12px !important;
                color: $text-sec-color;
            }
            svg {
                fill: $primary-color;
                width: 14px;
                height: 14px;
            }
        }
        .bottom-btn {
            margin-top: 10px;
            .ant-btn {
                width: 100%;
                border: 0;
                border-radius: 8px;
                span {
                    font-size: 14px;
                }
                &:first-of-type {
                    margin-bottom: 10px;
                }
            }
            span {
                &:first-of-type {
                    position: absolute;
                    left: 10px;
                    width: 22px;
                    height: 22px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .ant-modal-close {
            top: 0px !important;
            svg {
                width: 18px;
                height: 18px;
            }
        }
        @media (max-width: 1919px) {
            width: 420px !important;
            .ant-modal-title {
                font-size: 14px;
            }
            .modal-top {
                .detail-wrap {
                    span {
                        font-size: 14px;
                    }
                }
            }
            .icon-wrap {
                span {
                    font-size: 9px !important;
                }
            }
            .bottom-btn {
                .ant-btn {
                    span {
                        font-size: 11px;
                    }
                }
                span {
                    &:first-of-type {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
        @media (max-width: 1366px) {
            width: 376px !important;
            .ant-modal-title {
                font-size: 14px;
            }
            .modal-top {
                .detail-wrap {
                    span {
                        font-size: 12px;
                    }
                }
            }
            .icon-wrap {
                span {
                    font-size: 10px !important;
                }
            }
            .bottom-btn {
                .ant-btn {
                    span {
                        font-size: 12px;
                    }
                }
                span {
                    &:first-of-type {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
    }
    .req-action {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-preferred-size: 33%;
        flex-basis: 33%;
    }
    .icon-action {
        flex-direction: column;
        align-items: center;
        .anticon {
            width: 13px;
            height: 13px;
            svg {
                width: 100%;
                height: 100%;
                fill: $primary-color;
            }
        }
        .icon-wrap span {
            font-size: 12px;
            color: $text-sec-color;
            margin-left: 3px;
        }
    }
    .req-amount {
        -ms-flex-preferred-size: 33%;
        flex-basis: 33%;
    }
    .loader-wrap {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.pr-popup {
    position: relative;
    .loader-wrap {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .ant-form-item {
        margin-bottom: 0px !important;
    }
    .pr-input {
        margin-bottom: 10px !important;
    }
    .input-sm {
        margin-bottom: 10px !important;
    }

    .upload-wrapper {
        position: relative;
    }
    input[type='file'],
    #img {
        position: absolute;
        z-index: 9;
        opacity: 0;
        cursor: pointer;
    }
    .file-track {
        display: flex;
        justify-content: center;
        font-size: 12px;

        span {
            color: $danger-color;
            cursor: pointer;
            margin-left: 10px;
        }
        a {
            color: $primary-color;
            margin-right: 10px;
        }
    }
    .upload-file {
        margin-top: 10px;
        border-bottom: 1px solid rgba(153, 153, 153, 0.2);
        .modal-bottom {
            border-bottom: 0px !important;
            padding-bottom: 0 !important;
        }
    }
    .file-err {
        color: $brick-red;
        font-size: 12px;
        margin-bottom: 10px;
    }
    .field-err {
        color: $brick-red;
        font-size: 12px;
    }
    .form-scroll {
        padding: 0px 12px 0px 12px;
        max-height: 350px;
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 10px;
        &::-webkit-scrollbar {
            width: 10px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
            background-color: #ccc;
        }
    }
    .btn-wrap {
        padding: 0px 12px 0px 12px;
    }
    .modal-bottom {
        .ant-btn {
            span {
                font-size: 12px;
                color: $white-color;
            }
        }
    }
}

.custom-popup {
    position: relative;
    .loader-wrap {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    input,
    .ant-select-selector {
        border: 0 !important;
        box-shadow: 0px 4px 10px $shadow-color;
        border-radius: 8px !important;
        height: 40px !important;
        color: $primary-color;
        font-size: 12px;
        margin-bottom: 10px !important;
        display: flex;
        align-items: center;
        &::placeholder {
            color: $primary-color;
        }
        @media (min-width: 1440px) {
            height: 44px;
            font-size: 14px;
        }
    }
    .ant-form-item-label {
        // top: -27px !important;
        padding: 0;
        label {
            font-size: 12px;
            color: $text-sec-color;
            @media (min-width: 1440px) {
                // font-size: 14px;
            }
        }
    }
    .ant-select-arrow {
        color: $primary-color !important;
        top: 38% !important;
    }
    textarea {
        border-radius: 6px;
        border-color: $text-border !important;
        font-size: 12px;
        margin: 5px 0px;
        @media (min-width: 1440px) {
            max-height: 46px;
            font-size: 14px;
        }
    }
    .input {
        &-sm {
            margin-bottom: 15px;
            input {
                height: 22px !important;
                border-bottom: 1px solid $grey-border !important;
                border-radius: 0 !important;
                box-shadow: none;
                color: $text-sec-color !important;
                padding-left: 0;
                &::placeholder {
                    color: $text-sec-color !important;
                }
            }
        }
        &-code {
            input {
                height: 20px;
            }
        }
    }
    .ant-btn {
        width: 100%;
        height: 36px;
        border: 0;
        border-radius: 6px;
        span {
            font-size: 12px;
            font-weight: 600;
        }
        @media (min-width: 1440px) {
            span {
                font-size: 14px;
            }
            height: 40px;
        }
    }
    .btn-form {
        margin-bottom: 10px !important;
    }
    .ant-modal-body {
        padding: 12px 0px 12px 0px;
        padding-bottom: 10px;
    }
    .btn-red {
        background: $brick-red;
    }
    .fp-button-red {
        background: $brick-red;
    }
    .transfer-details {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        span {
            font-size: 13px;
            color: $text-color;
            @media (min-width: 1440px) {
                font-size: 15px;
            }
        }
        .dispute {
            color: $brick-red;
        }
    }
    .dispute-txt {
        margin-bottom: 0px !important;
        margin-top: 30px;
    }
    .dispute-err {
        font-size: 12px;
        color: $brick-red;
        margin: 5px 0px;
    }
    .recipient-date {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 20px;
        span {
            font-size: 13px;
            color: $text-color;
        }
    }
    .details {
        span {
            font-size: 13px;
            color: $text-color;
        }
    }
    .total-details {
        margin: 20px 0;
        display: flex;
        justify-content: space-between;
        span {
            font-size: 13px;
            color: $text-color;
            font-weight: 600;
        }
    }
    .line {
        height: 0px;
        width: 100%;
        border: 1px dotted $text-color;
    }
    .line-special {
        height: 0px;
        width: 100%;
        border: 1px dotted $text-color;
        margin-bottom: 15px;
    }
    .dispute-text {
        text-align: center;
        height: 36px;
        margin-top: 20px;
        border-radius: 6px;
        margin-bottom: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $brick-red;
        span {
            font-size: 12px;
            color: $brick-red;
        }
    }
    .ant-modal-content {
        overflow: hidden;
    }
    // .ant-form {
    //     padding: 0px 12px 0px 12px;
    //     max-height: 420px;
    //     overflow-y: auto;
    //     overflow-x: hidden;
    //     margin-bottom: 10px;
    //     &::-webkit-scrollbar {
    //         width: 3px;
    //         background-color: #f5f5f5;
    //     }
    //     &::-webkit-scrollbar-thumb {
    //         border-radius: 5px;
    //         -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
    //         background-color: #ccc;
    //     }
    // }
    .form-scroll {
        padding: 0px 12px 0px 12px;
        max-height: 350px;
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 10px;
        &::-webkit-scrollbar {
            width: 10px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
            background-color: #ccc;
        }
    }
    .btn-wrap {
        padding: 0px 12px 0px 12px;
    }
    .upload-wrapper {
        position: relative;
    }
    input[type='file'],
    #img {
        position: absolute;
        z-index: 9;
        opacity: 0;
        cursor: pointer;
    }
    .file-track {
        display: flex;
        justify-content: space-around;
        font-size: 12px;
        margin-bottom: 10px;
        span {
            color: $danger-color;
            cursor: pointer;
            margin-left: 10px;
        }
        a {
            color: $primary-color;
            margin-right: 10px;
        }
    }
    .upload-file {
        margin-top: 10px;
        border-bottom: 1px solid rgba(153, 153, 153, 0.2);
        margin-bottom: 0px;
        .modal-bottom {
            border-bottom: 0px !important;
            padding-bottom: 0 !important;
        }
    }
    .file-err {
        display: block;
        color: $brick-red;
        font-size: 12px;
        margin-bottom: 10px;
    }

    .img-preview {
        display: block;
        margin: 0 auto;
        margin-bottom: 20px;
        width: auto;
        max-width: 376px;
    }
}
.del-popup {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    .btn {
        width: 80%;
        margin-bottom: 20px;
        margin-left: 35px;
    }
}

.rb-popup {
    position: relative;
    .loader-wrap {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .transfer-details {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        span {
            font-size: 12px;
            color: $text-color;
        }
    }
    .input-select {
        margin-bottom: 20px !important  ;
    }
    .detail-wrap {
        margin-bottom: 20px;
    }
    .btn-form {
        margin-bottom: 10px;
        .fp-button-primary {
            background: $primary-color;
        }
        .fp-button-red {
            background: $brick-red;
        }
    }
    .upload-wrapper {
        position: relative;
        .ant-btn {
            span {
                color: $white-color;
            }
        }
    }
    input[type='file'],
    #img {
        position: absolute;
        z-index: 9;
        opacity: 0;
        cursor: pointer;
    }
    .file-track {
        display: flex;
        justify-content: center;
        font-size: 12px;
        span {
            color: $danger-color;
            cursor: pointer;
            margin-left: 10px;
        }
        a {
            color: $primary-color;
            margin-right: 10px;
        }
    }
    .upload-file {
        margin-top: 10px;
        border-bottom: 1px solid rgba(153, 153, 153, 0.2);
        .modal-bottom {
            border-bottom: 0px !important;
            padding-bottom: 0 !important;
        }
    }
    .file-err {
        color: $brick-red;
        font-size: 12px;
        margin-bottom: 10px;
    }
    .form-scroll {
        padding: 0px 12px 0px 12px;
        max-height: 350px;
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 10px;
        &::-webkit-scrollbar {
            width: 10px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
            background-color: #ccc;
        }
    }
    .btn-wrap {
        padding: 0px 12px 0px 12px;
    }
    .field-err {
        color: $brick-red;
        font-size: 12px;
    }
}

.cancel-popup {
    position: relative;
    .loader-wrap {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .trans {
        &-details {
            span {
                color: $text-sec-color;
                font-size: 12px;
                @media (min-width: 1440px) {
                    font-size: 14px;
                }
            }
            .send {
                &-name,
                &-fee {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;
                }
                &-amount {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                }
            }
            .reciepient {
                border-bottom: 1px solid $grey-border;
                &-date {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;
                }
                &-name {
                    margin: 5px 0px 10px 0px;
                }
            }
            .total {
                display: flex;
                justify-content: space-between;
                margin: 10px 0px 10px 0px;
                span {
                    color: $text-color;
                    font-size: 10px;
                }
            }
        }
        &-notes {
            color: $text-color;
            font-size: 10px;
            margin-bottom: 20px;
            @media (min-width: 1440px) {
                font-size: 13px;
            }
            span {
                display: block;
                margin-bottom: 10px;
            }
        }
    }
    .upload-wrapper {
        position: relative;
    }
    input[type='file'],
    #img {
        position: absolute;
        z-index: 9;
        opacity: 0;
        cursor: pointer;
    }
    .file-track {
        display: flex;
        justify-content: center;
        font-size: 12px;
        span {
            color: $danger-color;
            cursor: pointer;
            margin-left: 10px;
        }
        a {
            color: $primary-color;
            margin-right: 10px;
        }
    }
    .upload-file {
        margin-top: 10px;
        border-bottom: 1px solid rgba(153, 153, 153, 0.2);
        .modal-bottom {
            border-bottom: 0px !important;
            padding-bottom: 0 !important;
        }
    }
    .file-err {
        color: $brick-red;
        font-size: 12px;
        margin-bottom: 10px;
    }
}
.ach-payment {
    .add-btn {
        .ant-btn {
            width: 100%;
            border: 0;
            border-radius: 6px;
            height: 40px;
            span {
                font-size: 12px;
                font-weight: 600;
            }
        }
    }
    .loader-wrap {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .icon-wrap {
        svg {
            fill: $text-sec-color;
        }
    }
    .req-action {
        .ant-btn {
            height: 22px !important;
            min-width: 68px;
        }
    }
    .req-amount {
        span {
            color: $text-sec-color !important ;
            font-size: 12px !important;
            &:nth-of-type(2) {
                font-size: 10px !important;
            }
        }
    }
    .req-details {
        span {
            width: 90px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .ant-modal-body {
        padding-top: 0;
    }
    .ant-tabs-tab {
        background: #fff;
        box-shadow: 0px 6px 15px #0000000d;
        border-radius: 6px;
        width: 100%;
        height: 34px;
        margin: 10px 0px 20px 0px;
        display: flex;
        justify-content: center;
        font-size: 10px;
        color: $text-sec-color;
        &:not(:nth-last-child(2)) {
            margin-right: 10px;
        }
    }

    .ant-tabs-tab.ant-tabs-tab-active {
        background: $primary-blue !important;
        .ant-tabs-tab-btn {
            color: #fff;
        }
    }
    .ant-tabs-nav-list {
        width: 100%;
    }
}
.input-code {
    margin-bottom: 10px !important;
}
.cust-text-area {
    min-height: 44px;
    background-color: #f3f3f3;
    border-radius: 6px;
    padding: 5px 10px;
    .text-inner {
        span {
            &:nth-child(2) {
                font-size: 10px;
                color: $text-sec-color;
            }
            &:nth-child(1) {
                margin-right: 10px;
            }
        }
        img {
            width: 12px;
            height: 12px;
        }
    }
    @media (min-width: 1440px) {
        min-height: 47px;
    }
}

.list-card {
    cursor: pointer;
}

.ach-error-popup {
    .btn {
        margin-bottom: 20px;
    }
    .exceed-btn{
        width: 80%;
        margin-left: 10%;
    }
}
.edit {
    &-payment {
        position: relative;
        .loader-wrap {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .ant-modal-title {
            font-size: 14px;
            color: $black-color;
            @media (max-width: 1919px) {
                font-size: 18px;
            }
            @media (max-width: 1366px) {
                font-size: 14px;
            }
        }
        .ant-modal-body {
            padding: 0px 12px 15px 12px !important;
        }
        .ant-modal-header {
            padding-bottom: 20px;
        }
        .cust-input {
            margin-bottom: 10px !important;
            .ant-input {
                border: 0 !important;
                box-shadow: 0px 4px 10px #0000000a;
                border-radius: 8px;
                height: 40px;
                color: #38a0eb;
                font-size: 12px;
                display: flex;
                align-items: center;
            }
            .ant-select-selector {
                border: 0 !important;
                box-shadow: 0px 4px 10px #0000000a;
                border-radius: 8px;
                height: 40px;
                color: #38a0eb;
                font-size: 12px;
                display: flex;
                align-items: center;
            }
            .ant-select-arrow {
                color: #38a0eb;
            }
        }
        .input-sm {
            margin-bottom: 10px;
            input {
                height: 22px;
                border-bottom: 1px solid #e9e9e9 !important;
                border-radius: 0;
                box-shadow: none;
                color: #999999 !important;
                border: 0;
                font-size: 10px;
                padding-left: 0;
                font-size: 12px;
                @media (max-width: 1919px) {
                    height: 26px;
                    font-size: 14px;
                }
            }
            .ant-form-item-label {
                // top: -20px;
                padding-left: 0;
                padding-bottom: 0 !important;
                label {
                    font-size: 10px;
                    color: $text-sec-color;
                    @media (max-width: 1919px) {
                        font-size: 12px;
                    }
                }
            }
        }
        .input-number {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .ant-form-item {
                width: 50%;
                margin-bottom: 20px;
                &:nth-of-type(1) {
                    margin-right: 5px;
                }
                &:nth-of-type(2) {
                    margin-left: 5px;
                }
                .ant-input-number {
                    width: 100%;
                    box-shadow: 0px 6px 15px #0000000d;
                    border-radius: 6px;
                    border: 0;
                    input {
                        height: 48px;
                        color: $text-sec-color;
                        @media (max-width: 1366px) {
                            height: 44px;
                        }
                    }
                    .ant-input-number-handler-wrap {
                        opacity: 1;
                        border: 0;
                        width: 30px;
                        .anticon {
                            font-size: 13px;
                            width: 25px;
                        }
                    }
                    .ant-input-number-handler-down {
                        border: 0;
                    }
                }
            }
            .ant-form-item-label {
                // top: -30px;
                padding-left: 0;
                label {
                    font-size: 12px;
                    color: $text-color;
                }
            }
        }
        .ant-checkbox-wrapper {
            .ant-checkbox-inner {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                color: $text-sec-color;
                border-color: $text-sec-color !important;
            }
            .cb-text {
                font-size: 12px;
                color: $text-sec-color;
            }
        }

        .cb-form {
            margin-bottom: 20px;
            .ant-checkbox-checked {
                &:hover,
                &:active,
                &:focus {
                    border: 0 !important;
                }
            }
        }
        .btn-full {
            margin-bottom: 10px;
            button {
                width: 100%;
                border: 0;
                height: 36px;
                border-radius: 6px;
                span {
                    font-size: 12px;
                    font-weight: 500;
                }
            }
        }
        table thead tr th,
        table tbody tr td {
            padding: 10px;
        }

        table thead {
            border-bottom: 1px solid #ddd;
            color: $text-sec-color;
        }

        table thead tr th {
            font-weight: 400;
        }

        table {
            margin-bottom: 20px;
        }

        @media (max-width: 1366px) {
            max-width: 346px;
        }
    }
    &-type {
        font-size: 14px;
        color: $black-color;
        margin-bottom: 10px;
        @media (max-width: 1919px) {
            font-size: 16px;
        }
        @media (max-width: 1366px) {
            font-size: 13px;
        }
    }
}
.create-pay-modal {
    position: relative;
    .loader-wrap {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .add-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        border-radius: 6px;
        height: 24px;
        width: 70px;
        span {
            font-size: 12px !important;
            font-weight: 600;
            color: #fff !important;
        }
        @media (max-width: 1366px) {
            span {
                font-size: 12px;
                text-shadow: none;
            }
        }
    }
    .select-wrap {
        .ant-select-selector {
            border: 0 !important;
            box-shadow: 0px 4px 10px #0000000a;
            border-radius: 8px;
            height: 40px;
            color: #38a0eb;
            font-size: 12px;
            display: flex;
            align-items: center;
            @media (max-width: 1919px) {
                height: 43px;
                font-size: 14px;
            }
        }
        .ant-select-selection-item {
            font-size: 12px;
            color: $primary-color !important;
        }
        .ant-select-arrow {
            color: $primary-color !important;
        }
    }
    .ant-radio-inner {
        width: 20px !important;
        height: 20px !important;
        border-color: $black-color;
        &:after {
            top: 5px !important;
            left: 5px !important;
        }
    }
    .ant-radio-checked .ant-radio-inner {
        border-color: #1890ff !important;
    }
    .ant-radio-wrapper {
        margin: 0px 0px 17px 10px !important;
    }
    .ant-modal-header {
        border-radius: 20px;
        border-bottom: 0;
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        color: $black-color;
        padding-bottom: 0;
        padding-top: 15px;
        .ant-modal-title {
            font-size: 14px;
            font-weight: 600;
            color: $black-color;
        }
    }
    .ant-modal-body {
        padding: 20px 0px 5px 0px !important;
    }
    .ant-modal-close {
        top: 0px !important;
        svg {
            width: 18px;
            height: 18px;
        }
    }
    .input-sm {
        margin-bottom: 35px;
        input {
            height: 22px !important;
            border-bottom: 1px solid #e9e9e9 !important;
            border-radius: 0;
            box-shadow: none;
            color: #999999 !important;
            border: 0 !important;
            font-size: 10px;
            padding-left: 0;
            font-size: 12px;
            @media (max-width: 1919px) {
                height: 26px;
                font-size: 14px;
            }
        }
        .ant-input-affix-wrapper {
            border: 0 !important;
        }
        .ant-input-suffix {
            font-size: 12px !important;
            position: absolute;
            right: 10px;
        }
    }
    .ant-radio-wrapper {
        span {
            font-size: 11px !important;
            color: $text-color;
            &:first-of-type {
                font-weight: 600 !important;
                font-size: 12px !important;
            }
        }
    }
    .modal-btn {
        border: 0;
        width: 100%;
        height: 44px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border-radius: 5px;
        span {
            font-size: 12px;
        }
        @media (max-width: 1366px) {
            height: 36px;
            span {
                font-size: 12px;
                font-weight: 600;
                text-shadow: none;
            }
        }
    }
    .transfer-details {
        display: flex;
        margin-bottom: 18px !important;
        span {
            flex-basis: 33.3%;
            font-size: 12px;
            &:nth-of-type(2) {
                display: flex;
                justify-content: center;
            }
            &:last-of-type {
                display: flex;
                justify-content: flex-end;
            }
        }
        &.item {
            margin-bottom: 24px !important;
        }
    }
    .freq-wrap {
        // display: flex;
        // align-items: center;
        .ant-form-item {
            margin-bottom: 0;
            width: 100%;
        }
        .ant-picker {
            width: 100%;
            height: 40px;
            border: 0;
            box-shadow: 0px 4px 10px #0000000a;
            border-radius: 8px;
            margin-bottom: 0px;
            margin-top: 10px;
            input {
                color: $primary-color;
                font-size: 12px;
            }
        }
        .ant-picker-suffix {
            color: $primary-color;
        }
        .ant-select {
            margin-right: 5px !important;
        }
        .input-full {
            margin-bottom: 15px !important;
        }
        .ant-form-item-label {
            position: absolute;
            top: -16px;
            padding-left: 0px !important;
            label {
                font-size: 10px;
                color: $text-color;
            }
        }
    }
    .text-area-sm {
        margin-bottom: 0 !important;
        .ant-input {
            border-radius: 6px;
        }
    }
    .full-btn {
        margin-bottom: 15px !important;
    }
    // .ant-form {
    //     max-height: 420px;
    //     overflow-y: auto;
    //     overflow-x: hidden;
    //     padding: 0px 13px 0px 13px;
    //     margin-bottom: 10px;
    //     &::-webkit-scrollbar {
    //         width: 3px;
    //         background-color: #f5f5f5;
    //     }
    //     &::-webkit-scrollbar-thumb {
    //         border-radius: 5px;
    //         -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
    //         background-color: #ccc;
    //     }
    // }
    .form-scroll {
        padding: 0px 12px 0px 12px;
        max-height: 350px;
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 10px;
        &::-webkit-scrollbar {
            width: 10px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
            background-color: #ccc;
        }
    }
    .payment-scroll {
        &::-webkit-scrollbar {
            width: 10px !important;
        }
        .multi-select {
            .ant-select-selector {
                font-size: 12px;
                .ant-select-selection-placeholder {
                    color: $primary-blue;
                    font-size: 12px;
                }
            }
        }
    }
    .btn-wrap {
        padding: 0px 12px 0px 12px;
    }
    .ant-modal-content {
        overflow: hidden;
    }
    .upload-wrapper {
        position: relative;
    }
    input[type='file'],
    #img {
        position: absolute;
        z-index: 9;
        opacity: 0;
        cursor: pointer;
    }
    .file-track {
        display: flex;
        justify-content: center;
        font-size: 12px;
        span {
            color: $danger-color;
            cursor: pointer;
            margin-left: 10px;
        }
        a {
            color: $primary-color;
            margin-right: 10px;
        }
    }
    .upload-file {
        margin-top: 10px;
        border-bottom: 1px solid rgba(153, 153, 153, 0.2);
        .modal-bottom {
            border-bottom: 0px !important;
            padding-bottom: 0 !important;
        }
    }
    .file-err {
        color: $brick-red;
        font-size: 12px;
    }
    .display-form-err {
        color: $brick-red;
        font-size: 10px;
    }
    .cust-read {
        font-size: 12px;
        color: $primary-color;
        border: 0 !important;
        height: 43px;
        border-radius: 8px;
    }
    .mb-0 {
        margin-bottom: 0 !important;
    }
    .single-sel {
        height: 35px !important;
        .ant-select-selector {
            height: 35px !important;
        }
    }
}
.pr-input {
    margin-bottom: 10px !important;
}
.bp-modal {
    position: relative;
    .loader-wrap {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .ant-card-body {
        padding: 10px !important;
    }
    .ant-modal-body {
        padding: 0px 0px 15px 0px !important;
    }
    .ant-modal-header {
        padding-bottom: 10px !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background: $white-color !important;
        &::after {
            border-color: #4dabe7 !important;
        }
    }
    .scroll-wrap {
        padding: 0px 15px 0px 15px;
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-color: #f5f5f5 !important;
        scrollbar-width: thin !important;
        &::-webkit-scrollbar {
            width: 10px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
            background-color: #ccc;
        }
    }
    .modal-top {
        margin-top: 10px !important;
        margin-bottom: 20px !important;
        .detail-wrap span {
            font-size: 12px;
        }
        div:nth-of-type(1) {
            margin-left: 30px;
        }
        div:nth-of-type(2) {
            margin-right: 30px;
        }
    }
    .bottom-btn {
        padding: 0px 18px !important;
        margin-top: 15px;
        .ant-btn {
            height: 40px;
            span {
                font-size: 12px !important;
            }
        }
    }
}
.trans-detail {
    position: relative;
    .loader-wrap {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .pay-wrap {
        .detail {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            font-size: 12px;
            color: $text-color;
            margin-bottom: 4px;
        }
    }

    .detail-wrap {
        margin-bottom: 20px;
        div:nth-of-type(2) {
            font-size: 10px;
            color: $text-sec-color;
        }
    }
    .ant-form-item {
        margin-bottom: 20px !important;
    }
    .input-sm {
        margin-bottom: 0 !important;
        border-bottom: 1px solid $grey-border;
        input {
            font-size: 12px !important;
        }
    }
    .select-wrap {
        margin-bottom: 0 !important;
        display: block !important;
        .ant-select-selector {
            border: 0 !important;
            box-shadow: 0px 4px 10px #0000000a;
            border-radius: 8px;
            height: 40px;
            color: #38a0eb;
            font-size: 12px;
            display: flex;
            align-items: center;
        }
        .ant-select-selection-item {
            font-size: 12px;
            color: $primary-color !important;
        }
        .ant-select-arrow {
            color: $primary-color !important;
        }
        .ant-select {
            width: 100% !important;
            margin-right: 0 !important;
        }
        .ant-select-selection-placeholder {
            color: #38a0eb;
        }
    }
    .input-sm {
        margin-bottom: 35px;
        input {
            height: 22px !important;
            border-bottom: 1px solid #e9e9e9 !important;
            border-radius: 0;
            box-shadow: none;
            color: #999999 !important;
            border: 0 !important;
            font-size: 10px;
            padding-left: 0;
            font-size: 12px;
            @media (max-width: 1919px) {
                height: 26px;
                font-size: 14px;
            }
        }
        .ant-input-affix-wrapper {
            border: 0 !important;
        }
        .ant-input-suffix {
            font-size: 12px !important;
            position: absolute;
            right: 10px;
        }
    }
    .payment-detail {
        border-bottom: 1px solid $grey-border;
        padding: 10px 0;
    }
    .modal-bottom {
        padding-bottom: 0 !important;
        .ant-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0;
            border-radius: 6px;
            height: 24px;
            width: 70px;
            span {
                color: $white-color;
                font-size: 12px;
            }
        }
    }
    .upload-wrapper {
        position: relative;
    }
    input[type='file'],
    #img {
        position: absolute;
        z-index: 9;
        opacity: 0;
        cursor: pointer;
    }
    .file-track {
        display: flex;
        justify-content: center;
        font-size: 12px;
        margin: 5px 0px 10px 0px;
        span {
            color: $danger-color;
            cursor: pointer;
            margin-left: 10px;
        }
        a {
            color: $primary-color;
            margin-right: 10px;
        }
    }
    .file-err {
        color: $brick-red;
        font-size: 12px;
        margin-bottom: 10px;
    }
    .field-err {
        color: $brick-red;
        font-size: 12px;
    }
    .btn-form {
        margin-bottom: 0 !important;
        .ant-btn {
            width: 100%;
            border: 0;
            border-radius: 8px;
            font-size: 12px;
        }
    }
    .cust-mt {
        margin-top: 20px;
        div:nth-of-type(2) {
            font-size: 12px;
            color: $text-color;
        }
    }
    .ant-modal-body {
        padding: 0px 0px 20px 0px !important ;
        .pay-wrap {
            padding: 0px 20px 0px 20px;
            max-height: 300px;
            overflow-y: auto;
            overflow-x: hidden;
            scrollbar-color: #f5f5f5 !important;
            scrollbar-width: thin !important;
            &::-webkit-scrollbar {
                width: 10px;
                background-color: #f5f5f5;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
                background-color: #ccc;
            }
            .modal-bottom {
                border-bottom: 0 !important;
                margin-top: 10px;
            }
            .upload-file {
                border-bottom: 1px solid rgba(153, 153, 153, 0.2);
            }
        }
        .btn-pad {
            padding: 0px 20px 0px 20px;
        }
    }
}
.text-color {
    color: $primary-color;
}
.ant-card.payments {
    .ant-card-body {
        padding: 0 !important;
        .area {
            .scrollarea-content {
                padding: 15px !important;
                .list-card {
                    padding: 12px !important;
                }
            }
        }
    }
}
