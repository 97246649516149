@import 'Assets/styles/global.scss';
.card {
    &-head {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        margin: 15px 5px 20px 5px;
        @media (min-width: 1920px) {
            margin: 15px 5px 30px 5px;
        }
        span {
            font-size: 20px;
            font-weight: 700;
            color: $black-color;
            @media (max-width: 768px) {
                font-size: 14px;
            }
        }
        .btn-wrap {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 2;
            button {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                border: 0;
                height: 30px;
                border-radius: 5px;
                span {
                    color: #fff !important;
                    font-size: 12px;
                    font-weight: 600;
                    text-shadow: none;
                    line-height: 0;
                }
                img {
                    width: 15px;
                    height: 15px;
                    margin-right: 5px;
                }
            }
            @media (max-width: 768px) {
                button {
                    height: 25px;
                    padding: 10px;
                    span {
                        font-size: 10px !important;
                    }
                }
            }
        }
    }
    &-tab {
        flex: 1;
        max-height: calc(100% - 110px);
        .ant-tabs-tab {
            width: 50%;
            margin: 0 !important;
            padding: 10px 50px !important;
            background: #fff !important;
            border-radius: 0px 22px 22px 0px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            color: $text-sec-color;
            font-size: 12px;
            @media (min-width: 1990px) {
                font-size: 14px;
            }
            .ant-tabs-tab-btn {
                font-weight: 500;
            }
            &-active {
                @include secondary-gradient;
                .ant-tabs-tab-btn {
                    color: #fff !important;
                    font-weight: 600;
                }
            }
            &:first-of-type {
                border-radius: 22px 0px 0px 22px;
            }
        }
        .ant-tabs-content-holder {
            overflow: auto;
            scrollbar-color: #f5f5f5 !important;
            scrollbar-width: thin !important;

            &::-webkit-scrollbar {
                width: 5px;
                background-color: #f5f5f5;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
                background-color: #ccc;
            }
        }
        .ant-tabs-nav {
            margin: 0 auto;
            margin-bottom: 30px;
            &::before {
                display: none;
            }
        }
        .ant-tabs-nav-operations {
            display: none !important;
        }

        @media (max-width: 768px) {
            .ant-tabs-tab {
                padding: 5px 20px !important;
                &-btn {
                    font-size: 11px;
                }
            }
        }
    }
}
.list {
    &-card {
        .ant-card-body {
            padding: 10px !important;
        }
        &:not(:first-of-type) {
            margin-top: 10px;
        }
    }
    &-inner {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        position: relative;
    }
    &-date {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-right: 1px solid #eaeaea;
        padding-right: 10px;
        width: 55px;
        span {
            font-size: 12px;
            color: $text-sec-color;
            &:first-of-type {
                color: $black-color;
                font-weight: 700;
                font-size: 14px;
            }
        }
    }
    &-time {
        padding: 0px 10px;
        span {
            font-size: 14px;
            color: $black-color;
        }
    }
    &-task {
        padding: 0px 5px;
        width: 160px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        span {
            font-size: 14px;
            color: $black-color;
        }
    }
    &-check {
        width: 15px;
        height: 15px;
        margin-right: 10px;
        cursor: pointer;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        flex-shrink: 0;
        img {
            width: 100%;
            height: 100%;
        }
    }
    &-more {
        width: 15px;
        height: 15px;
        cursor: pointer;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        img {
            width: 100%;
            height: 100%;
        }
    }
    @media (max-width: 768px) {
        &-check,
        &-more {
            width: 15px;
            height: 15px;
        }
        &-task {
            padding: 0px 5px;
            span {
                font-size: 11px;
            }
        }
        &-time {
            padding: 0px 5px;
            span {
                font-size: 12px;
            }
        }
    }
}
.text-wrapper {
    margin-top: auto !important;
    margin-left: -15px;
    margin-right: -15px;
    padding: 10px 20px;
    margin-top: 5px;
    border-top: 1px solid rgba(112, 112, 112, 0.1);
    .ant-input {
        border-radius: 5px !important;
        font-size: 12px;
    }
}
.ant-popover-inner {
    border-radius: 5px !important;
}
.ant-popover-arrow {
    display: none;
}
.pop-content {
    span {
        color: $black-color;
    }
    .img-wrap {
        margin-left: 20px;
        img {
            width: 12px;
            height: 12px;
        }
    }
}
.td-card {
    height: 100%;
}
@media (min-width: 1920px) {
    .td-card {
        .list {
            &-date {
                span {
                    font-size: 14px;
                    &:first-of-type {
                        font-size: 16px;
                    }
                }
            }
            &-time {
                span {
                    font-size: 16px;
                }
            }
            &-task {
                span {
                    font-size: 16px;
                }
            }
        }
    }
}
@media (max-width: 1919px) {
    .td-card {
        .list {
            &-date {
                width: 65px;
                span {
                    font-size: 10px;
                    &:first-of-type {
                        font-size: 13px;
                    }
                }
            }
            &-time {
                span {
                    font-size: 12px;
                }
            }
            &-task {
                span {
                    font-size: 12px;
                }
            }
        }
    }
}
