@import 'Assets/styles/global.scss';
.req {
    &-inner {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    &-details {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-preferred-size: 33%;
        flex-basis: 33%;
        span {
            font-size: 11px;
            color: $text-sec-color;
            &:first-of-type {
                color: $text-color;
                font-size: 12px;
            }
        }
    }
    &-amount {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        span {
            &:nth-of-type(1) {
                color: $text-color;
                font-weight: 600;
                font-size: 14px;
            }
            &:nth-of-type(2) {
                font-size: 12px;
                color: $text-sec-color;
            }
        }
    }
    &-action {
        .btn {
            border: 0;
            height: 30px;
            width: 80px;
            border-radius: 8px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            &-send {
                // background: rgb(240, 200, 91);
                // background: -moz-linear-gradient(90deg, rgba(240, 200, 91, 1) 0%, rgba(255, 154, 41, 1) 100%);
                // background: -webkit-linear-gradient(90deg, rgba(240, 200, 91, 1) 0%, rgba(255, 154, 41, 1) 100%);
                // background: linear-gradient(90deg, rgba(240, 200, 91, 1) 0%, rgba(255, 154, 41, 1) 100%);
                // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f0c85b",endColorstr="#ff9a29",GradientType=1);
                &:hover {
                    background: transparent;
                    border: 1px solid rgb(240, 200, 91);
                    transition: all ease-in-out 0.5s;
                    span {
                        color: rgb(240, 200, 91);
                    }
                }
            }
            &-review {
                background: rgb(115, 190, 225);
                background: -moz-linear-gradient(90deg, rgba(115, 190, 225, 1) 0%, rgba(56, 157, 230, 1) 100%);
                background: -webkit-linear-gradient(90deg, rgba(115, 190, 225, 1) 0%, rgba(56, 157, 230, 1) 100%);
                background: linear-gradient(90deg, rgba(115, 190, 225, 1) 0%, rgba(56, 157, 230, 1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#73bee1",endColorstr="#389de6",GradientType=1);
                &:hover {
                    background: transparent;
                    border: 1px solid rgb(115, 190, 225);
                    transition: all ease-in-out 0.5s;
                    span {
                        color: rgb(115, 190, 225) !important;
                    }
                }
            }

            span {
                font-size: 14px;
                color: #fff;
                font-weight: 600;
                text-shadow: none;
            }
        }
    }

    &-head {
        margin-bottom: 10px;
    }
    @media (max-width: 768px) {
        &-details {
            span {
                font-size: 12px;
                &:last-child {
                    color: $text-sec-color;
                }
            }
        }
        &-amount {
            span {
                &:nth-of-type(1) {
                    color: $black-color;
                    font-weight: 600;
                    font-size: 12px;
                }
                &:nth-of-type(2) {
                    font-size: 10px;
                }
            }
        }
        &-action {
            .btn {
                span {
                    font-size: 11px;
                }
            }
        }
    }
}

.req-card {
    height: 50%;
    .ant-card-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    @media (max-width: 768px) {
        margin-bottom: 20px;
    }
    .d-block {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}
.custom-mr {
    margin-right: 10px;
}

@media (min-width: 1920px) {
    .req {
        &-details {
            span {
                font-size: 16px;
                color: $text-color;
                &:first-of-type {
                    font-size: 16px;
                }
            }
        }
        &-amount {
            span {
                font-size: 12px;
                color: $text-color;
                &:first-of-type {
                    font-size: 16px;
                }
            }
        }
        &-action {
            span {
                font-size: 14px;
            }
        }
    }
}
@media (max-width: 1919px) {
    .req {
        &-amount {
            span {
                font-size: 11px !important;
                color: $text-color;
                &:first-of-type {
                    font-size: 12px;
                }
            }
        }
        &-action {
            span {
                font-size: 10px !important;
            }
            .btn {
                height: 20px;
                width: 70px;
                border-radius: 6px;
            }
        }
    }
}
.ant-card.card-rec-req{
    height: 100% !important;
    .ant-card-body{
        padding: 0px !important;
    }
    .req-head{
        padding: 15px !important; 
    }
    .req-card-wrap{
        .scrollarea-content{
            padding: 15px !important;
            .list-card{
                .ant-card-body{
                    padding: 12px !important;
                }
            }
        }
       
    }
}
