.container {
    display: flex;
    width: 100%;
    background: inherit;
}

.buttonWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 2;
    margin: 0px 10px;
}

.button {
    border: none;
    cursor: pointer;
    background: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 0;
    margin-bottom: 5px;
    img {
        width: 100%;
        height: 100%;
    }
}

.dateListScrollable {
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none;
    margin: 2px 0px 10px 0px;
    // -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    // justify-content: center;
}
.container {
    max-width: 620px;
    margin: 0 auto;
}

.dateListScrollable::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
}

.monthContainer {
    display: flex;
    flex-direction: column;
}

.monthYearLabel {
    display: none;
}

.dateDayItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin: 0px 15px;
    width: 55px;
    height: 130px;
    flex-shrink: 0;
    border-radius: 30px;
}
#selected {
    border: 1px solid #ededed;
}

.daysContainer {
    display: flex;
    z-index: 1;
}

.dayLabel {
    font-size: 12px;
    font-weight: 600;
    margin: 4px 0px;
    text-transform: uppercase;
}

.dateLabel {
    width: 40px;
    height: 40px;
    background: #fafafa;
    border-radius: 50%;
    margin-top: 5px;
    span {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 20px;
        font-weight: 700;
        width: 100%;
        height: 100%;
        color: #333333;
    }
}
.select {
    background-color: #38a0eb;
    span {
        color: #fff;
    }
}
.btn-right {
    transform: rotate(180deg);
}
.notify {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0px 5px;
    span {
        width: 8px;
        height: 8px;
        background: red;
        border-radius: 50%;
        margin: 3px;
    }
    .Pink {
        background-color: #c910e8;
    }
    .Blue {
        background-color: #2196f3;
    }
    .Purple {
        background-color: #673ab7;
    }
    .Red {
        background-color: #ff0000;
    }
    .Green {
        background-color: #4caf50;
    }
    .Orange {
        background-color: #ffc107;
    }
    .Cyan {
        background-color: #00bcd4;
    }
    .Brown {
        background-color: #795548;
    }
    .White {
        background-color: #ffffff;
        border: 1px solid #000000;
    }
    .Grey {
        background-color: #999999;
    }
    .Charcoal {
        background-color: #333333;
    }
    .Black {
        background-color: #000000;
    }
    .Gradient1{
        background: linear-gradient(-200deg,#ffffff 50%, #999999 50%);
        border: 1px solid #000000;
    }
    .Gradient2{
        background: linear-gradient(-200deg,#ffffff 50%, #000000 50%);
        border: 1px solid #000000;
    }
    .blue {
        background-color: #ffc107;
    }
    .orange {
        background-color: #fca232;
    }
    .green {
        background-color: #22b422;
    }
    .violet {
        background-color: #dc2cd1;
    }
    .selected {
        background-color: #fafafa;
    }
}
@media (min-width: 1920px) {
    .dateDayItem {
        width: 60px;
        height: 160px;
    }
    .dateLabel {
        width: 53px;
        height: 53px;
        span {
            font-size: 20px;
        }
    }
    .dayLabel {
        font-size: 14px;
    }
    .notify {
        padding: 0px 5px;
        span {
            width: 12px;
            height: 12px;
            margin: 2px;
        }
    }
    .container {
        max-width: 670px;
        margin: 0 auto;
    }
}
@media (max-width: 1919px) {
    .dateDayItem {
        margin: 0px 8px;
    }
    .dateLabel {
        width: 44px;
        height: 44px;
        span {
            font-size: 14px;
        }
    }
    .dayLabel {
        font-size: 10px;
    }
}
@media (max-width: 1366px) {
    .dateDayItem {
        margin: 0px 8px !important;
        width: 44px;
        height: 120px;
        .dateLabel {
            width: 34px;
            height: 34px;
            span {
                font-size: 12px;
            }
        }
        .notify {
            padding: 0px 3px;
            span {
                width: 6px;
                height: 6px;
            }
        }
    }
}
@media (max-width: 768px) {
    .dateDayItem {
        margin: 0px 5px;
    }
    .dateLabel {
        width: 35px;
        height: 35px;
        span {
            font-size: 14px;
        }
    }
    .dayLabel {
        font-size: 10px;
    }
    .notify {
        padding: 0px 5px;
        span {
            width: 9px;
            height: 9px;
        }
    }
}
