@import 'Assets/styles/global.scss';

.sub-wrapper {
    .detail-head {
        h1 {
            font-size: 16px !important;
            margin-top: 30px;
        }
    }
    .offer-card {
        border-radius: 10px;
        border: 1px solid #58595b1a;
        box-shadow: 0px 5px 10px #0000000d;
        margin: 0px 20px 20px 20px;
        &-head {
            border-bottom: 1px solid #58595b1a;
            .head-wrap {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                padding: 12px 9px;
                font-size: 14px;
                color: $light-grey;
                font-weight: 300;
                span {
                    &:nth-of-type(1) {
                        -ms-flex-preferred-size: 40%;
                        flex-basis: 45%;
                        text-align: left;
                    }
                    &:nth-of-type(2) {
                        -ms-flex-preferred-size: 20%;
                        flex-basis: 20%;
                        text-align: center;
                    }
                    &:nth-of-type(3) {
                        -ms-flex-preferred-size: 40%;
                        flex-basis: 35%;
                        text-align: right;
                    }
                }
            }
        }
        .ant-card-body {
            margin-bottom: 10px !important;
        }
        .body {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            padding: 12px 9px;
            .item {
                -ms-flex-preferred-size: 45%;
                flex-basis: 45%;
                text-align: left;
                font-size: 16px;
                div {
                    margin-bottom: 6px;
                    &:nth-of-type(1) {
                        font-size: 16px;
                        color: $black-color;
                        font-weight: 500;
                    }
                    &:nth-of-type(2) {
                        font-size: 12px;
                        color: $black-color;
                        span {
                            &:first-of-type {
                                margin-right: 5px;
                            }
                        }
                    }
                    &:nth-of-type(3) {
                        font-size: 14px;
                        color: $light-grey;
                    }
                }
            }
            .quantity {
                -ms-flex-preferred-size: 20%;
                flex-basis: 20%;
                text-align: center;
                font-size: 16px;
                font-weight: 500;
                color: $black-color;
            }
            .rate {
                -ms-flex-preferred-size: 40%;
                flex-basis: 35%;
                text-align: right;
                font-size: 16px;
                font-weight: 500;
                color: $black-color;
            }
        }
        .discount {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding: 0px 9px;
            font-size: 14px;
            color: $light-grey;
            margin-bottom: 10px;
            .d-label {
                -ms-flex-preferred-size: 40%;
                flex-basis: 40%;
                text-align: left;
            }
            .d-input {
                -ms-flex-preferred-size: 40%;
                flex-basis: 40%;
                text-align: center;
                margin-right: 10px;
                input {
                    border-radius: 5px;
                    height: 40px;
                    text-align: center;
                    font-size: 14px;
                    color: $light-grey;
                    letter-spacing: 1.4px;
                }
            }
            .btn-apply {
                -ms-flex-preferred-size: 20%;
                flex-basis: 20%;
                text-align: right;
                .ant-btn {
                    border-radius: 5px;
                    height: 40px;
                    font-size: 12px;
                    color: $white-color;
                    border: 0;
                    background: #f99c30;
                }
            }
        }
        .success-msg {
            color: $success-color;
            font-size: 10px;
            text-align: center;
        }
        .err-msg {
            color: $brick-red;
            font-size: 10px;
            text-align: center;
        }
        .card-bottom {
            border-top: 1px solid #58595b1a;
            padding: 10px 9px 0px 10px;
            margin-top: 10px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            font-size: 16px;
            font-weight: 500;
            color: $dark-orange;
            .free-trial-btn-wrap {
                 width : 100%;
            }
            .free-trial-home-btn {
                width : 100%;
                color : #fff;
                font-size : 13px;
                border-radius  :6px;
                padding: 17px 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                border : 0px;


            }
        }
    }
    .form-card {
        padding: 10px 20px 20px 20px !important;
        margin: 20px;
        h2 {
            font-size: 14px;
            color: $black-color;
        }
        .ant-card-body {
            margin-bottom: 10px !important;
        }
        .field-wrap {
            .input-group {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                width: 100%;
                input,
                .ant-select {
                    &:nth-of-type(1) {
                        margin-right: 5px;
                        width: 50%;
                    }
                    &:nth-of-type(2) {
                        margin-left: 5px;
                        width: 50%;
                    }
                }
            }

            .input-group-sm {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                width: 100%;
                .ant-select,
                input {
                    flex-basis: 33.3%;
                    -ms-flex-preferred-size: 33.3%;
                    &:not(:last-child) {
                        margin-right: 5px;
                    }
                }
                .ant-select-selector,
                input {
                    font-size: 12px !important;
                }
            }
            .field-head {
                display: block;
                font-size: 14px;
                font-weight: 300;
                color: $black-color;
                margin-bottom: 20px;
                margin-top: 10px;
            }
            .input-sm {
                margin-bottom: 10px;
                input {
                    height: 40px;
                    border-radius: 5px;
                    border-color: #58595b4d;
                    font-size: 14px;
                    padding: 11px;

                    color: $light-grey;
                    &::placeholder {
                        color: #58595bb3;
                    }
                }
                .ant-select-selector {
                    height: 40px;
                    border-radius: 5px;
                    border-color: #58595b4d;
                    font-size: 14px;

                    .ant-select-selection-item {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        color: $light-grey;
                    }
                    .ant-select-selection-placeholder {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                    }
                }
            }
        }
        .bottom-btn {
            .ant-btn {
                width: 100%;
                height: 44px;
                border-radius: 6px;
                background: $primary-blue;
                font-size: 18px;
                color: $white-color;
                margin-top: 20px;
            }
        }
    }
}

.w-50 {
    width: 50%;
}
.sub-form-err {
    color: $brick-red;
    font-size: 10px;
}

.justify-content-between{
    justify-content: space-between;
}
.flex-end-block{
    display: flex;
    justify-content: flex-end;
}
.bottom-input{
    width: 33.3%;
    .ant-select{
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        width: 100%;
    }
}