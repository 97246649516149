@import 'Assets/styles/global.scss';
.coparent {
    &-card {
        height: calc(50% - 5px) !important;
        .ant-card-body {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .ant-card.coparent-inner{
            .ant-card-body{
                padding: 10px !important;
                font-size: 12px;
                color: #58595b;
            }
        }
        .wrap-name{
                width: 50%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                .w-break{
                    margin-right: 20px;
                    span{
                        word-break: break-word;
                    }
                }
        }
    }

    &-inner {
        margin: 10px 0px 0;
        box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.05) !important;
        .ant-card-body {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
            align-items: center;
            flex-direction: row !important;
            padding: 10px !important;
            font-size: 12px;
            color: #58595b;
            .btn {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                border: 0;
                border-radius: 6px;
                transition: all ease-in-out 0.2s;
                &-accept {
                    background: $dark-orange;
                    margin-right: 10px;
                }
                &-decline {
                 background: $primary-blue;
                }
            }
        }
        .name {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            width: 50%;
            font-size: 12px;
            color: $text-color;
            @media (min-width: 1920px) {
                font-size: 16px;
            }
        }
        .actions {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
            width: 50%;
            .btn {
                padding: 4px 22px;
                height: auto;
                font-size: 10px;
                font-weight: 600;
                @media (min-width: 1920px) {
                    font-size: 14px;
                }
            }
        }
    }
    &-list {
        // max-height: 300px;
        overflow: auto;
        padding: 0 10px 10px;
        margin-left: -10px;
        margin-right: -10px;
    }
}

.c-pointer {
    cursor: pointer;
}
.position-r {
    position: relative;
}
.wid-box {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-bottom: 1px solid #ccc;
    border-radius: 50%;
    margin: 0 auto;
    .ant-avatar{
        border: 1px solid #ccc;
        border-radius: 50% ;
        @media(min-width: 1440px){
            width: 80px !important;
            height: 80px !important;
        }
    }
}
.wid-box img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.wid-box .img-exist img{
    object-fit: contain;
    object-position: center;
} 
.position-ab {
    height: inherit;
    width: inherit;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    z-index: 1;
}
.edit-icon {
    width: 35px;
    height: 36px;
    padding: 9px;
    border: 1px solid #ccc;
    border-radius: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #a5a5a5;
    box-shadow: 0px 0px 10px #000;
    transition: 0.5s;
    opacity: 0;
}
.edit-icon svg {
    fill: #fff;
}
.img-upload:hover .edit-icon {
    opacity: 1;
    transition: 0.5s;
}
.small-font span {
    font-size: 13px;
    color: $light-grey;
}

// add coparent style change for the similar to the card
.header-h {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px;
    .icon-_size {
        fill: $primary-color;
        cursor: pointer;
    }
    .relation {
        font-size: 13px;
        color: $light-grey;
        .inside-info {
            display: flex;
            span {
                font-size: 13px;
                font-weight: 400;
                color: $light-grey;
            }
        }
    }
}
.header-h span {
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: capitalize;
}
// .coparent-list{
//     overflow: unset !important;
// }

// .ant-modal .select-wrap {
//     display: block !important;
// }

.form-err-coparent {
    color: #fb4437;
    position: absolute;
    left: 0;
    top: 36px;
    z-index: 2;
    font-size: 9px;
    padding-left: 12px;
}
.add-coparent {
    .ant-modal-body {
        padding: 20px !important;
    }
    .cust-textbox {
        // border: 1px solid rgb(217, 217, 217);
        padding: 8px 0px;
        font-size: 12px;
        // border-radius: 5px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none; 
        -moz-user-select: none; 
        -ms-user-select: none; 
        user-select: none;    
    }
    .cust-cb {
        span {
            font-size: 11px;
        }
    }
}
.del-pop {
    width: 290px !important;
}

    .resend-inv {
       display: -webkit-box;
       display: -ms-flexbox;
       display: flex;
       -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        .btn-resend{
            border-radius: 6px;
            padding: 0px 10px;
            height: 23px;
            margin-left: 10px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            span {
                font-size: 10px;
            }
        }
    }
    .view-coparent-d-opacity {
        .ant-input[disabled] {
            color: #000;
            background-color: #f5f5f5;
            cursor: not-allowed;
            opacity: 0.6;
        }
        .ant-select-disabled .ant-select-selection-item {
           color: #000 !important;
            opacity: 0.6;
        }
    }

.select-dot-kid-invite {
    .dot {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 5px;
        border-radius: 50%;
    }
    .Pink {
        background-color: #c910e8;
    }
    .Blue {
        background-color: #2196f3;
    }
    .Purple {
        background-color: #673ab7;
    }
    .Red {
        background-color: #ff0000;
    }
    .Green {
        background-color: #4caf50;
    }
    .Orange {
        background-color: #ffc107;
    }
    .Cyan {
        background-color: #00bcd4;
    }
    .Brown {
        background-color: #795548;
    }
    .White {
        background-color: #ffffff;
        border: 1px solid #000000;
    }
    .Grey {
        background-color: #999999;
    }
    .Charcoal {
        background-color: #333333;
    }
    .Black {
        background-color: #000000;
    }
    .Gradient1 {
        background: linear-gradient(-200deg, #ffffff 50%, #999999 50%);
        border: 1px solid #000000;
    }
    .Gradient2 {
        background: linear-gradient(-200deg, #ffffff 50%, #000000 50%);
        border: 1px solid #000000;
    }
}
  
.ant-modal.invite-coparent .select-wrap .ant-select-selection-item {
    color: $light-grey !important;
    font-size: 12px !important;
} 

.invite-coparent {
    .input-full-invite{
       margin-top: 20px !important; 
       margin-bottom: 20px !important;
    }
    .full-btn {
        width: 100% !important;
        border: 0;
        height: 40px;
        border-radius: 3px;
        margin-bottom: 0 !important;
        padding: 20px !important;
        span {
            font-size: 12px;
            font-weight: 600;
            color: $white-color;
        }
    }
    .red-btn {
        background: #e63e35;
        margin-bottom: 0!important;
    }
    .check-text {
        color: #58595B;
        font-size: 12px;
        text-align: justify;
    }
    .ant-modal-body{
        padding-bottom: 10px !important;
    }
    .cust-selct span{
        color: $light-grey !important;
        font-size: 12px !important;
    }
}

