@font-face {
    font-family: 'Museo';
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/Light/Museo100-Regular.eot');
    src: url('../fonts/Light/Museo100-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Light/Museo100-Regular.woff') format('woff'),
        url('../fonts/Light/Museo100-Regular.ttf') format('truetype'),
        url('../fonts/Light/Museo100-Regular.otf') format('opentype');
}
@font-face {
    font-family: 'Museo';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/Regular/Museo300-Regular.eot');
    src: url('../fonts/Regular/Museo300-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Regular/Museo300-Regular.woff') format('woff'),
        url('../fonts/Regular/Museo300-Regular.ttf') format('truetype'),
        url('../fonts/Regular/Museo300-Regular.otf') format('opentype');
}
@font-face {
    font-family: 'Museo';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/SemiBold/Museo500-Regular.eot');
    src: url('../fonts/SemiBold/Museo500-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SemiBold/Museo500-Regular.woff') format('woff'),
        url('../fonts/SemiBold/Museo500-Regular.ttf') format('truetype'),
        url('../fonts/SemiBold/Museo500-Regular.otf') format('opentype');
}
@font-face {
    font-family: 'Museo';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/Bold/Museo700-Regular.eot');
    src: url('../fonts/Bold/Museo700-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Bold/Museo700-Regular.woff') format('woff'),
        url('../fonts/Bold/Museo700-Regular.ttf') format('truetype'),
        url('../fonts/Bold/Museo700-Regular.otf') format('opentype');
}
@font-face {
    font-family: 'Museo';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/ExtraBold/Museo900-Regular.eot');
    src: url('../fonts/ExtraBold/Museo900-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ExtraBold/Museo900-Regular.woff') format('woff'),
        url('../fonts/ExtraBold/Museo900-Regular.ttf') format('truetype'),
        url('../fonts/ExtraBold/Museo900-Regular.otf') format('opentype');
}

:root {
    h1,
    h1.ant-typography,
    .ant-typography h1 {
        margin-bottom: 16px;
        color: $black-color;
        font-weight: 600;
        font-size: 42px;
        line-height: 1.2;
    }
    h2,
    h2.ant-typography,
    .ant-typography h2 {
        margin-bottom: 16px;
        color: $black-color;
        font-weight: 600;
        font-size: 36px;
        line-height: 1.2;
    }
    h3,
    h3.ant-typography,
    .ant-typography h3 {
        margin-bottom: 16px;
        color: $black-color;
        font-weight: 600;
        font-size: 28px;
        line-height: 1.2;
    }
    h4,
    h4.ant-typography,
    .ant-typography h4 {
        margin-bottom: 12px;
        color: $black-color;
        font-weight: 600;
        font-size: 20px;
        line-height: 1.2;
    }
    h5,
    h5.ant-typography,
    .ant-typography h5 {
        margin-bottom: 0px;
        color: $black-color;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.2;
    }
    p {
        font-weight: 400;
        line-height: 1.2;
    }
    a.ant-typography {
        color: $black-color;
    }
}
