@import 'Assets/styles/global.scss';
@import 'pages/CalenderInner/CalenderList/CalenderList.scss';

.custody {
    height: calc(50% - 10px);
    margin-top: 10px;
    position: relative;
    .ant-card-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .loader-wrap {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
    .ant-card-body {
        position: relative;
        padding: 0px !important;
        display: flex;
        flex-direction: column;
        max-height: calc(100% - 75px);
        .tab-wrap {
            flex: 1 1;
            max-height: calc(100% - 80px);
            .ant-tabs {
                height: 100%;
            }
        }
    }
    .custody-wrap {
        padding: 15px;
        overflow: auto;
        scrollbar-color: #f5f5f5 !important;
        scrollbar-width: thin !important;
        &::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
            background-color: #ccc;
        }
        .ant-card {
            cursor: pointer;
        }
    }
    &-create {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 100%;
        font-size: 12px;
        font-weight: 600;
        padding: 20px 0px;
        border: 0 !important;
        border-radius: 3px;
        height: 30px;
        @media (max-width: 1366px) {
            font-size: 10px;
            top: -43px;
            height: 25px;
        }
    }
    &-wrap {
        .ant-card {
            &:not(:first-of-type) {
                margin-top: 10px;
            }
            .ant-card-body {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -ms-flex-direction: row;
                flex-direction: row;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
                width: 100%;
                padding: 10px !important;
                font-size: 11px;
                color: $light-grey;
                &:before,
                &:after {
                    display: none;
                }
                .card-left {
                    width: 40%;
                    .title {
                        // color: $text-color;
                        font-weight: 600;
                    }
                    .repeat {
                        span {
                             &:nth-of-type(1) {
                                color: $black-color;
                                font-weight: 700;
                            }
                            &:nth-of-type(2) {
                                color: $black-color;
                                font-weight: 700;
                            }
                             &:nth-of-type(3) {
                                color: $black-color;
                                font-weight: 700;
                            }
                        }
                    }
                    & > div {
                        margin-bottom: 2px;
                    }
                }
                .card-right {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -webkit-box-align: end;
                    -ms-flex-align: end;
                    align-items: flex-end;
                    width: 60%;
                    .icon {
                        -webkit-transform: rotate(90deg);
                        -ms-transform: rotate(90deg);
                        transform: rotate(90deg);
                        cursor: pointer;

                        span {
                            width: 15px;
                            height: 15px;
                            display: inline-block;
                            img {
                                width: 100%;
                                height: 100%;
                                opacity: 0.6;
                            }
                        }
                    }
                    .name {
                        font-weight: 600;
                        margin-right: 20px;
                        text-align: right;
                    }
                    .status {
                        margin-right: 20px;
                    }
                    .edit {
                        margin-right: 20px;
                        span {
                            &:nth-of-type(2) {
                                img {
                                    width: 13px;
                                    height: 13px;
                                    margin-bottom: 3px;
                                    margin-left: 5px;
                                }
                            }
                        }
                    }
                    .d-none {
                        display: none;
                    }
                    & > div {
                        margin-bottom: 2px;
                    }
                }
            }
        }
    }
    .ant-card-head-title{
        @media(max-width: 1150px) and (min-width: 991px){
            width: 100px;
            word-wrap: break-word;
            white-space: initial;
        }
    }
}
.schedule-form {
    .input-sm {
        .ant-form-item-label {
            // top: -20px;
            padding-left: 0;
            padding-bottom: 0;
            label {
                font-size: 10px !important;
                color: $light-grey;
                opacity: 0.5;
            }
        }
    }
    .tp-input {
        margin-top: 10px;
        label {
            font-size: 13px !important;
        }
    }
    .icon-input {
        label {
            font-size: 10px;
        }
        .ant-form-item-control-input-content {
            border-bottom: 1px solid $grey-border !important;
        }
        .ant-input-affix-wrapper {
            border: 0;
            padding-left: 0 !important;
        }
        input {
            border-bottom: 0 !important;
            &::placeholder {
                font-size: 12px;
                color: $light-grey;
                opacity: 0.5;
            }
        }
        .ant-input-affix-wrapper:focus,
        .ant-input-affix-wrapper-focused {
            box-shadow: none;
        }
        .ant-input-suffix {
            color: $primary-color !important;
            cursor: pointer;
        }
    }

    .item-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        .input-sm {
            margin-top: 15px;
            margin-bottom: 10px !important;
            &:nth-of-type(1) {
                margin-right: 5px;
            }
            &:nth-of-type(2) {
                margin-left: 5px;
            }
        }
        input {
            font-size: 12px !important;
        }
    }
    .detail-wrap {
        font-size: 12px;
        color: $light-grey;
        margin: 20px 0px;
    }
    .btn-row {
        .ant-form-item {
            width: 50%;
            &:nth-of-type(1) {
                margin-right: 5px;
            }
            &:nth-of-type(2) {
                margin-left: 5px;
            }
        }
        button {
            box-shadow: 0px 4px 10px #0000000a;
        }
        .btn-white {
            background: $white-color;
            color: $light-grey;
            border: 1px solid #58595b1a;
        }
    }
}
.cal-modal {
    .react-calendar__navigation__prev-button,
    .react-calendar__navigation__next-button {
        font-size: 30px !important;
    }
    .react-calendar__navigation::after {
        display: none;
    }
    .react-calendar__navigation {
        margin-bottom: 0 !important;
        margin-top: 0 !important;
    }
    .react-calendar__month-view__weekdays__weekday {
        padding: 0 !important;
    }
    .react-calendar__tile {
        padding: 12px;
    }
    .react-calendar__month-view__weekdays {
        margin-bottom: 10px !important;
    }
    .react-calendar__navigation button span {
        color: $light-grey;
        font-weight: 600;
    }
    .cal-wrap {
        margin-bottom: 10px;
    }
    .btn-scroll-wrap {
        // margin: 20px 0;
    }
    .special-text {
        margin: 10px 0;
        font-size: 12px;
        color: #999999;
    }
    .remove-btn {
        background-color: $brick-red;
        font-size: 12px;
        color: #fff;
    }
    .submit-btn {
        background-color: #f99c30;
        font-size: 12px;
        color: #fff;
    }
    .ant-checkbox-inner {
        background-color: #999999;
        border: none;
    }
}
.edit-wrap {
    .cal-wrap {
        .react-calendar__tile {
            justify-content: center;
            display: flex;
            position: relative;
            &::after {
                content: '';
                position: absolute;
                z-index: 0;
                left: 0;
                top: -2px;
                width: calc(100% - 8px);
                height: calc(100% - 16px);
                background: transparent;
                margin: 9px 4px;
                border-radius: 20px;
            }
            > div {
                position: relative;
                z-index: 1;
            }
        }
        .selected {
            &::after {
                background: $secondary-color;
            }
            > div {
                color: $white-color;
            }
        }
        .tile-circle {
            &:after {
                content: '';
                position: absolute;
                left: 1px;
                top: 0;
                width: 80%;
                height: 60%;
                background: url('../../../Assets/round-cycle.png') center no-repeat;
                background-size: 25px 25px;
            }
        }
        .trade-tile-circle {
            &:after {
                content: '';
                position: absolute;
                left: 1px;
                top: 0;
                width: 80%;
                height: 60%;
                background: url('../../../Assets/recycle.png') center no-repeat;
                background-size: 25px 25px;
            }
        }
        .highlight-date {
            color: $secondary-color;
        }
    }
    .cust-cal-wrap {
        .selected::after {
            background: $secondary-color;
        }
    }
    .schedule {
        &-wrap {
            margin-top: 30px;
            margin-bottom: 15px;
            color: $black-color;
        }
        &-inner {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            margin-bottom: 15px;
            span {
                &:nth-of-type(1) {
                    font-size: 10px;
                }
                &:nth-of-type(2) {
                    font-size: 12px;
                }
            }
        }
    }
    .full-btn {
        width: 100%;
        border: 0;
        height: 40px;
        border-radius: 3px;
        margin-bottom: 0 !important;
        span {
            font-size: 12px;
            font-weight: 600;
            color: $white-color;
        }
    }
    .btn-top {
        margin-bottom: 0 !important;
        .red-btn {
            background: $brick-red;
            margin-bottom: 0 !important;
        }
    }
    .btn-btm {
        margin-bottom: 0 !important;
    }
    .react-calendar__navigation {
        padding-bottom: 10px !important;
    }
    .react-calendar__navigation button span {
        color: $black-color !important;
    }
}
.custom-schedule {
    .ant-collapse {
        border: 0 !important;
        background: transparent;
        .ant-collapse-item {
            border: 0 !important;
            .ant-collapse-header {
                padding: 10px !important;
                margin-bottom: 10px;
                box-shadow: 0px 6px 15px #0000000d;
                font-size: 12px;
                border-radius: 3px;
                .ant-collapse-arrow {
                    right: 16px !important;
                    left: auto;
                }
            }
        }
    }
    .ant-collapse-content {
        border-top: 0 !important;
    }
    .ant-collapse-item-active .ant-collapse-header {
        color: $secondary-color;
    }

    .ant-collapse-content-box {
        padding: 0 !important;
    }
    .react-calendar__navigation {
        padding-bottom: 10px !important;
        button span {
            color: $black-color;
            font-size: 12px;
        }
    }
    .cal-wrap {
        .react-calendar__tile {
            justify-content: center;
            display: flex;
            position: relative;
            &::after {
                content: '';
                position: absolute;
                z-index: 0;
                left: 0;
                top: 0;
                width: calc(100% - 8px);
                height: calc(100% - 16px);
                background: transparent;
                margin: 9px 4px;
                border-radius: 20px;
            }
            > div {
                position: relative;
                z-index: 1;
            }
        }
        .selected {
            &::after {
                background: $secondary-color;
            }
            > div {
                color: $white-color;
            }
        }
    }
}
.cal-custom {
    .ant-btn {
        width: 100%;
        border: 0;
    }
    .scroll-wrap {
        max-height: 300px !important;
        overflow: hidden;
    }
    .cal-wrap {
        .set-date {
            justify-content: center;
            display: flex;
            position: relative;
            &::after {
                content: '';
                position: absolute;
                z-index: 0;
                left: 6px;
                top: -3px;
                width: 25px;
                height: 25px;
                background: $secondary-color;
                // @media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio:0) {
                //     left: 7px;
                //     top: 8px;
                // }
                @media not all and (min-resolution: 0.001dpcm) {
                    @supports (-webkit-appearance: none) {
                        left: 7px;
                        // top: 8px;
                    }
                }
            }
            > div {
                position: relative;
                z-index: 1;
                color: $white-color;
            }
        }
    }
}
.line-break {
    background: #ccc;
    height: 1px;
    margin: 15px 0;
}
.text-special-color {
    color: $primary-color;
}
.item-tabs {
    display: flex;
    justify-content: left;
    margin: 15px 0px;
    .item {
        font-size: 12px;
        padding: 2px 8px;
        background-color: #999999;
        color: #fff;
        border-radius: 5px;
        margin-right: 10px;
        word-break: keep-all;
    }
}
.con-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.check-text {
    color: #999999;
    font-size: 10px;
    text-align: justify;
}

.sc-modal {
    position: relative;
    .loader-wrap {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.multi-select {
    .ant-select-multiple {
        .ant-select-selector {
            position: relative;
            min-height: 35px;
            height: auto !important;
            padding-right: 20px;
            > span.ant-select-selection-item {
                position: relative;
                background: $white-color;
                border: 0;
                margin-right: 0;
                &:after {
                    content: ',';
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    color: $primary-blue;
                }
            }
            span.ant-select-selection-item:nth-last-child(2) {
                &:after {
                    content: none;
                }
            }
            .ant-select-selection-search {
                display: none;
            }
            .ant-select-selection-item-content {
                color: $primary-blue;
                margin-right: 0;
            }
            .ant-select-selection-item-remove {
                display: none;
            }
            &:after {
                position: absolute;
                right: 10px;
                top: 5px;
                background: url('../../../Assets/select-drop.png') center no-repeat;
                width: 20px;
                height: 20px;
                cursor: pointer;
                background-size: 8px 11px;
                -webkit-transform: rotate(-90deg);
                -ms-transform: rotate(-90deg);
                transform: rotate(-90deg);
            }
        }
    }
    .ant-select-open {
        .ant-select-selector {
            &:after {
                -webkit-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                transform: rotate(90deg);
                -webkit-transition: all ease-in-out 0.2s;
                -o-transition: all ease-in-out 0.2s;
                transition: all ease-in-out 0.2s;
            }
        }
    }
}


.kid-color-card {
    .list-dot {
        display: inline-block;
        width: 7px;
        height: 7px;
        margin-right: 5px;
        border-radius: 50%;
        background-color: #ff0000;
    }
    .blue {
        background: $primary-blue;
    }
    .orange {
        background: $dark-orange;
    }
    .green {
        background: $success-color;
    }
    .Pink {
        background-color: #c910e8;
    }
    .Blue {
        background-color: #2196f3;
    }
    .Purple {
        background-color: #673ab7;
    }
    .Red {
        background-color: #ff0000;
    }
    .Green {
        background-color: #4caf50;
    }
    .Orange {
        background-color: #ffc107;
    }
    .Cyan {
        background-color: #00bcd4;
    }
    .Brown {
        background-color: #795548;
    }
    .White {
        background-color: #ffffff;
        border: 1px solid #000000;
    }
    .Grey {
        background-color: #999999;
    }
    .Charcoal {
        background-color: #333333;
    }
    .Black {
        background-color: #000000;
    }
    .Gradient1 {
        background: linear-gradient(-200deg, #ffffff 50%, #999999 50%);
        border: 1px solid #000000;
    }
    .Gradient2 {
        background: linear-gradient(-200deg, #ffffff 50%, #000000 50%);
        border: 1px solid #000000;
    }
    span:nth-of-type(2){
        font-size:12px !important;
        color: #333333;
        font-weight: 600;
    }
}
 
