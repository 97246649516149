.p-t-card {
    height: 100%;
    .card-bottom {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        span {
            width: 50%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:nth-of-type(2) {
                text-align: right;
            }
        }
    }
    .card-body {
        .tab-content-wrap {
            .ant-card {
                .card-top {
                    span {
                        @media (max-width: 1919px) {
                            font-size: 12px !important;
                        }
                        &:nth-of-type(2) {
                            @media (max-width: 1919px) {
                                font-size: 11px !important;
                            }
                        }
                    }
                }
                .card-bottom {
                    span {
                        @media (max-width: 1919px) {
                            font-size: 11px !important;
                        }
                    }
                }
            }
        }
    }
}
.report-data-modal {
    .modal-body {
        .payment {
            &-wrap {
                padding: 20px;
                padding-top: 0px;
                max-height: 250px;
                overflow: auto;
                scrollbar-color: #f5f5f5 !important;
                scrollbar-width: thin !important;

                &::-webkit-scrollbar {
                    width: 5px;
                    background-color: #f5f5f5;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 5px;
                    -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
                    background-color: #ccc;
                }
                .total-row {
                    .total-head,
                    .total-row {
                        display: flex;
                        span {
                            margin: 3px 0px;
                            &:nth-of-type(1) {
                                width: 24%;
                            }
                            &:nth-of-type(2) {
                                width: 16%;
                            }
                            &:nth-of-type(3) {
                                width: 20%;
                                text-align: left;
                                border-bottom: 1px solid #ccc;
                            }
                            &:nth-of-type(4) {
                                width: 20%;
                                text-align: left;
                                border-bottom: 1px solid #ccc;
                            }
                            &:nth-of-type(5) {
                                width: 20%;
                                text-align: right;
                                border-bottom: 1px solid #ccc;
                            }
                        }
                    }
                    .total-row {
                        span {
                            font-weight: 300;
                            font-size: 12px;
                            &:nth-of-type(3) {
                                border-bottom: 1px dotted #ccc;
                            }
                            &:nth-of-type(4) {
                                border-bottom: 1px dotted #ccc;
                            }
                            &:nth-of-type(5) {
                                border-bottom: 1px dotted #ccc;
                            }
                        }
                        .amount-red {
                            color: #f06061;
                        }
                    }
                }
            }
            &-item {
                .pay-row {
                    border-bottom: 1px dotted #ccc;
                    .item {
                        display: flex;
                        span {
                            font-size: 12px;
                            font-weight: 300;
                            color: #58595b;
                            margin: 4px 0px;
                            &:nth-of-type(1) {
                                width: 24%;
                            }
                            &:nth-of-type(2) {
                                width: 38%;
                            }
                            &:nth-of-type(3) {
                                width: 38%;
                                text-align: right;
                            }
                        }
                        .amount {
                            font-weight: 500;
                            font-size: 14px;
                            span {
                                margin: 0px 2px;
                            }
                        }
                        .amount-red {
                            color: #f06061;
                        }
                        .amount-green {
                            color: #41c248;
                        }
                        .name {
                            font-weight: 500;
                        }
                    }
                    .item-top,
                    .item-bottom {
                        span {
                            &:nth-of-type(1) {
                                width: 20%;
                            }
                            &:nth-of-type(2) {
                                width: 20%;
                            }
                            &:nth-of-type(3) {
                                width: 30%;
                                text-align: center;
                            }
                        }
                    }
                    .item-top {
                        span {
                            &:nth-of-type(4) {
                                width: 30%;
                                text-align: right;
                            }
                        }
                    }
                    &:last-of-type {
                        border-bottom: 1px solid #ccc;
                    }
                }
                &:not(:first-of-type) {
                    padding-top: 30px;
                }
            }
            &-head {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
                border-bottom: 1px solid #ccc;
                span {
                    font-size: 14px;
                    font-weight: 500;
                    color: #58595b;
                    margin: 4px 0px;

                    &:nth-child(1) {
                        text-align: left;
                        width: 40%;
                    }
                    &:nth-child(2) {
                        text-align: center;
                        width: 30%;
                    }
                    &:nth-child(3) {
                        text-align: right;
                        width: 30%;
                    }
                }
            }
        }
    }
}
.pay-data {
    .ant-modal-body {
        padding-bottom: 10px;
    }
}

.save-wrap {
    .d-none {
        display: none;
    }
}
