@import 'Assets/styles/global.scss';
.rp-card {
    height: calc(50% - 5px);
    .ant-card-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    &-head {
        margin: 15px 10px 10px 10px;
        padding: 15px;
        h1 {
            font-size: 20px !important;
            font-weight: 700 !important;
            color: $black-color;
            margin: 0 !important;
        }
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        .btn-add-rec {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            border: none;
            border-radius: 8px;
            width: 188px;
            height: 35px;
            background: $dark-orange;
            span {
                font-size: 14px;
                font-weight: 600;
                line-height: 0;
                text-shadow: none;
            }
        }
    }
    &-body {
        .list-card {
            box-shadow: 0px 6px 15px #0000000d;
            padding: 0px;
            .req {
                &-inner {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -ms-flex-preferred-size: 33.3%;
                    flex-basis: 33.3%;
                }
                &-details {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -ms-flex-preferred-size: 33.3%;
                    flex-basis: 33.3%;
                    .detail-wrap {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        span {
                            color: $text-color;
                            font-size: 16px;
                            &:not(:last-of-type) {
                                margin-right: 5px;
                            }
                            &:nth-of-type(2) {
                                width: 15px;
                                height: 15px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }
                    .type {
                        color: $text-sec-color;
                        font-size: 16px;
                    }
                }
                &-action {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -ms-flex-preferred-size: 33.3%;
                    flex-basis: 33.3%;
                    span {
                        font-size: 12px;
                        color: $text-sec-color;
                    }
                    .img-wrap {
                        .anticon {
                            font-size: 16px !important;
                            color: $primary-color;
                            fill: $primary-color;
                        }
                    }
                }
                &-amount {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    text-align: right;
                    -ms-flex-preferred-size: 33.3%;
                    flex-basis: 33.3%;
                    span {
                        color: $text-sec-color;
                        font-size: 12px;
                        &:first-of-type {
                            font-weight: 700;
                            color: $text-color;
                            font-size: 16px;
                        }
                    }
                }
            }
            &:not(:first-of-type) {
                margin-top: 16px;
            }
        }
    }
    @media (max-width: 1919px) {
        &-head {
            h1 {
                font-size: 14px !important;
            }
            .btn-add-rec {
                border-radius: 6px;
                width: 143px;
                height: 32px;
                span {
                    font-size: 10px;
                }
            }
        }
        &-body {
            .list-card {
                .req {
                    &-details {
                        .detail-wrap {
                            span {
                                font-size: 12px !important;
                                &:nth-of-type(2) {
                                    width: 15px;
                                    height: 15px;
                                    img {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }
                        }
                        .type {
                            font-size: 11px !important;
                        }
                    }
                    &-action {
                        span {
                            font-size: 10px !important;
                        }
                    }
                    &-amount {
                        span {
                            font-size: 11px !important;
                            &:first-of-type {
                                font-size: 12px !important;
                            }
                        }
                    }
                }
                &:not(:first-of-type) {
                    margin-top: 16px;
                }
            }
        }
    }
    @media (max-width: 1366px) {
        &-head {
            h1 {
                font-size: 13px !important;
            }
        }
    }
    .loader-wrap {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.ant-modal {
    &-content {
        border-radius: 20px;
        .modal {
            &-head {
                font-size: 20px;
                font-weight: 700;
                color: $black-color;
                text-align: center;
                margin-bottom: 15px;
            }
        }
        .option-inner {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
        }
        .ant-radio-wrapper {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin-bottom: 20px;
            span {
                font-size: 12px;
                color: $text-color;
                &:first-of-type {
                    font-weight: 600;
                    font-size: 14px;
                }
            }
        }
    }
    .select-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        margin-bottom: 20px;
        .ant-select {
            width: 50%;
            &:nth-of-type(1) {
                margin-right: 10px;
            }
            &:nth-of-type(2) {
                margin-left: 10px;
            }
            &-selector {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                height: 40px;
                border-radius: 5px;
            }
            &-selection-item {
                color: $text-sec-color;
                font-size: 12px;
            }
        }
    }
    .input-full {
        margin-bottom: 20px;
        margin-top: 10px;
        .ant-select {
            width: 100%;
            margin-right: 0 !important;
        }
        input {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            height: 40px;
            border-radius: 5px;
            &::placeholder {
                color: $text-sec-color;
                font-size: 12px;
            }
        }
    }
    .input-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        margin-bottom: 20px;
        input {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            height: 40px;
            border-radius: 5px;
            width: 50%;
            &:nth-of-type(1) {
                margin-right: 10px;
            }
            &:nth-of-type(2) {
                margin-left: 10px;
            }
            &::placeholder {
                color: $text-sec-color;
                font-size: 12px;
            }
        }
    }
    .full-btn {
        margin-top: 10px;
        margin-bottom: 20px;
        .btn-modal {
            border: 0;
            width: 100%;
            height: 44px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            border-radius: 5px;
            background: $dark-orange;
            span {
                font-size: 12px;
            }
        }
    }
    .modal-bottom {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 4px;
        border-bottom: 1px solid rgba(153, 153, 153, 0.2);
        padding-bottom: 5px;
        .btn-add {
            border: 0;
            border-radius: 6px;
            height: 35px;
            width: 105px;
            background: $primary-blue;
            span {
                font-size: 14px;
                color: #fff;
            }
        }
        span {
            color: $text-color;
            font-size: 12px;
        }
    }
    &-footer {
        display: none;
    }
    &-close {
        top: 15px;
        &-x {
            svg {
                width: 24px;
                height: 24px;
                color: $text-color;
            }
        }
    }
    .ant-select-arrow {
        color: $text-color;
        width: 14px;
        height: 7px;
        transition: all ease-in-out 0.2s;
    }
    .ant-select-open {
        .ant-select-arrow {
            transform: rotate(180deg);
            top: 60%;
        }
    }
    @media (max-width: 1366px) {
        &-content {
            .modal-head {
                font-size: 14px !important;
            }
            .ant-radio-wrapper {
                span {
                    font-size: 11px;
                    &:first-of-type {
                        font-size: 12px;
                    }
                }
            }
        }
        .select-wrap {
            .ant-select {
                width: 50%;
                &:nth-of-type(1) {
                    margin-right: 10px;
                }
                &:nth-of-type(2) {
                    margin-left: 10px;
                }
                &-selector {
                    height: 33px;
                }
                &-selection-item {
                    color: $text-sec-color;
                    font-size: 10px;
                }
            }
        }
        .input-full {
            margin-bottom: 20px;
            margin-top: 10px;
            .ant-select {
                width: 100%;
                margin-right: 0 !important;
            }
            input {
                height: 33px;
                &::placeholder {
                    color: $text-sec-color;
                    font-size: 10px;
                }
            }
        }
        .input-wrap {
            input {
                height: 33px;
                &:nth-of-type(1) {
                    margin-right: 10px;
                }
                &:nth-of-type(2) {
                    margin-left: 10px;
                }
                &::placeholder {
                    color: $text-sec-color;
                    font-size: 12px;
                }
            }
        }
        .full-btn {
            margin-top: 10px;
            margin-bottom: 20px;
            .btn-modal {
                height: 40px;
                span {
                    font-size: 12px;
                    font-weight: 600;
                    text-shadow: none;
                }
            }
        }
        .modal-bottom {
            .btn-add {
                height: 28px;
                width: 85px;
                span {
                    font-size: 12px;
                    font-weight: 600;
                    text-shadow: none;
                }
            }
            span {
                color: $text-color;
                font-size: 10px;
            }
        }
        &-close {
            top: 5px;
            &-x {
                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
}
.modal-container {
    width: 420px !important;
    @media (max-width: 1366px) {
        width: 360px !important;
    }
}
.create-pay-modal {
    .add-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        border-radius: 6px;
        height: 24px;
        width: 70px;
        span {
            font-size: 12px !important;
            font-weight: 600;
            color: #fff !important;
        }
        @media (max-width: 1366px) {
            span {
                font-size: 12px;
                text-shadow: none;
            }
        }
    }
    .err-msg {
        position: absolute;
        color: $brick-red;
        font-size: 10px;
        bottom: -20px;
        left: 0;
    }
    .cust-mt {
        margin-top: 10px;
    }
    .err-right {
        left: 50%;
        @media (max-width: 1366px) {
            font-size: 9px;
        }
    }
    .select-wrap {
        display: flex;
        position: relative;
        .coparent-input {
            width: 50%;
            border: 0 !important;
            box-shadow: 0px 4px 10px #0000000a;
            border-radius: 8px;
            height: 40px;
            color: #38a0eb;
            font-size: 12px;
            display: flex;
            align-items: center;
            @media (max-width: 1919px) {
                height: 43px;
                font-size: 14px;
            }
        }
        .child-input {
            width: 50%;
            border: 0 !important;
            box-shadow: 0px 4px 10px #0000000a;
            border-radius: 8px;
            height: 40px !important;
            color: #38a0eb;
            font-size: 12px;
            display: flex;
            align-items: center;
            @media (max-width: 1919px) {
                height: 43px;
                font-size: 14px;
            }
        }
        .category-input {
            width: 100%;
            border: 0 !important;
            box-shadow: 0px 4px 10px #0000000a;
            border-radius: 8px;
            height: 40px !important;
            color: #38a0eb;
            font-size: 12px;
            display: flex;
            align-items: center;
            @media (max-width: 1919px) {
                height: 43px;
                font-size: 14px;
            }
        }
        .ant-select:nth-of-type(1) {
            margin-right: 0px;
        }
        .ant-select-selector {
            border: 0 !important;
            box-shadow: 0px 4px 10px #0000000a;
            border-radius: 8px;
            height: 40px;
            color: #38a0eb;
            font-size: 12px;
            display: flex;
            align-items: center;
            @media (max-width: 1919px) {
                height: 43px;
                font-size: 14px;
            }
        }
        .ant-select-selection-item {
            font-size: 12px;
            color: $primary-color !important;
        }
        .ant-select-arrow {
            color: $primary-color !important;
        }
    }
    .ant-radio-inner {
        width: 20px !important;
        height: 20px !important;
        border-color: $black-color;
        &:after {
            top: 5px !important;
            left: 5px !important;
        }
    }
    .ant-radio-checked .ant-radio-inner {
        border-color: #1890ff !important;
    }
    .ant-radio-wrapper {
        margin: 0px 0px 17px 10px !important;
    }
    .ant-modal-header {
        border-radius: 20px;
        border-bottom: 0;
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        color: $black-color;
        padding-bottom: 0;
        padding-top: 20px;
        .ant-modal-title {
            font-size: 14px;
            font-weight: 600;
            color: $black-color;
        }
    }
    .ant-modal-body {
        padding: 20px 0px 5px 0px !important;
    }
    .ant-modal-close {
        top: 0px !important;
        svg {
            width: 18px;
            height: 18px;
        }
    }
    .input-sm {
        margin-bottom: 35px;
        input {
            height: 22px !important;
            border-bottom: 1px solid #e9e9e9 !important;
            border-radius: 0;
            box-shadow: none;
            color: #999999 !important;
            border: 0 !important;
            font-size: 10px;
            padding-left: 0;
            font-size: 12px;
            @media (max-width: 1919px) {
                height: 26px;
                font-size: 14px;
            }
        }
        .ant-input-affix-wrapper {
            border: 0 !important;
        }
        .ant-input-suffix {
            font-size: 12px !important;
            position: absolute;
            right: 10px;
        }
    }
    .ant-radio-wrapper {
        span {
            font-size: 11px !important;
            color: $text-color;
            &:first-of-type {
                font-weight: 600 !important;
                font-size: 12px !important;
            }
        }
    }
    .modal-btn {
        border: 0;
        width: 100%;
        height: 44px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border-radius: 5px;
        span {
            font-size: 12px;
        }
        @media (max-width: 1366px) {
            height: 36px;
            span {
                font-size: 12px;
                font-weight: 600;
                text-shadow: none;
            }
        }
    }
    .transfer-details {
        display: flex;
        margin-bottom: 18px !important;
        span {
            flex-basis: 33.3%;
            font-size: 12px;
            &:nth-of-type(2) {
                display: flex;
                justify-content: center;
            }
            &:last-of-type {
                display: flex;
                justify-content: flex-end;
            }
        }
        &.item {
            margin-bottom: 24px !important;
        }
    }
    .freq-wrap {
        // display: flex;
        // align-items: center;
        .ant-form-item {
            margin-bottom: 0;
            width: 100%;
        }
        .ant-picker {
            width: 100%;
            height: 40px;
            border: 0;
            box-shadow: 0px 4px 10px #0000000a;
            border-radius: 8px;
            margin-bottom: 0px;
            margin-top: 10px;
            input {
                color: $primary-color;
                font-size: 12px;
            }
        }
        .ant-picker-suffix {
            color: $primary-color;
        }
        .input-full {
            margin-bottom: 15px !important;
        }
        .ant-form-item-label {
            position: absolute;
            // top: -16px;
            padding-left: 0px !important;
            padding-bottom: 0px !important;
            label {
                font-size: 10px;
                color: $text-color;
            }
        }
        .input-dob {
            margin-top: 20px;
            .ant-input-affix-wrapper {
                border-radius: 5px;
                input {
                    font-size: 12px;
                }
                .ant-input-suffix {
                    svg {
                        fill: $primary-blue !important;
                    }
                }
            }
            .ant-form-item-label {
                top: -24px !important;
            }
        }
    }
    .text-area-sm {
        margin-bottom: 0 !important;
        .ant-input {
            border-radius: 6px;
        }
    }
    .full-btn {
        margin-bottom: 15px !important;
    }
    // .ant-form {
    //     max-height: 420px;
    //     overflow-y: auto;
    //     overflow-x: hidden;
    //     padding: 0px 13px 0px 13px;
    //     margin-bottom: 10px;
    //     &::-webkit-scrollbar {
    //         width: 3px;
    //         background-color: #f5f5f5;
    //     }
    //     &::-webkit-scrollbar-thumb {
    //         border-radius: 5px;
    //         -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
    //         background-color: #ccc;
    //     }
    // }
    .form-scroll {
        padding: 0px 12px 0px 12px;
        max-height: 350px;
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 10px;
        &::-webkit-scrollbar {
            width: 10px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
            background-color: #ccc;
        }
    }
    .recurring-scroll {
        &::-webkit-scrollbar {
            width: 10px;
        }
        .multi-select {
            .ant-select-selector {
                font-size: 12px;
                .ant-select-selection-placeholder {
                    color: $primary-blue;
                    font-size: 12px;
                }
            }
            .single-sel {
                height: 35px !important;
                .ant-select-selector {
                    height: 35px !important;
                }
            }
        }
    }
    .dt-picker {
        .ant-picker-clear {
            display: none;
        }
        .ant-picker-input {
            cursor: pointer;
        }
    }
    .btn-wrap {
        padding: 0px 12px 0px 12px;
    }
    .multi-select {
        .edit-err {
            left: 50% !important;
        }
    }
    .single-sel {
        height: 35px !important;
        .ant-select-selector {
            height: 35px !important;
        }
    }
}

.text-color {
    color: $primary-color;
}
.ant-card.rp-card {
    .ant-card-body {
        padding: 0 !important;
        .rp-card-body {
            .scrollarea-content {
                padding: 15px !important;
                .list-card {
                    padding: 12px !important;
                }
            }
        }
    }
}
