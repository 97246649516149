.ant-card {
    border-radius: 10px;
    box-shadow: 0px 12px 45px #0000000f;
    .ant-card-body {
        padding: 15px !important;
    }
    .ant-tabs-nav-wrap {
        border-radius: 30px;
        box-shadow: 0px 5px 20px #0000000d;
        margin-top: 10px;
    }
    @media (max-width: 768px) {
        .ant-card-body {
            padding: 10px !important;
        }
    }
}
.list-card {
    border-radius: 10px;
    box-shadow: 0px 6px 15px #0000000d;
}
.row-container {
    padding: 15px;
    margin: 0;
    height: calc(1000px - 60px);
    // height: 100%;
    background: #f5f4f3;
}

.ant-tabs-ink-bar {
    display: none;
}
.col-wrap {
    // height: calc(100vh - 80px);
    max-height: 100%;
}
.row-wrap {
    // height: 100%;
    // flex: 1;
    .ant-col {
        // height: 50%;
    }
    .req-card,
    .trans-card {
        height: 100%;
        .ant-card-body,
        .trans-card {
            overflow: hidden;
            height: 100%;
            .req-card-wrap,
            .trans-card-wrap {
                // overflow: auto;
                // max-height: calc(100% - 47px);
                min-height: 330px;
                overflow: auto;
            }
        }
    }
    .trans-card {
        height: calc(100% - 40px);
    }
}
.todo-wrap {
    // height: calc(100vh - 115px);
    max-height: 100%;
    .td-card {
        // height: 100%;
        .ant-card-body {
            height: 100%;
            display: flex;
            flex-direction: column;
            .card-tab {
                // height: calc(100% - 110px);
                // overflow: hidden;
                .ant-tabs {
                    height: 100%;
                }
                .ant-tabs-content-holder {
                    // max-height: 850px;
                    // overflow: auto;
                }
            }
        }
    }
    @media (max-width: 768px) {
        margin-bottom: 20px;
    }
}
.calender-wrap {
    max-height: 100%;
    // height: calc(100vh - 115px);
    .ant-card {
        //height: 100%;
    }
    .ant-card-body {
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .card-body-wrap {
            height: 100%;
            overflow: hidden;
            flex: 1;
            .list {
                // max-height: 850px;
                // overflow: auto;
            }
        }
    }
    @media (max-width: 768px) {
        margin-bottom: 20px;
    }
}
.card-height {
    .ant-card {
        height: 100%;
    }
}

.trans-card {
    .ant-card-body {
        overflow: hidden;
    }
}
