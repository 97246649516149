@import 'Assets/styles/global.scss';
.ct-card {
    height: 100%;
    .ant-card-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    &-main {
        overflow: auto;
        overflow-x: hidden;
        flex: 1;
        scrollbar-color: #f5f5f5 !important;
        scrollbar-width: thin !important;
        &::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
            background-color: #ccc;
        }
    }
    &-head {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 15px 10px 30px 10px;
        padding: 6px 0;
        position: relative;
        h1 {
            font-size: 20px !important;
            color: $black-color;
            margin: 0 !important;
            font-weight: 700 !important;
        }
        .add-item {
            position: absolute;
            right: 10px;
            top: 0px;
            z-index: 2;
            transition: all ease-in-out 0.2s;
            border: 0;
            @include secondary-gradient();
            svg {
                width: 12px;
                height: 12px;
            }
        }
    }
    &-top {
        margin-bottom: 10px;
        border-bottom: 1px solid rgba(153, 153, 153, 0.2);
        padding-bottom: 20px;
        .ant-card {
            box-shadow: none !important;
            border-color: rgba(153, 153, 153, 0.2);
            &-body {
                padding: 0px 10px !important;
                margin: 0px -10px !important;
            }
        }
        .card {
            &-row {
                &:first-of-type {
                    border-bottom: 1px solid rgba(153, 153, 153, 0.2);
                }
                h2 {
                    text-align: center;
                    font-size: 16px;
                    color: $text-color;
                    font-weight: 600;
                    margin: 15px 0px;
                }
                .img-info {
                    width: 15px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            &-inner {
                margin-bottom: 15px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: space-evenly;
                -ms-flex-pack: space-evenly;
                justify-content: space-evenly;
                .btn {
                    border: 0;
                    border-radius: 8px;
                    height: 35px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    width: 70px;
                    span {
                        font-size: 14px;
                        color: #fff;
                        font-weight: 600;
                        line-height: 0;
                        text-shadow: none;
                    }
                    &-orange {
                        width: 70px;
                    }
                    &-grey {
                        width: 70px;
                        background: $text-sec-color;
                    }
                }
                // span:not(:last-child) {
                //     margin-right: 5px;
                // }
            }
        }
    }
    &-bottom {
        .ant-table-wrapper {
            margin: 0px 10px;
        }
        .ant-pagination {
            display: none;
        }
        .ant-table {
            &-thead > tr > th {
                padding-left: 0px;
                background: #fff !important;
                font-weight: 600;
                font-size: 16px;
                color: $black-color;
                &:nth-of-type(2),
                &:nth-of-type(3) {
                    text-align: center;
                }
            }
            &-tbody > tr > td {
                padding-left: 0px;
                font-size: 16px;
                color: $text-sec-color;
                &:nth-of-type(1) {
                    color: $text-color;
                }
                &:nth-of-type(2),
                &:nth-of-type(3) {
                    text-align: center;
                }
            }
            &-content {
                // max-height: calc(100vh - 160px);
                overflow: auto;
                scrollbar-color: #f5f5f5 !important;
                scrollbar-width: thin !important;
                &::-webkit-scrollbar {
                    width: 5px;
                    background-color: #f5f5f5;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 5px;
                    -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
                    background-color: #ccc;
                }
            }
        }
        .ant-list {
            .thead,
            .tbody {
                display: flex;
                span {
                    font-size: 12px;
                    &:nth-of-type(1) {
                        flex-basis: 45%;
                        color: $text-color;
                    }
                    &:nth-of-type(2) {
                        flex-basis: 15%;
                        color: $text-sec-color;
                    }
                    &:nth-of-type(3) {
                        flex-basis: 30%;
                        color: $text-sec-color;
                    }
                    &:nth-of-type(4) {
                        flex-basis: 10%;
                    }
                    img {
                        width: 16px;
                        height: 14px;
                    }
                }
            }
            .thead {
                span {
                    font-size: 13px;
                    color: $black-color !important;
                    font-weight: 600;
                }
            }
            .tbody {
                cursor: pointer;
            }
            .ant-list-item {
                padding: 15px 0px !important;
            }
        }
        .ant-card {
            width: 100%;
            border-radius: 0 !important;
            box-shadow: none !important;
            &-body {
                padding: 0 !important;
                width: 100%;
                .td {
                    display: flex;
                }
            }
        }
    }

    @media (max-width: 1919px) {
        &-head {
            h1 {
                font-size: 14px !important;
            }
            .add-item {
                width: 24px;
                height: 24px;
                svg {
                    width: 12px;
                    height: 12px;
                }
            }
        }
        &-top {
            .card {
                &-row {
                    h2 {
                        font-size: 12px;
                    }
                }
                &-inner {
                    margin-bottom: 15px;
                    .btn {
                        border-radius: 6px;
                        height: 25px;
                        width: 60px;
                        span {
                            font-size: 10px;
                        }
                        &-orange {
                            width: 60px;
                        }

                        &-grey {
                            width: 60px;
                        }
                    }
                    span:not(:last-child) {
                        font-size: 10px;
                    }
                }
            }
        }
        &-bottom {
            .ant-table {
                &-thead > tr > th {
                    font-size: 13px;
                }
                &-tbody > tr > td {
                    font-size: 12px;
                    color: $text-sec-color;
                    &:last-of-type {
                        text-align: right;
                        img {
                            width: 15px;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 1366px) {
        &-head {
            h1 {
                font-size: 13px !important;
            }
        }
    }
    .loader-wrap {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.edit-payment {
    .input-sm {
        position: relative;
    }
    .file-err {
        position: absolute;
        bottom: -12px;
        font-size: 10px;
        color: $brick-red;
    }
}

.terms-modal {
    width: 520px !important;
    @media (max-width: 1366px) {
        max-width: 520px !important;
    }
    .term-body-wrap {
        max-height: 400px;
        overflow: auto;
        padding: 0px 20px;
        scrollbar-color: #f5f5f5 !important;
        scrollbar-width: thin !important;
        font-size: 13px;
        text-align: justify;
        ol,
        ul {
            padding-left: 20px !important;
        }
        &::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
            background-color: #ccc;
        }
    }
    .cb-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding-top: 10px;
        padding-bottom: 10px;
        max-width: 350px;
        margin: 0 auto;
        .ant-btn {
            height: 40px;
            border: 0;
            border-radius: 6px;
            &:first-of-type {
                margin-bottom: 10px;
            }
        }
    }
    .terms-head {
        margin: 0px 20px 10px 20px;
        text-align: center;
    }
}
