.custody-swap-card {
    height: 100%;
}
.save-swap {
    .ant-modal-body {
        padding-bottom: 10px;
    }
    .tab-content-wrap {
        .content-inner {
            overflow: hidden !important;
            max-height: 100% !important;
        }
    }
    .ant-empty {
        .ant-empty-image {
            height: 25px;
        }
        .ant-empty-description {
            p {
                font-size: 12px;
            }
        }
    }
}
.custody-swap-card {
    .card-body {
        .tab-content-wrap {
            .ant-card {
                .card-top {
                    span {
                        @media (max-width: 1919px) {
                            font-size: 12px !important;
                        }
                        &:nth-of-type(2) {
                            @media (max-width: 1919px) {
                                font-size: 11px !important;
                            }
                        }
                    }
                }
                .card-bottom {
                    span {
                        @media (max-width: 1919px) {
                            font-size: 11px !important;
                        }
                    }
                }
            }
        }
    }
}
