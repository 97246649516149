@import 'Assets/styles/global.scss';
.register-card {
    margin-bottom: 12px;
    font-size: 12px;
    max-height: 68px;
    min-height: 68px;
    .card-inner {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        .req-data {
            width: 65%;
            margin-left: 10px;
        }
    }
    .btn-add-req {
        border: none;
        border-radius: 8px;
        width: 165px;
        height: 35px;
        padding: 0 !important;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 10px;
        span {
            color: $white-color;
            font-weight: 700;
        }
        @media (max-width: 1919px) {
            border-radius: 6px;
            width: 130px;
            height: 30px;
            span {
                font-size: 11px;
            }
        }
        @media (max-width: 1366px) {
            span {
                font-size: 10px;
            }
        }
    }
    .pop-loader {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        ._loading_overlay_overlay {
            border-radius: 20px;
        }
    }
}
.account-modal {
    max-width: 346px;
    .ant-modal-header {
        border-radius: 20px;
        border: 0;
        padding-bottom: 0;
        padding-top: 30px;
        .ant-modal-title {
            text-align: center;
            font-weight: 600;
            color: $black-color;
        }
    }
    .pop-loader {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        ._loading_overlay_overlay {
            border-radius: 20px;
        }
    }
    .content-wrap {
        padding: 10px 20px 10px 20px;
        max-height: 350px;
        overflow-y: auto;
        overflow-x: hidden;
        // margin-bottom: 10px;
        &::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
            background-color: #ccc;
        }
        .ant-input + .label-float,
        .ant-input + .label-float {
            font-size: 10px;
            transform: translate3d(0, -80%, 0);
            opacity: 1;
            color: $text-sec-color;
            @media (min-width: 1440px) {
                font-size: 10px;
            }
            @media (min-width: 1920px) {
                font-size: 12px;
            }
        }
        .label {
            left: 0 !important;
            font-weight: 300;
        }
        .err-msg {
            color: $brick-red;
            font-size: 10px;
        }
        .ant-input {
            height: 43px;
            border: 0;
            font-size: 13px;
            border-bottom: 1px solid #f0f0f0;
            padding: 0;
            color: $black-color;
            font-weight: 300;
        }
        .cust-dp.ant-picker {
            width: 100%;
            padding-left: 0;
            border: 0;
            .ant-picker-input {
                width: 100%;
                height: 43px;
                border: 0;
                input {
                    font-size: 13px;
                    color: $black-color;
                    font-weight: 300;
                }
            }
            .ant-picker-clear {
                display: none;
            }
            .ant-picker-suffix {
                color: $primary-color;
            }
            .label {
                top: 3px !important;
            }
        }
        .ant-input:focus,
        .ant-input-focused {
            box-shadow: none;
        }
        .float-wrap {
            margin-bottom: 20px;
        }
        .cust-mb {
            margin-bottom: 7px !important;
        }
        .cust-selct {
            width: 100%;
            .ant-select-selector {
                border: 0 !important;
                box-shadow: 0px 4px 10px #0000000a;
                border-radius: 8px;
                height: 43px;
                color: #38a0eb;
                font-size: 12px;
                display: flex;
                align-items: center;
            }
            .ant-select-arrow {
                color: #38a0eb;
            }
        }
    }
    .payment-setup-wrap {
        padding: 0 !important;
    }
    .cb-wrap {
        text-align: center;
        margin-bottom: 20px;
        .err-msg {
            margin-top: 5px;
        }
        span {
            color: #999999;
            font-size: 10px;
        }
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #479ed7;
            border-color: #479ed7;
        }
    }
    .btn-wrap {
        margin: 0px 20px;
        .ant-btn {
            width: 100%;
            box-shadow: 0px 3px 6px #00000038;
            border-radius: 6px;
            margin-bottom: 20px;
            height: 40px;
            font-size: 16px;
        }
    }
    .btn-flex {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        .btn-back {
            background: $white-color;
            border: 0;
            width: 40px;
            height: 40px;
            margin-right: 10px;
            .img-wrap {
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
    .bottom-info {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        span {
            font-size: 10px;
            color: #b2b2b2;
            margin-bottom: 10px;
        }
    }
    .ant-modal-body {
        padding: 10px 0px 10px 0px !important;
    }
    @media (max-width: 1366px) {
        .ant-modal-close {
            top: 15px !important;
        }
    }
    .recieve-head {
        font-size: 10px;
        color: #999999;
        text-align: center;
        margin-bottom: 20px;
    }
    .recieve-card {
        margin: 0px 20px;
        box-shadow: 0px 6px 15px #0000000d !important;
        margin-bottom: 10px;
        .ant-card-body {
            padding: 10px;
        }
        .card-wrap {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            .card-data {
                width: 50%;
                span {
                    font-size: 12px;
                    font-weight: 500;
                    color: $black-color;
                }
            }
            .card-btn-wrap {
                width: 50%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: end;
                -ms-flex-pack: end;
                justify-content: flex-end;
                .ant-btn {
                    border-radius: 3px;
                    width: 57px;
                    height: 21px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    border: 0;
                    span {
                        font-size: 10px;
                    }
                }
                .btn-edit {
                    background: #479ed7;
                    margin-right: 10px;
                }
                .btn-disable {
                    background: #f75f5e;
                }
                .btn-enable {
                    background: #479ed7;
                }
            }
        }
    }
    .review-head {
        font-size: 10px;
        color: #999999;
        text-align: center;
        margin-bottom: 10px;
    }
    .review-card {
        margin: 0px 20px;
        box-shadow: 0px 6px 15px #0000000d !important;
        margin-bottom: 10px;
        .ant-card-body {
            padding: 10px;
        }
        .card-wrap {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            font-size: 10px;
            .card-data {
                width: 50%;
                span {
                    font-weight: 500;
                    color: $black-color;
                }
            }
            .card-btn-wrap {
                width: 50%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: end;
                -ms-flex-pack: end;
                justify-content: flex-end;
                span {
                    font-weight: 700;
                }
                .succes {
                    color: #22b422;
                }
                .decline {
                    color: #f75f5e;
                }
            }
        }
    }
}
.pay-setup-modal {
    .ant-modal-body {
        padding-bottom: 30px !important;
    }
}
.privacy-policy-popup {
    width: 720px;
    .privacy-head {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 20px;
        color: $black-color;
    }
    .privacy-rights-text {
        text-align: center;
        display: block;
        text-decoration: underline;
        font-weight: bold;
    }
    .policy-list {
        color: #707070;
        font-size: 13px;
        line-height: normal;
    }
    p {
        color: #707070;
        font-size: 13px;
    }
    .cb-wrap {
        text-align: center;
    }
    .privacy-wrap {
        max-height: 450px;
        overflow: auto;
        &::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
            background-color: #ccc;
        }
    }
    .ant-modal-body {
        padding-top: 0;
    }
    .ant-modal-close {
        top: 0px;
    }
    .ant-modal-header {
        border-radius: 20px;
        border-bottom: 0;
        text-align: center;
        font-size: 20px;
        .ant-modal-title {
            font-size: 22px;
            text-transform: uppercase;
            color: #000;
            font-weight: 700;
        }
    }
}
