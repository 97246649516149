@import "Assets/styles/global.scss";
.header-wrap {
  @include orange-gradient;
  padding: 15px 25px;
  .logo-wrap {
    img {
      width: 39px;
      height: 30px;
      cursor: pointer !important;
    }
    a {
      cursor: default !important;
    }
    .logo-inner {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 39px;
      height: 30px;
      cursor: pointer !important;
      .logo-item {
        font-size: 27px;
        font-weight: 500;
        color: $white-color;
      }
    }
  }
  .icon-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    .icon-inner {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      span {
        width: 23px;
        height: 23px;
        list-style-type: none;
        cursor: pointer;
        &:hover {
          -webkit-transform: translateY(-2px);
          -ms-transform: translateY(-2px);
          transform: translateY(-2px);
          -webkit-transition-duration: 0.1s;
          -webkit-transition-timing-function: ease-in-out;
        }
        &:not(:last-of-type) {
          margin-right: 20px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .notification-wrap {
        .ant-badge-count {
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          -webkit-box-shadow: none;
          box-shadow: none;
          background: #f75f5e;
          border-radius: 50%;
          padding: 12px;
          .ant-scroll-number-only {
            // height: 100%;
            // width: 100%;
            margin-right: 2px !important;
            .ant-scroll-number-only-unit {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .drop-down {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    .ant-select {
      width: 200px;
      &-selector {
        background: #fff;
        color: #000;
        border: 1px solid #fff;
        border-radius: 5px;
      }
      &-arrow {
        color: #000;
      }
      .ant-select-selection-item {
        font-size: 16px;
        @media (max-width: 1919px) {
          font-size: 13px;
        }
      }
      .ant-select-item-option {
        border-bottom: 1px solid #ccc;
      }
      @media (max-width: 768px) {
        width: 125px;
        .ant-select-selection-item {
          font-size: 13px;
        }
      }
    }
  }
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }
  .drop-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .ant-select-arrow {
    transition: all ease-in 0.2s;
  }
  .ant-select-open {
    .ant-select-arrow {
      transform: rotate(180deg);
    }
  }
  @media (max-width: 768px) {
    padding: 10px;
    .logo-wrap {
      width: 85px;
      height: 25px;
    }
    .icon-wrap {
      .icon-inner {
        span {
          width: 18px;
          height: 18px;
          &:not(:last-of-type) {
            margin-right: 7px;
          }
        }
      }
    }
  }
  .head-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
.pwd-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 16px;
  .logo-wrap {
    width: 160px;
    height: 48px;
  }
}
.ant-popover-inner {
  width: 125px;
  .ant-popover-inner-content {
    padding: 0px !important;
  }
  .content-wrap {
    padding: 12px 0px;
    p {
      padding: 0px 12px;
      color: $text-color;
      font-size: 12px;
      cursor: pointer;
    }
    .logout {
      padding-top: 10px;
      display: block;
      border-top: 1px solid rgba(88, 89, 91, 0.1);
    }
  }
}
.ant-popover-arrow {
  display: block !important;
}
.notification-popover {
  .ant-popover-title {
    font-size: 16px;
    color: #212121;
    font-weight: 700;
  }
  .ant-popover-inner {
    width: 390px;
  }
}
.notification-content-wrap {
  .head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
    padding: 20px 20px 15px 20px;
    span {
      &:first-of-type {
        font-size: 16px;
        color: #212121;
        font-weight: 700;
      }
    }
    img {
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }
  .list-wrap-notif {
    .ant-list-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: #212121;
      padding: 15px 20px;
      &:last-of-type {
        border-bottom: 1px solid #f0f0f0;
      }
    }
    .list-top {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 10px;
      span {
        &:nth-of-type(1) {
          font-size: 12px;
          font-weight: 500;
        }
        &:nth-of-type(2) {
          font-size: 11px;
          font-weight: 300;
        }
      }
    }
    .list-bottom {
      font-size: 11px;
      font-weight: 300;
    }
    .blue-list {
      background: #f5f5f5;
    }
    .pink-list {
      background: #ff972012;
    }
  }
  .notification-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    span {
      font-size: 14px;
      font-weight: 500;
      color: #212121;
    }
  }
}
