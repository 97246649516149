@import 'Assets/styles/global.scss';
.chat-tab {
    .ant-card-body {
        padding: 0 !important;
        height: 100%;
        &::before,
        &::after {
            display: none;
        }
    }
    .chat-tab-inner {
        padding: 0px 20px 20px 20px;
        overflow: auto;
        height: 100%;
        margin-top: 20px;
        scrollbar-color: #f5f5f5 !important;
        scrollbar-width: thin !important;
        &::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
            background-color: #ccc;
        }
        .chat-tab-item {
            cursor: pointer;
            padding: 8px 10px;
            box-shadow: 0px 6px 15px #0000000d;
            margin-bottom: 16px;
            .ant-card-body {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                width: 100%;
                .card-left {
                    width: 20%;
                    .ant-scroll-number {
                        width: 10px;
                        height: 10px;
                        border: 1px solid $white-color;
                        position: absolute;
                        top: 5px;
                        right: 3px;
                    }
                    .ant-badge {
                        .ant-avatar {
                            position: relative;
                        }
                    }
                }
                .card-right {
                    width: 80%;
                    .person-data {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-pack: justify;
                        -ms-flex-pack: justify;
                        justify-content: space-between;
                        span {
                            &:nth-of-type(1) {
                                font-size: 12px;
                                color: $black-color;
                                font-weight: 700;
                                width: 135px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                            &:nth-of-type(2) {
                                font-size: 10px;
                                color: #b2b2b2;
                            }
                        }
                    }
                    .msg-body {
                        margin-top: 10px;
                        span {
                            font-size: 10px;
                            color: #b2b2b2;
                            width: 180px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
