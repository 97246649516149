@import 'Assets/styles/global.scss';
.calender-card {
    .list-inn {
        display: flex;
        justify-content: flex-start;
    }
    height: 100%;
    .ant-card-body {
        padding: 0px !important;
    }
    .card-head {
        margin: 15px 5px 30px 5px;
        @media (min-width: 1920px) {
            margin: 15px 5px 40px 5px;
        }
    }
    .content-child{
        overflow: auto !important;
        border-top: 1px solid rgba(112, 112, 112, 0.1);
    scrollbar-color: #f5f5f5 !important;
    scrollbar-width: thin !important;
    &::-webkit-scrollbar {
        width: 2px;
        background-color: #f5f5f5;
        }
    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
        background-color: #ccc;
        }
    }
}
.name-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 25px;
    padding: 18px 24px;
    span {
        font-weight: 700;
        font-size: 10px;
    }
    .circle {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 2px solid transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        font-size: 14px;
        padding: 12px;
        @media (min-width: 1920px) {
            width: 25px;
            height: 25px;
        }
        @media (min-width: 1920px) {
            width: 30px;
            height: 30px;
        }
    }
    .Pink {
        border-color: $rose-color;
        span {
            color: $rose-color;
        }
    }
    .Blue {
        border-color: $primary-color;
        span {
            color: $primary-color;
        }
    }
    .Purple {
        border-color: $violet-color;
        span {
            color: $violet-color;
        }
    }
    .Red {
        border-color: $danger-color;
        span {
            color: $danger-color;
        }
    }
    .Green {
        border-color: $success-color;
        span {
            color: $success-color;
        }
    }
    .Orange {
        border-color: $secondary-color;
        span {
            color: $secondary-color;
        }
    }
    .Cyan {
        border-color: #00bcd4;
        span {
            color: #00bcd4;
        }
    }
    .Brown {
        border-color: #795548;
        span {
            color: #795548;
        }
    }
    .White {
        background-color: #ffffff;
        border-color: #000000;
    }
    .Grey {
        border-color: #999999;
        span {
            color: #999999;
        }
    }
    .Charcoal {
        border-color: $text-color;
        span {
            color: $text-color;
        }
    }
    .Black {
        border-color: $black-color;
        span {
            color: $black-color;
        }
    }
    .Gradient1 {
        position: relative;
        color: #555 !important;
        border: double 2px transparent !important;
        background-image: linear-gradient(white, white), radial-gradient(circle at top left, #fff, #555);
        background-origin: border-box;
        background-clip: content-box, border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            position: relative;
            z-index: 2;
            color: #ccc !important;
        }
        &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: #fff;
            border-radius: 50%;
        }
    }
    .Gradient2 {
        position: relative;
        color: #ccc !important;
        border: double 2px transparent !important;
        background-image: linear-gradient(white, white), radial-gradient(circle at top left, #fff, #000);
        background-origin: border-box;
        background-clip: content-box, border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            position: relative;
            z-index: 2;
            color: #000 !important;
        }
        &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: #fff;
            border-radius: 50%;
        }
    }
    .blue {
        border-color: #38a0eb;
        span {
            color: #38a0eb;
        }
    }
    .orange {
        border-color: #fca232;
        span {
            color: #fca232;
        }
    }
    .green {
        border-color: #22b422;
        span {
            color: #22b422;
        }
    }
    .violet {
        border-color: #dc2cd1;
        span {
            color: #dc2cd1;
        }
    }
}

.list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    scrollbar-color: #f5f5f5 !important;
    scrollbar-width: thin !important;
    &::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
        background-color: #ccc;
    }

    &-day {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 20px;
        width: 23px;
        span {
            font-size: 14px;
            color: $text-sec-color;
            font-weight: 500;
            &:first-of-type {
                font-weight: 700;
                font-size: 20px;
                color: $black-color;
            }
            @media (max-width: 768px) {
                font-size: 10px;
                &:first-of-type {
                    font-size: 14px;
                }
            }
        }
    }
    &-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding: 10px 24px;
        border-top: 1px solid rgba(112, 112, 112, 0.1);
        min-height: 100px;
    }
    &-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: auto;
        scrollbar-color: #f5f5f5 !important;
        scrollbar-width: thin !important;
        &::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
            background-color: #ccc;
        }
    }
    &-wrap {
        display: flex;
        align-items: flex-start;
    }
    &-timer {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        // margin: 0px 15px 10px 10px;
        width: 50px;
        span {
            &:first-of-type {
                margin-bottom: 5px;
                color: $text-color;
                font-size: 13px;
                margin-top: 2px;
            }
        }
        .stat {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            span {
                margin-right: 5px;
            }
        }
        @media (max-width: 768px) {
            span {
                font-size: 11px;
            }
        }
        .Pink {
            background-color: #c910e8;
        }
        .Blue {
            background-color: #2196f3;
        }
        .Purple {
            background-color: #673ab7;
        }
        .Red {
            background-color: #ff0000;
        }
        .Green {
            background-color: #4caf50;
        }
        .Orange {
            background-color: #ffc107;
        }
        .Cyan {
            background-color: #00bcd4;
        }
        .Brown {
            background-color: #795548;
        }
        .White {
            background-color: #ffffff;
            border: 1px solid #000000;
        }
        .Grey {
            background-color: #999999;
        }
        .Charcoal {
            background-color: #333333;
        }
        .Black {
            background-color: #000000;
        }
        .Gradient1 {
            background: linear-gradient(#ffffff, #999999);
        }
        .Gradient2 {
            background: linear-gradient(#ffffff, #000000);
        }
        .blue {
            background-color: #ffc107;
        }
        .orange {
            background-color: #fca232;
        }
        .green {
            background-color: #22b422;
        }
        .violet {
            background-color: #dc2cd1;
        }
    }
    &-main {
        display: flex;
        margin-bottom: 5px;
    }
    &-activity {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-left: 10px;
        span {
            font-size: 13px;
            color: $text-color;
            &:first-of-type {
                color: $black-color;
                font-weight: 600;
                font-size: 16px;
            }
        }
        @media (max-width: 768px) {
            span {
                font-size: 11px;
                &:first-of-type {
                    font-size: 12px;
                }
            }
        }
    }
    &-info {
        width: 7px;
        height: 7px;
        background: red;
        border-radius: 50%;
        @media (min-width: 1440px) {
            width: 10px;
            height: 10px;
        }
        @media (min-width: 1920px) {
            width: 12px;
            height: 12px;
        }
    }
    .blue {
        background-color: $primary-color;
    }
    .orange {
        background-color: $secondary-color;
    }
    .green {
        background-color: $success-color;
    }
    .violet {
        background-color: $danger-color;
    }
    .dark-blue {
        background-color: $dark-blue;
    }
    .selected {
        background-color: #fafafa;
        .list-day {
            span {
                color: $black-color;
            }
        }
    }
    &-details {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}
.i-task {
    font-style: italic;
    font-weight: 300 !important;
}
@media (min-width: 1920px) {
    .calender-card {
        .list {
            padding-bottom: 30px;
            .list-day {
                span {
                    font-size: 20px;
                    &:not(:first-of-type) {
                        font-size: 14px;
                    }
                }
            }
            .list-timer {
                span {
                    &:first-of-type {
                        font-size: 14px;
                    }
                }
            }
            .list-info {
                width: 12px;
                height: 12px;
            }
            .list-activity {
                span {
                    font-size: 14px;
                    &:first-of-type {
                        font-size: 18px;
                    }
                }
            }
        }
    }
    .card-head {
        span {
            font-size: 20px !important;
        }
    }
}
@media (max-width: 1919px) {
    .calender-card {
        .list {
            .list-day {
                span {
                    font-size: 14px;
                    &:not(:first-of-type) {
                        font-size: 10px;
                    }
                }
            }
            .list-timer {
                span {
                    &:first-of-type {
                        font-size: 10px;
                    }
                }
            }
            .list-activity {
                span {
                    font-size: 10px;
                    &:first-of-type {
                        font-size: 13px;
                    }
                }
            }
        }
    }
    .card-head {
        span {
            font-size: 14px !important;
        }
        .ant-btn {
            span {
                font-size: 11px !important;
            }
        }
    }
}
.loader-wrap,
.cal-loader {
    height: 100%;
}
