@import 'Assets/styles/global.scss';
.pwd-wrap {
    margin-top: 100px !important;
    @media (max-width: 768px) {
        margin-top: 15px !important;
    }
}
.pwd-form {
    .btn-wrap {
        margin-top: 20px !important;
        margin-bottom: 0 !important;
    }
}
.pswrd-head {
    @media (max-width: 768px) {
        display: flex !important;
        justify-content: center !important;
    }
}
.forgotErrorMessage {
    position: relative;
    top: 10px;
    color: $brick-red;
    font-size: 13px;
}
.alert-pop {
    .ant-modal-close {
        top: -12px !important;
        right: -10px !important;
        svg {
            width: 15px;
            height: 15px;
        }
    }
}
